export const COUNT_SPLIT_OVERALL = "OVR";
export const COUNT_SPLIT_PRE_2K = "P2K";
export const COUNT_SPLIT_2K = "2K";

export const BATS_OVERALL = "OVR";
export const BATS_L = "L";
export const BATS_R = "R";

export const THROWS_OVERALL = "OVR";
export const THROWS_L = "L";
export const THROWS_R = "R";

export const PLAYING_LEVEL_PRO = "PRO";
export const PLAYING_LEVEL_AMA = "AMA";

export const THRESHOLD_ATTACK_ANGLE = "attack_angle";
export const THRESHOLD_BAT_SPEED = "bat_speed";
export const THRESHOLD_BAT_TO_BALL = "bat_to_ball";
export const THRESHOLD_SWING_DECISION = "swing_decision";

export const GAME_TYPE_OVERALL = "OVR";
