/*
    Our axios configuration to be reused throughout the application
 */
import dayjs from "dayjs";
import { refreshOktaTokens } from "_react/app/_helpers";
import { getdayjsFromUnixTS } from "utils/functions";
import { cookies } from "utils/redux_constants";
// Refactoring this requires typing out all axios responses and errors
// eslint-disable-next-line @typescript-eslint/no-var-requires
const _axios = require("axios").default;
const MINUTES_BEFORE_REFRESH = 5;

dayjs.suppressDeprecationWarnings = true;

// export const restAvailable = () => {
// 	return selectNetworkAvailable(store.getState()) && selectApiAvailable(store.getState());
// };

export function getCancelToken() {
	let cancel;
	return {
		cancelToken: new _axios.CancelToken(function executor(c) {
			cancel = c;
		}),
		cancel
	};
}

_axios.defaults.baseURL = process.env.REACT_APP_API_URL;
_axios.defaults.headers.common["Authorization"] = cookies.get("token");

// Add a request interceptor
_axios.interceptors.request.use(
	config => {
		// https://medium.com/@mateioprea/maintaining-api-authentication-using-axios-e70ba174da6
		const exp = cookies.get("exp");
		// let idToken = cookies.get("idToken");
		// Custom Auth doesn't set the `exp` cookie so should never hit this
		// console.log(`What's exp ${exp}`)
		// console.log(`EXP=${exp}, Minutes till expire ${getdayjsFromUnixTS(exp).diff(dayjs(), "minute")}`)
		if (exp && getdayjsFromUnixTS(exp).diff(dayjs(), "minute") < MINUTES_BEFORE_REFRESH) {
			// Refresh token if possible
			const originalRequest = config;
			return refreshOktaTokens()
				.then(() => {
					// TODO: Put Bearer
					// TODO: Only call refreshToken once have any others reuse the promise
					// https://github.com/axios/axios/issues/450
					originalRequest.headers.Authorization = cookies.get("token");
					// _axios.defaults.headers.common["Authorization"] = cookies.get("token");
					return Promise.resolve(originalRequest);
				})
				.catch(err => {
					const { message } = err;
					return Promise.reject(`Unable to refresh token ${message}`);
				});
		}
		//if(cookies.get("idToken")){
		//    let idToken = cookies.get("idToken");
		//    console.log(`ID TOKEN IS ${idToken.substring(idToken.length - 5, idToken.length)}`)
		//    config.headers.Authorization = `Bearer ${cookies.get("idToken")}`;
		//}
		if (cookies.get("token")) {
			config.headers.common.Authorization = cookies.get("token");
		}
		return config;
	},
	function(error) {
		// Do something with request error
		console.log(error);
		return Promise.reject(error);
	}
);

_axios.interceptors.response.use(
	response => {
		// if (!restAvailable()) {
		// 	// Update REST available if you used to be disconnected
		// 	store.dispatch({ type: NETWORK_AVAILABLE, payload: true });
		// 	store.dispatch({ type: API_AVAILABLE, payload: true });
		// }

		return response;
	},
	error => {
		// TODO: Add in retry logic
		// https://github.com/axios/axios/issues/934
		// const {message, config, status} = error
		// const { message } = error;
		// const { onLine } = navigator;
		// if (!onLine && message === "Network Error") {
		// 	// User needs to connect their WiFi
		// 	store.dispatch({ type: NETWORK_AVAILABLE, payload: false });
		// 	store.dispatch({ type: API_AVAILABLE, payload: false });
		// } else if (onLine && message === "Network Error") {
		// 	// WiFi available but API is not
		// 	store.dispatch({ type: API_AVAILABLE, payload: false });
		// }

		if (_axios.isCancel(error)) {
			console.log("request canceled");
		} else {
			console.log(error);
		}
		return Promise.reject(error);
	}
);

export default _axios;
