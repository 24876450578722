import styled from "@emotion/styled";

// Style Objects

export const MODAL_STYLE = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		maxWidth: "500px",
		minWidth: "300px",
		maxHeight: "calc(100vh - 100px)",
		overflow: "scroll",
		border: "1px solid black"
	},
	overlay: { zIndex: 1000 }
};

export const SmallCircularProgressStyle = {
	height: 32,
	margin: "auto"
};

export const CardStyle = {
	margin: 10,
	height: "auto"
};

export const OddTrStyle = {
	backgroundColor: "rgb(238, 238, 238)"
};

export const FilterTextFieldStyle = {
	padding: "6px",
	backgroundColor: "white",
	borderBottom: "none",
	color: "black",
	fontSize: "11px"
};

export const LeftButtonStyle = {
	marginRight: "10px"
};

export const IconButtonStyle = {
	backgroundColor: "transparent"
};

// Styled Components

export const ActionsDiv = styled.div({
	width: "150px",
	display: "flex",
	justifyContent: "center"
});

type TFixedWidthDiv = { width?: string };
export const FixedWidthDiv = styled.div<TFixedWidthDiv>(({ width }) => {
	return {
		width: width,
		margin: "auto"
	};
});

type TFixedHeightDiv = { height?: string };
export const FixedHeightDiv = styled.div<TFixedHeightDiv>(({ height }) => {
	return {
		height: height,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	};
});

export const ModalLinkDiv = styled.div({
	cursor: "pointer",
	textDecoration: "underline",
	"&:hover": {
		opacity: "0.5"
	}
});

type TMinWidthDiv = { minWidth?: string; flex?: boolean };
export const MinWidthDiv = styled.div<TMinWidthDiv>(({ minWidth, flex }) => {
	return {
		minWidth: minWidth,
		display: `${flex ? "flex" : undefined}`
	};
});

type TDisplayDiv = { display?: string };
export const DisplayDiv = styled.div<TDisplayDiv>(({ display }) => {
	return {
		display: display
	};
});

export const MetricTable = styled.table({
	width: "100%",
	borderCollapse: "collapse"
});

export const MetricTr = styled.tr({
	"&:hover": {
		backgroundColor: "rgba(0, 43, 92, 0.125) !important"
	}
});

export const MetricFilterTh = styled.th({
	borderRight: "1px solid rgb(128, 128, 128)",
	textAlign: "center",
	backgroundColor: "lightgray"
});

export const MetricTh = styled.th({
	borderRight: "1px solid rgb(128, 128, 128)",
	textAlign: "center",
	cursor: "pointer",
	backgroundColor: "black",
	color: "white",
	padding: "5px"
});

export const MetricTd = styled.td({
	borderRight: "1px solid rgb(128, 128, 128)",
	textAlign: "center",
	padding: "5px",
	fontSize: "12px"
});

// Raw CSS to override DatePicker styles

export const DATEPICKER_STYLE = `
.react-datepicker__input-container input {
	width: 100%;
	font-size: 11px;
	background-color: white;
	border: 1px solid #CCCCCC;
	border-radius: 3px;
	padding: 4px;
}
.react-datepicker__close-icon:after {
	height: 12px;
	width: 12px;
	font-weight: 900;
	font-size: 12px;
}
`;

export const TooltipStyle = {
	backgroundColor: "rgb(125,125,125)",
	whiteSpace: "pre-wrap"
};
