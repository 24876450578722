import { AMA } from "utils/tsutils";

// TODO replace this type once $TSFixMeTeam is typed out
// Also change functions below to only camelCase when all snake_case is gone
type teamNames = {
	name_display?: string | null;
	name?: string | null;
	name_short?: string | null;
	name_long?: string | null;
	name_abbrev?: string | null;
	name_nickname?: string | null;
	player_classification?: string | null;
	nameDisplay?: string | null;
	nameShort?: string | null;
	nameLong?: string | null;
	nameAbbrev?: string | null;
	nameNickname?: string | null;
	playerClassification?: string | null;
	teamName?: string | null;
};

export const hasTeamName = (team?: teamNames | null) => {
	return (
		team?.name_display ||
		team?.name ||
		team?.name_short ||
		team?.name_long ||
		team?.name_abbrev ||
		team?.name_nickname ||
		team?.nameDisplay ||
		team?.nameShort ||
		team?.nameLong ||
		team?.nameAbbrev ||
		team?.nameNickname
	);
};

export const getTeamName = (team?: teamNames | null, nullFillValue = "") => {
	switch (team?.player_classification?.toUpperCase() ?? team?.playerClassification?.toUpperCase()) {
		case AMA:
			return (
				team?.name_display ??
				team?.nameDisplay ??
				team?.name ??
				team?.name_short ??
				team?.nameShort ??
				team?.name_long ??
				team?.nameLong ??
				team?.name_abbrev ??
				team?.nameAbbrev ??
				team?.name_nickname ??
				team?.nameNickname ??
				team?.teamName ??
				nullFillValue
			);
		default:
			return (
				team?.name ??
				team?.name_display ??
				team?.nameDisplay ??
				team?.name_short ??
				team?.nameShort ??
				team?.name_long ??
				team?.nameLong ??
				team?.name_abbrev ??
				team?.nameAbbrev ??
				team?.name_nickname ??
				team?.nameNickname ??
				nullFillValue
			);
	}
};

// This helper function fixes an issue with three of the MLB team abbreviations
// in mesa.team.parent_org_code.
export const fixMesaTeamParentOrgCode = (orgCode: string): string => {
	// In mesa.team, Yankees are NYY and Mets are NY
	if (orgCode === "NY") return "NYM";
	// Also, Angels are LAA while Dodgers are LA
	if (orgCode === "LA") return "LAD";
	if (orgCode === "CHI") return "CHC";
	return orgCode;
};
