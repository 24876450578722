import React, { FunctionComponent, useState } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import { useIsGroupMember } from "_react/app/AppContents";
import { $TSFixMe } from "utils/tsutils";
import { getQueryStringValue } from "utils/url_helpers";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import StatisticsPage from "_react/stats/player";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { ScheduleTable } from "_react/teampage/schedule/ScheduleTable";

import { EvaluationsTable } from "_react/playerpage/eval/EvaluationsTable";
import { ContractsTable } from "_react/playerpage/contract/ContractsTable";
import { TransactionsTable } from "_react/playerpage/transaction/TransactionsTable";
import { CollegeSplits } from "_react/playerpage/stat/collegesplits";
import { AMA, MAJOR, MINORS, PRO } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { SurplusValueBox } from "_react/playerpage/surplus_value/SurplusValueBox";
import GameSummaryWidget from "_react/gamesummary/GameSummaryWidget";

type SummaryProps = {
	colorScheme: ColorSchemeGroup;
	evaluations?: $TSFixMeEval[] | null;
	player: $TSFixMe;
	playerCombined: TPlayerPageCombinedPlayer;
	viewClassification: string | null;
};

const SpacedContractsCard = styled.div`
	@media (min-width: 992px) {
		margin-right: -4px;
	}
`;
const SpacedTansactionsCard = styled.div`
	@media (min-width: 992px) {
		margin-left: -4px;
	}
`;
const GamesContainer = styled.div`
	margin-bottom: 10px;
`;

const Summary: FunctionComponent<SummaryProps> = ({
	colorScheme,
	evaluations,
	player,
	playerCombined,
	viewClassification
}) => {
	const SURPLUS_VALUE_IAM_GROUP = 26;
	const [mountContracts, setMountContracts] = useState(true);
	const history = useHistory();
	const contractsMj = playerCombined.proProfile?.contractsMj.map(e => ({ ...e, major: true }));
	const contractsMjDefined = contractsMj ?? [];
	const contractsMn = playerCombined.proProfile?.contractsMn.map(e => ({ ...e, major: false }));
	const contractsMnDefined = contractsMn ?? [];
	const onClickContract = (contractId: number) => {
		const levelRaw = getQueryStringValue("level") as string | undefined;
		const level = levelRaw === MINORS ? levelRaw : MAJOR;
		setMountContracts(false);
		setTimeout(() => {
			history.push(
				`?philId=${playerCombined.playerProId}&view=contract&contractId=${contractId}&level=${level}&viewClassification=pro`
			);
		}, 50);
	};

	const canViewSurplusValue = useIsGroupMember(SURPLUS_VALUE_IAM_GROUP);

	return (
		<React.Fragment>
			{playerCombined == null && (
				<Card>
					<ColoredCardHeader colorScheme={colorScheme.secondary} text="Recent Statistics">
						<div style={{ paddingRight: "8px" }} />
					</ColoredCardHeader>
					<div className="loading-container">
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
						<div className="loading-item" style={{ height: "27px" }} />
					</div>
				</Card>
			)}
			{playerCombined.playerClassification?.toUpperCase() === "PRO" &&
				playerCombined.bamId &&
				canViewSurplusValue && <SurplusValueBox colorSchemeGroup={colorScheme} bamId={playerCombined.bamId} />}
			{playerCombined != null && playerCombined.playerClassification?.toUpperCase() === "PRO" && (
				<GamesContainer>
					<GameSummaryWidget playerId={playerCombined.id} />
				</GamesContainer>
			)}
			{viewClassification === PRO && player !== null && (
				<div style={{ overflow: "auto" }}>
					<StatisticsPage
						colorScheme={colorScheme}
						player={player}
						playerType={playerCombined && playerCombined.isPitcher ? "pitcher" : "batter"}
						summary={true}
					/>
				</div>
			)}
			{viewClassification === AMA && <CollegeSplits colorScheme={colorScheme} player={playerCombined} />}
			<EvaluationsTable colorScheme={colorScheme} evaluations={evaluations} />
			{viewClassification === AMA && playerCombined?.team != null && (
				<ScheduleTable colorSchemeGroup={colorScheme} player={playerCombined} style={{ maxHeight: "250px" }} />
			)}
			{viewClassification === PRO && (
				<Grid
					style={{
						flex: 1,
						paddingLeft: "0px",
						marginLeft: "0px",
						paddingRight: "0px",
						marginRight: "0px",
						width: "100%"
					}}
				>
					<Row style={{ padding: 0 }}>
						<Col md={12} lg={6}>
							{mountContracts && (
								<SpacedContractsCard>
									<ContractsTable
										colorScheme={colorScheme}
										contracts={[...contractsMjDefined, ...contractsMnDefined]}
										onClickContract={onClickContract}
										tableMaxHeight={275}
									/>
								</SpacedContractsCard>
							)}
						</Col>
						<Col md={12} lg={6}>
							<SpacedTansactionsCard>
								<TransactionsTable
									colorScheme={colorScheme}
									player={playerCombined}
									transactions={playerCombined.proProfile?.transactions}
									tableMaxHeight={275}
								/>
							</SpacedTansactionsCard>
						</Col>
					</Row>
				</Grid>
			)}
		</React.Fragment>
	);
};

export default Summary;
