import styled from "@emotion/styled";
import { CSSProperties } from "react";
import { CSSObjectWithLabel } from "react-select";

// Color Schemes

export const GreenColorScheme = {
	color: "#028402",
	text: "white",
	hover: "#009900",
	hoverText: "white",
	selected: "#009900",
	selectedText: "white"
};

export const GrayColorScheme = {
	color: "#737373",
	text: "white",
	hover: "#8c8c8c",
	hoverText: "white",
	selected: "#8c8c8c",
	selectedText: "white"
};

// Style Objects

export const ModalStyle = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		maxWidth: "calc(100vw - 100px)",
		maxHeight: "calc(100vh - 100px)",
		minWidth: "50%",
		overflow: "scroll"
	},
	overlay: { zIndex: 1000 }
};

export const DropDownButtonStyle = {
	boxShadow: "none",
	backgroundColor: "transparent",
	border: "none"
};

export const MaxWidthSelectStyle = {
	container: (provided: CSSObjectWithLabel) => ({
		...provided,
		width: "100%"
	})
};

export const MaxWidthStyledSelectStyle = {
	container: { width: "100%" }
};

export const CardStyle = {
	margin: 10,
	height: "auto"
};

export const ColoredCardHeaderStyle = {
	height: "100%"
};

export const ListColoredCardHeaderStyle = {
	height: "auto"
};

export const ThumbsTdStyle = {
	textAlign: "center",
	padding: "0px"
} as React.CSSProperties;

export const ThumbsIconSmallStyle = {
	height: "12px",
	width: "12px",
	marginRight: "5px"
};

export const AttachmentDeleteMargin = {
	marginLeft: "135px"
};

export const ThumbsIconStyle = {
	height: "20px",
	width: "20px",
	padding: "0px 2px"
};

export const ThumbsNeutralIconStyle = {
	...ThumbsIconStyle,
	transform: "rotate(-90deg)"
};

export const CircularProgressStyle = {
	textAlign: "center"
} as React.CSSProperties;

export const QualitiativeTextFieldStyle = {
	width: "400px",
	backgroundColor: "transparent",
	border: "1px solid hsl(0,0%,80%)",
	borderRadius: "4px",
	margin: "0 20px",
	padding: "5.5px 8px",
	fontSize: "11px"
} as React.CSSProperties;

export const TextFieldStyle = {
	width: "120px",
	backgroundColor: "transparent",
	border: "1px solid hsl(0,0%,80%)",
	borderRadius: "4px",
	margin: "0 20px",
	padding: "5.5px 8px",
	fontSize: "11px"
} as React.CSSProperties;

export const NoteTextFieldStyle = {
	width: "100%",
	backgroundColor: "transparent",
	border: "1px solid hsl(0,0%,80%)",
	borderRadius: "4px",
	padding: "5.5px 8px",
	fontSize: "11px",
	minHeight: 30.5,
	alignItems: "center"
} as React.CSSProperties;

export const MultiLineNoteTextFieldStyle = {
	width: "100%",
	backgroundColor: "transparent",
	border: "1px solid hsl(0,0%,80%)",
	borderRadius: "4px",
	padding: "5.5px 8px",
	fontSize: "11px",
	minHeight: 35
} as React.CSSProperties;

export const GoalDrillContentTextFieldStyle = {
	width: "100%",
	backgroundColor: "transparent",
	border: "1px solid hsl(0,0%,80%)",
	borderRadius: "4px",
	padding: "5.5px 8px",
	fontSize: "12px",
	fontWeight: "normal",
	minHeight: 30.5
} as React.CSSProperties;

export const SecondaryButtonStyle = {
	marginRight: "10px"
};

export const WrapperStyle = {
	marginTop: "30px",
	borderColor: "lightgray"
};

export const MarginWrapperStyle = {
	marginTop: "30px",
	marginBottom: "30px",
	borderColor: "lightgray"
};

export const DetailsWrapperStyle = {
	marginTop: "30px",
	borderColor: "lightgray",
	width: "75%",
	marginLeft: "auto",
	marginRight: "auto"
};

export const TopWrapperStyle = {
	borderColor: "lightgray"
};

export const AdjustmentButtonStyle = {
	width: "135px"
};

export const EvenStyle = {
	backgroundColor: "white"
};

export const OddStyle = {
	backgroundColor: "rgb(238, 238, 238)"
};

export const CloseStyle = {
	marginLeft: "10px",
	height: "18px",
	width: "18px"
};

export const PlayerSearchStyle = {
	width: "300px",
	color: "black"
};

export const CheckboxLeftStyle = {
	marginLeft: "5px"
};

export const CheckboxLeftInlineStyle = {
	marginLeft: "5px",
	display: "inline-block"
};

export const CheckboxRightStyle = {
	marginRight: "5px"
};

export const CheckboxMarginRightStyle = {
	marginRight: "50px"
};

export const ActionButtonStyle = {
	width: "25px",
	height: "25px",
	marginLeft: "10px"
};

export const MetricTypeStyle = {
	width: "300px"
};

export const GoalSeasonStyle = {
	width: "210px"
};

export const GoalStatusStyle = {
	width: "210px"
};

export const WarningStyle = {
	marginRight: "5px"
};

export const TagStyle = {
	height: "16px",
	width: "16px"
};

export const DrillTooltipStyle = {
	display: "table",
	width: "400px",
	marginLeft: "10px"
};

export const LockIconStyle = {
	height: "14px",
	width: "14px",
	marginRight: "5px"
};

export const IconStyle = {
	verticalAlign: "middle",
	marginLeft: "10px"
};

export const AddAttachmentButtonStyle = {
	marginTop: "20px"
};

export const UrlTextFieldStyle = {
	width: "400px",
	margin: "10px 0"
};

export const GoalDrillDeleteStyle = {
	cursor: "pointer"
};

export const RemoveButtonStyle = {
	marginRight: "10px"
};

export const TooltipStyle = {
	width: "150px",
	transform: "translate(-50%, 75%)",
	whiteSpace: "pre-wrap"
};

export const TooltipRightStyle = {
	width: "150px",
	transform: "translate(-75%, 75%)",
	whiteSpace: "pre-wrap"
};

export const TooltipLeftStyle = {
	width: "150px",
	transform: "translate(-25%, 75%)",
	whiteSpace: "pre-wrap"
};

export const TranslateRightTooltipStyle = {
	translate: "50px"
};

export const InfoStyle = {
	height: "20px",
	width: "20px"
};

export const BackButtonStyle = {
	marginRight: "50px"
};

export const AddNoteStyle = {
	minWidth: "85px",
	marginRight: "15px"
};

export const SmallCircularProgressRightStyle = {
	height: 32,
	margin: "auto",
	float: "right"
} as React.CSSProperties;

export const SmallCircularProgressStyle = {
	height: 32,
	marginLeft: "10px"
} as React.CSSProperties;

export const AttachmentsCircularProgressStyle = {
	height: 32,
	marginLeft: "128px"
} as React.CSSProperties;

export const LeftButtonStyle = {
	marginRight: "10px"
};

export const RightButtonStyle = {
	marginLeft: "10px"
};

export const BothButtonStyle = {
	marginRight: "10px",
	marginLeft: "10px"
};

// Styled Components

export const TextAlignLeftDiv = styled.div({
	textAlign: "left"
});

export const NoFocusAreaDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	fontSize: "26px",
	margin: "50px"
});

export const NoGoalGraphDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	fontSize: "26px",
	margin: "50px"
});

export const NoGoalNotesDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	fontSize: "26px",
	padding: "40px",
	borderBottom: "2px solid lightgray"
});

export const FocusAreaWrapper = styled.div({
	fontSize: "16px",
	fontWeight: "normal",
	margin: "5px"
});

export const FocusAreaFlexDiv = styled.div({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center"
});

export const FocusAreaDeletedTitleDiv = styled.div({
	opacity: ".45"
});

export const DeletedSupportingActionDiv = styled.div({
	opacity: ".5"
});

export const SupportingActionFlexDiv = styled.div({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center"
});

export const DrillWrapper = styled.div({
	fontWeight: "normal",
	fontSize: "16px",
	borderTop: "1px solid lightgray",
	padding: "10px"
});

export const DrillNameDiv = styled.div({
	fontWeight: "bold",
	marginRight: "5px"
});

export const DrillDateAuthorDiv = styled.div({
	fontSize: "0.9em",
	fontWeight: "normal",
	fontStyle: "italic",
	marginRight: "5px"
});

export const DrillContentDiv = styled.div({
	fontWeight: "normal"
});

type TDrillAddDiv = { hasDrillsToDisplay?: boolean };
export const DrillAddDiv = styled.div<TDrillAddDiv>(({ hasDrillsToDisplay = false }) => {
	return {
		padding: "10px",
		marginBottom: "10px",
		borderTop: "1px solid lightgray",
		display: "flex",
		justifyContent: `${hasDrillsToDisplay ? "flex-end" : "space-between"}`
	};
});

type TMinWidthDiv = { minWidth?: string; flex?: boolean };
export const MinWidthDiv = styled.div<TMinWidthDiv>(({ minWidth, flex }) => {
	return {
		minWidth: minWidth,
		display: `${flex ? "flex" : undefined}`
	};
});

type TMaxWidthDiv = { maxWidth?: string; flex?: boolean };
export const MaxWidthDiv = styled.div<TMaxWidthDiv>(({ maxWidth, flex }) => {
	return {
		maxWidth: maxWidth,
		display: `${flex ? "flex" : undefined}`
	};
});

export const NoteDatePickerDiv = styled.div({
	width: "100px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

export const LinkHover = styled.a({
	"&:hover": {
		opacity: "0.5"
	}
});

export const FocusAreasOptionsContainer = styled.div({
	width: "100%",
	height: "20px"
});

export const ShowAllDeletedDiv = styled.div({
	cursor: "pointer",
	display: "inline-block",
	fontWeight: "normal",
	float: "right",
	textDecoration: "underline",
	"&:hover": {
		opacity: "0.5"
	},
	marginRight: "10px"
});

export const ExpandCollapseAllDiv = styled.div({
	cursor: "pointer",
	display: "inline-block",
	fontWeight: "normal",
	float: "right",
	textDecoration: "underline",
	"&:hover": {
		opacity: "0.5"
	}
});

export const FlexDiv = styled.div({
	display: "flex"
});

export const FlexStartDiv = styled.div({
	display: "flex",
	alignItems: "flex-start"
});

export const PaddedDiv = styled.div({
	padding: "10px"
});

export const PaddedLeftRightDiv = styled.div({
	paddingRight: "5px",
	paddingLeft: "5px"
});

export const FloatRightDiv = styled.div({
	float: "right"
});

export const FloatRightMarginDiv = styled.div({
	float: "right",
	marginLeft: "10px"
});

export const WideFloatRightDiv = styled.div({
	float: "right",
	minWidth: "px",
	display: "flex",
	justifyContent: "flex-end"
});

export const YesNoDiv = styled.div({
	minWidth: "100px"
});

export const AreYouSureDiv = styled.div({
	marginRight: "20px"
});

export const FlexCenteredDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

export const FlexSpaceBetweenPaddedDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "10px",
	borderBottom: "1px solid lightgray"
});

export const FlexCenteredPaddedDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	padding: "10px",
	borderBottom: "1px solid lightgray"
});

export const FlexCenteredHorizontalDiv = styled.div({
	display: "flex",
	alignContent: "center",
	flexDirection: "row"
});

export const FlexCenteredColumnDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	margin: "10px"
});

export const AdjustmentTierContainer = styled.div({
	display: "flex",
	alignItems: "center"
});

export const GoalToggleContainer = styled.div({
	display: "flex",
	alignItems: "center",
	fontWeight: "normal",
	padding: "5px 10px"
});

export const GoalToggleLabelContainer = styled.div({
	marginRight: "2px"
});

type EvenOddDivProps = {
	textAlign?: CSSProperties["textAlign"];
};
export const EvenDiv = styled.div<EvenOddDivProps>(({ textAlign = "center" }) => ({
	textAlign: textAlign,
	width: "100%",
	padding: "2px",
	backgroundColor: "rgb(238, 238, 238)",
	outline: "1px solid rgb(200, 200, 200)"
}));

export const OddDiv = styled.div<EvenOddDivProps>(({ textAlign = "center" }) => ({
	textAlign: textAlign,
	width: "100%",
	padding: "2px",
	outline: "1px solid rgb(200, 200, 200)"
}));

export const ModalInfo = styled.div({
	fontSize: "12px"
});

export const FlexSpaceEvenlyDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-evenly"
});

export const FlexSpaceBetweenDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	flexWrap: "wrap"
});

type LoadingDivProps = {
	height: string;
};
export const LoadingDiv = styled.div<LoadingDivProps>(({ height = "auto" }) => ({
	height: height,
	width: "100%",
	borderRadius: "3px",
	boxShadow:
		"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
}));

export const ListSelectContainer = styled.div({
	width: "150px"
});

export const GoalChartContainer = styled.div({
	minHeight: "450px"
});

export const CardHeaderContentsDiv = styled.div({
	display: "flex",
	justifyContent: "space-between",
	width: "100%",
	flexWrap: "wrap",
	rowGap: "5px"
});

export const PlayerHeader = styled.div({
	display: "flex",
	alignItems: "center"
});

export const PlayerNameDiv = styled.div({
	fontSize: "18px",
	display: "flex",
	alignItems: "center",
	paddingLeft: "8px",
	paddingRight: "10px"
});

export const PlayerHeaderSpan = styled.span({
	marginRight: "5px",
	fontSize: ".8em",
	fontWeight: "normal"
});

export const DropdownSelectsContainerDiv = styled.div({
	display: "flex",
	justifyContent: "space-between",
	flexWrap: "wrap"
});

export const DropdownSelectContainerDiv = styled.div({
	display: "flex",
	alignItems: "center"
});

export const TooltipButtonsDiv = styled.div({
	display: "flex",
	paddingLeft: "7px"
});

type TBioDivProps = {
	backgroundColor: string;
	color: string;
};
export const BioDiv = styled.div<TBioDivProps>(({ backgroundColor, color }) => {
	return {
		padding: "5px 10px 10px 12px",
		backgroundColor: backgroundColor,
		color: color,
		display: "flex"
	};
});

export const BioDataContainer = styled.div({
	display: "flex",
	marginRight: "30px"
});

export const BioDataSection = styled.div({
	marginRight: "10px"
});

export const BioDataLabels = styled.div({
	fontWeight: "bold",
	marginRight: "10px"
});

type TParentGoalsDivProps = {
	borderColor: string;
};
export const ParentGoalsDiv = styled.div<TParentGoalsDivProps>(({ borderColor }) => {
	return {
		width: "50%",
		borderRight: `2px solid ${borderColor}`,
		backgroundColor: "#d8d8d8"
	};
});

type TPHPGoalsDivProps = {
	borderColor: string;
};
export const PHPGoalsDiv = styled.div<TPHPGoalsDivProps>(({ borderColor }) => {
	return {
		width: "25%",
		borderLeft: `2px solid ${borderColor}`,
		borderRight: `2px solid ${borderColor}`,
		backgroundColor: "#e7f5fe"
	};
});

type TStrengthsDivProps = {
	borderColor: string;
};
export const StrengthsDiv = styled.div<TStrengthsDivProps>(({ borderColor }) => {
	return {
		width: "25%",
		borderLeft: `2px solid ${borderColor}`,
		backgroundColor: "#c4dfca"
	};
});

type TGoalSectionLabelProps = {
	backgroundColor: string;
	color: string;
};
export const GoalSectionLabel = styled.div<TGoalSectionLabelProps>(({ backgroundColor, color }) => {
	return {
		padding: "10px",
		fontWeight: "bold",
		backgroundColor: backgroundColor,
		color: color,
		textAlign: "center"
	};
});

type PlayerPlanFooterProps = {
	backgroundColor: string;
};
export const PlayerPlanFooter = styled.div<PlayerPlanFooterProps>(({ backgroundColor }) => {
	return {
		padding: "10px",
		fontWeight: "bold",
		backgroundColor: backgroundColor,
		color: "white",
		textAlign: "center",
		borderBottomLeftRadius: "3px",
		borderBottomRightRadius: "3px"
	};
});

export const ShowCompletedSpan = styled.span({
	display: "flex",
	float: "right"
});

export const GoalContainer = styled.div({
	overflow: "scroll"
});

export const GoalSectionHeader = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "10px",
	backgroundColor: "#C1C7C9",
	color: "black",
	cursor: "pointer",
	minHeight: "25px",
	":hover": {
		backgroundColor: "rgb(255,226,223)"
	}
});

export const GoalSectionLeftDiv = styled.div({
	display: "flex",
	alignItems: "center",
	// width: "190px"
	width: "70px"
});

export const GoalSectionCenterDiv = styled.div({
	paddingLeft: "50px",
	paddingRight: "50px"
});

export const GoalSectionRightDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	// width: "320px"
	width: "120px",
	marginLeft: "15px"
});

export const MetricTypeDiv = styled.div({
	// marginLeft: "10px"
});

export const ProgressDiv = styled.div({
	display: "inline-block",
	width: "50px",
	textAlign: "left",
	marginLeft: "10px"
});

export const TrendProgressDiv = styled.div({
	display: "inline-block",
	width: "35px",
	textAlign: "right",
	marginRight: "10px"
});

export const GreenSpan = styled.span({
	color: "green"
});

export const RedSpan = styled.span({
	color: "red"
});

export const DimGraySpan = styled.span({
	color: "dimgray"
});

type GoalHeaderProps = {
	isSelected?: boolean;
	isPHP?: boolean;
	isStrength?: boolean;
	isLowPriority?: boolean;
	isForNextYear?: boolean;
};
export const GoalHeader = styled.div<GoalHeaderProps>(
	({ isSelected = false, isPHP = false, isStrength = false, isLowPriority = false, isForNextYear = false }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "10px",
		borderBottom: "1px solid #bdbdbd",
		backgroundColor: isSelected ? "rgb(255,226,223)" : isStrength ? "#c4dfcb" : isPHP ? "#e7f5fe" : "#d8d8d8",
		color: isLowPriority || isForNextYear ? "grey" : "black",
		cursor: isLowPriority ? "not-allowed" : "pointer",
		fontStyle: isLowPriority || isForNextYear ? "italic" : undefined,
		minHeight: "27px",
		":hover": {
			backgroundColor: isLowPriority ? undefined : "rgb(255,226,223)"
		}
	})
);

export const GoalContent = styled.div({
	padding: "20px"
});

export const GoalStatusDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	margin: "10px",
	flexWrap: "wrap"
});

export const HeaderStatusCheckboxDiv = styled.div({
	display: "flex",
	alignItems: "center"
});

export const StatusCheckboxDiv = styled.div({
	display: "flex",
	alignItems: "center",
	marginRight: "20px",
	marginTop: "5px",
	marginBottom: "5px"
});

export const ModalContainer = styled.div({
	textAlign: "center",
	maxWidth: "1000px"
});

export const AddGoalDrillHeader = styled.div({
	textAlign: "center"
});

export const AddGoalDrillContentDiv = styled.div({
	width: "300px",
	marginTop: "20px"
});

export const ModalContents = styled.div({
	margin: "10px 10px 30px 10px"
});

export const NotesModalContents = styled.div({
	textAlign: "left"
});

export const NotesConfirmDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	fontSize: "1.5em",
	margin: "50px",
	fontWeight: "normal"
});

type NotesConfirmButtonDivProps = {
	isTranslate?: boolean;
};
export const NotesConfirmButtonDiv = styled.div<NotesConfirmButtonDivProps>(({ isTranslate = false }) => ({
	float: "right",
	transform: isTranslate ? "translate(0, -150%)" : undefined,
	marginRight: isTranslate ? "10px" : undefined
}));

export const GoalContents = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginBottom: "10px"
});

export const SplitContents = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginTop: "20px"
});

export const SeasonContents = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginTop: "20px"
});

export const DrillContents = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginTop: "30px"
});

export const TitleSpan = styled.span({
	marginRight: "10px"
});

export const AddGoalTable = styled.table({
	width: "100%",
	borderCollapse: "collapse"
});

export const AddGoalTr = styled.tr({});

export const AddGoalTd = styled.td({
	padding: "5px 10px",
	fontWeight: "normal"
});

export const DrillTable = styled.table({
	width: "100%",
	borderCollapse: "collapse"
});

export const DrillTd = styled.td({
	padding: "5px 10px",
	fontWeight: "normal"
});

export const RemovedSpan = styled.span({
	color: "red",
	fontWeight: "bold",
	marginRight: "5px"
});

export const NoteTable = styled.table({
	width: "100%",
	borderCollapse: "collapse"
});

export const NoteTr = styled.tr({});

export const NoteTd = styled.td({
	padding: "5px 10px",
	fontWeight: "normal"
});

export const GoalDetailsTable = styled.table({
	width: "100%",
	fontSize: "20px"
});

export const GoalDetailsTr = styled.tr({});

export const GoalDetailsTd = styled.td({
	padding: "5px 10px",
	fontWeight: "normal"
});

export const GoalDetailsCenteredTd = styled.td({
	textAlign: "center"
});

export const GoalDetailsWhitespace = styled.div({
	margin: "10px"
});

export const GoalDetailsCenteredContainer = styled.div({
	textAlign: "center"
});

export const GoalDetailsFlexCenteredContainer = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

export const GoalDetailsMetricValueContainer = styled.div({
	marginRight: "20px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

export const GoalDetailsDescriptionTd = styled.td({
	textAlign: "center",
	fontSize: "14px"
});

export const TargetTable = styled.table({
	width: "100%"
});

export const TargetTr = styled.tr({});

export const TargetTd = styled.td({
	padding: "5px 10px",
	fontWeight: "normal"
});

export const SmallCol = styled.col({
	width: "5%"
});

export const MediumCol = styled.col({
	width: "10%"
});

export const LargeCol = styled.col({
	width: "50%"
});

export const NoGoalsDiv = styled.div({
	width: "100%",
	textAlign: "center",
	padding: "20px"
});

export const NoApprovalDiv = styled.div({
	width: "100%",
	textAlign: "center",
	paddingTop: "20px",
	paddingBottom: "20px"
});

export const TableTr = styled.tr({
	color: "black",
	cursor: "pointer",
	"&:hover": {
		backgroundColor: "rgb(0,45,114,.125) !important"
	}
});

type TableTdProps = {
	isNoPadding?: boolean;
};
export const TableTd = styled.td<TableTdProps>(({ isNoPadding = false }) => ({
	padding: isNoPadding ? undefined : "5px 0"
}));

export const SplitSelectContainer = styled.div({
	width: "100%",
	textAlign: "left"
});

export const SeasonSelectContainer = styled.div({
	width: "150px",
	textAlign: "left"
});

export const GoalSeasonSelectContainer = styled.td({
	color: "black",
	width: "150px",
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center"
});

export const MetricTypeSelectContainer = styled.div({
	width: "300px",
	textAlign: "left",
	margin: "0 10px 0 20px"
});

export const MetricSubtypeSelectContainer = styled.div({
	width: "300px",
	textAlign: "left",
	margin: "0 20px 0 10px"
});

export const TargetSelectContainer = styled.div({
	width: "120px",
	textAlign: "left",
	marginLeft: "20px"
});

export const MetricSelectContainer = styled.div({
	width: "200px",
	margin: "0 20px"
});

export const WideDrillSelectContainer = styled.div({
	width: "300px",
	marginTop: "20px"
});

export const WideMetricSelectContainer = styled.div({
	width: "300px",
	margin: "0 20px"
});

export const StrengthCheckboxDiv = styled.div({
	display: "flex",
	alignItems: "center",
	fontSize: "12px",
	fontWeight: "bold",
	margin: "10px 10px 30px 10px"
});

export const PriorityCheckboxDiv = styled.div({
	display: "flex",
	alignItems: "center",
	fontSize: "12px",
	fontWeight: "bold",
	marginLeft: "10px"
});

export const AddTargetContainer = styled.div({
	display: "flex",
	justifyContent: "space-between"
});

export const AddTargetSelectDiv = styled.div({
	width: "calc(50% - 5px)"
});

export const ShowDeletedCheckboxDiv = styled.div({
	display: "flex",
	alignItems: "center",
	float: "right"
});

export const AddFADiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	paddingTop: "10px",
	borderTop: "2px solid lightgray"
});

export const NotesContainer = styled.div({
	padding: "5px 10px",
	marginTop: "10px",
	maxHeight: "400px",
	overflowY: "auto",
	border: "1px solid lightgray",
	fontWeight: "normal"
});

export const NoteContentSpan = styled.span({
	whiteSpace: "pre-line",
	fontWeight: "normal"
});

export const NoteContentDiv = styled.div({
	paddingTop: "10px"
});

export const AddNotesDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "left",
	flexWrap: "wrap",
	padding: "10px"
});

export const AddNotesStartDiv = styled.div({
	display: "flex",
	alignItems: "start",
	justifyContent: "space-between",
	padding: "10px"
});

export const AddNoteLkSelectDiv = styled.div({
	width: "215px",
	marginTop: "5px",
	marginBottom: "5px",
	marginRight: "5px"
});

export const AddNoteContentDiv = styled.div({
	width: "40%",
	margin: "5px"
});

export const NoteFiltersContainer = styled.div({
	display: "flex",
	flexDirection: "column"
});

export const NoteDatePickerContainer = styled.div({
	display: "flex",
	alignItems: "center",
	paddingBottom: "5px"
});

export const NoteFiltersDiv = styled.div({
	display: "flex",
	alignItems: "center",
	paddingBottom: "5px"
});

export const NoteSortToggle = styled.div({
	cursor: "pointer",
	padding: "2px",
	fontSize: "1.25em"
});

export const NoteSortDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	minWidth: "200px",
	paddingBottom: "5px"
});

export const NoteUserSelectDiv = styled.div({
	width: "215px",
	marginRight: "5px"
});

export const NoteGoalFilterSelectDiv = styled.div({
	width: "215px",
	marginRight: "5px"
});

export const NoteFocusAreaFilterSelectDiv = styled.div({
	width: "215px",
	marginRight: "5px"
});

export const NoteDrillFilterSelectDiv = styled.div({
	width: "215px",
	marginRight: "5px"
});

export const NoteTypeSelectDiv = styled.div({
	width: "215px",
	marginRight: "5px"
});

export const NoteMetricTypeSelectDiv = styled.div({
	width: "215px",
	marginRight: "5px"
});

type HeaderlessTableProps = {
	width?: string;
	borderRight?: string;
};
export const HeaderlessTable = styled.table<HeaderlessTableProps>(({ width = "100%", borderRight = undefined }) => ({
	width: width,
	borderCollapse: "collapse",
	textAlign: "center",
	borderRight: borderRight
}));

export const TopTrendsContainer = styled.div({
	width: "100%",
	display: "flex"
});

export const ListTrendContainer = styled.div({
	display: "flex",
	alignItems: "center",
	fontWeight: "normal"
});

export const ListTotalSpan = styled.span({
	marginRight: "10px",
	width: "25px",
	textAlign: "right"
});

export const ListTotalLabelSpan = styled.span({
	fontSize: ".7em",
	fontWeight: "bold"
});

export const PlayerListDiv = styled.div({
	width: "350px"
});

export const PlayerPlanApprovalDiv = styled.div({
	minWidth: "850px",
	flexGrow: 5
});

export const AttachmentsLabel = styled.div({
	margin: "10px 0",
	fontWeight: "bold"
});

export const AttachmentInputDiv = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column"
});

export const OrSpan = styled.span({
	marginTop: "10px",
	fontWeight: "bold"
});

export const StepCircleContainer = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginBottom: "30px"
});

type TStepCircleProp = { isActive?: boolean; isComplete?: boolean };
export const StepCircleDiv = styled.div<TStepCircleProp>(({ isActive = false, isComplete = false }) => {
	return {
		height: "65px",
		width: "65px",
		lineHeight: "65px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "10px",
		borderRadius: "50%",
		fontSize: "14px",
		fontWeight: "bold",
		color: isComplete ? "#2b6805" : isActive ? "black" : "#b7b7b7",
		textAlign: "center",
		border: isComplete ? "3px solid #2b6805" : isActive ? "3px solid black" : "3px solid #b7b7b7",
		backgroundColor: isComplete ? "#e0edd7" : isActive ? "#ededed" : "white"
	};
});

type TStepDividerProp = { isComplete?: boolean };
export const StepDivider = styled.div<TStepDividerProp>(({ isComplete = false }) => {
	return {
		height: "3px",
		background: isComplete ? "#2b6805" : "#b7b7b7",
		width: "40px",
		margin: "10px"
	};
});

export const InputLabel = styled.div({
	fontWeight: "bold",
	fontSize: "1.1em"
});

export const CheckboxDiv = styled.div({
	display: "flex",
	alignItems: "center",
	marginLeft: "20px"
});

export const SecondaryGoalCheckboxDiv = styled.div({
	display: "flex",
	alignItems: "center",
	marginTop: "20px",
	fontStyle: "italic"
});

export const PlayerPlanGoalSelectContainer = styled.div({
	display: "flex",
	alignItems: "center",
	marginTop: "30px"
});

export const ChangePlayerPlanGoalSelectContainer = styled.div({
	display: "flex",
	alignItems: "center",
	width: "400px"
});

type TNoteContainerProp = { isShowBorder: boolean };
export const PlayerPlanNoteContainer = styled.div<TNoteContainerProp>(({ isShowBorder }) => {
	return {
		padding: "10px",
		borderBottom: isShowBorder ? "1px solid lightgray" : undefined
	};
});

export const PlayerPlanNoteHeaderDiv = styled.div({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "baseline",
	marginBottom: "5px"
});

export const NoteGoalDiv = styled.div({
	marginBottom: "5px",
	fontSize: ".9em"
});

export const NoteNameDiv = styled.div({
	fontSize: "1.2em",
	fontWeight: "bold",
	marginRight: "5px"
});

export const NoteDateDiv = styled.div({
	fontSize: "0.9em",
	fontStyle: "italic",
	marginRight: "5px"
});

type TNoteMetricTypeSpanProps = { color?: string };
export const NoteMetricTypeSpan = styled.span<TNoteMetricTypeSpanProps>(({ color }) => {
	return {
		fontWeight: "bold",
		marginRight: "5px",
		color: color
	};
});

export const NoteVideoDiv = styled.div({
	marginTop: "5px"
});

export const ListSummaryHeaderDiv = styled.div({
	display: "flex",
	alignItems: "center"
});

// Raw CSS for Datepicker

export const DATEPICKER_STYLE = `
.react-datepicker__input-container input {
	width: 100px;
	font-size: 14px;
	background-color: white;
	border: 1px solid #CCCCCC;
	border-radius: 3px;
	padding: 4px;
}
`;
