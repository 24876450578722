import { AxiosError, AxiosResponse, CancelToken } from "axios";
import dayjs, { Dayjs } from "dayjs";

import axios from "_redux/_utils/_axios";

export type TValue = string | number | boolean | null | undefined | Dayjs;
export type TDict = { [index: string]: TValue };

export function isAxiosError(error: Error | AxiosError): error is AxiosError {
	return (error as AxiosError).response !== undefined;
}

export const handleAxiosError = (error: Error | AxiosError): Promise<undefined> => {
	if (axios.isCancel(error)) return Promise.resolve(undefined);
	throw error;
};

export function buildPathWithQueryParams<T extends TDict | undefined>(basePath: string, queryParams?: T): string {
	if (queryParams === undefined) return basePath;

	let isFirstQueryParam = true;
	let queryParamsString = "?";
	Object.entries(queryParams).forEach((param: [string, TValue]) => {
		let paramValue = param[1];
		if (paramValue != null) {
			if (dayjs.isDayjs(paramValue)) paramValue = paramValue.format("YYYY-MM-DD HH:mm:ss");
			queryParamsString =
				queryParamsString + `${isFirstQueryParam ? "" : "&"}${param[0]}=${encodeURIComponent(paramValue)}`;
			isFirstQueryParam = false;
		}
	});
	return `${basePath}${queryParamsString}`;
}

export function fetchResource<TQueryParam extends TDict | undefined, TResponse>(
	basePath: string,
	queryParams: TQueryParam,
	cancelToken?: CancelToken
) {
	const path = buildPathWithQueryParams<TQueryParam>(basePath, queryParams);
	return axios
		.get(path, { cancelToken })
		.then((response: AxiosResponse<TResponse>) => response.data)
		.catch(handleAxiosError);
}

export function postResource<TPostBody extends TDict, TResponse, TQueryParam extends TDict = TDict>(
	basePath: string,
	body: TPostBody,
	queryParams?: TQueryParam,
	cancelToken?: CancelToken
) {
	const path = buildPathWithQueryParams<TQueryParam>(basePath, queryParams);
	return axios
		.post(path, body, { cancelToken })
		.then((response: AxiosResponse<TResponse>) => response.data)
		.catch(handleAxiosError);
}

export function putResource<TPutBody extends TDict, TResponse, TQueryParam extends TDict = TDict>(
	basePath: string,
	body: TPutBody,
	queryParams?: TQueryParam,
	cancelToken?: CancelToken
) {
	const path = buildPathWithQueryParams<TQueryParam>(basePath, queryParams);
	return axios
		.put(path, body, { cancelToken })
		.then((response: AxiosResponse<TResponse>) => response.data)
		.catch(handleAxiosError);
}
