import React, { useContext, useMemo } from "react";
import dayjs from "dayjs";
import { useSelector } from "@xstate/react";
import { SimpleGrid, GridItem } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { TReplacementLevelActive } from "_react/shared/data_models/phred/_types";
import { IProjectedPitcherProportions } from "_react/shared/data_models/projections/_types";
import { getPosition, getPositionGroup } from "_react/shared/_helpers/position_groups";
import { PHILLIES_TEAM_ID } from "_react/shared/_constants/ids";
import {
	POSITION_GROUP_SP,
	POSITION_GROUP_RP,
	POSITION_GROUP_1B_DH,
	PITCHER_POSITIONS
} from "_react/shared/_constants/position_groups";
import { TSimplePitcherProjections } from "_react/shared/data_models/projections/_types";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import ProjectionsStatCard from "_react/shared/ui/data/cards/ProjectionsStatCard/ProjectionsStatCard";
import ProjectionsTable from "_react/shared/ui/data/tables/ProjectionsTable/ProjectionsTable";
import SimilarPlayersProjectionTable from "_react/shared/ui/data/tables/SimilarPlayersProjectionTable/SimilarPlayersProjectionTable";
import FieldingProjectionsTable from "_react/shared/ui/data/tables/FieldingProjectionsTable/FieldingProjectionsTable";

import { StatCardMarginTopStyle, SectionStyleObject } from "_react/playerpage/pro/shared/_styles";
import {
	SIMILAR_PROJECTION_PLAYERS_COLUMNS,
	SIMILAR_PROJECTION_PLAYERS_COLUMNS_SUBSET,
	SIMILAR_PROJECTION_SP_COLUMNS_MOBILE,
	SIMILAR_PROJECTION_RP_COLUMNS_MOBILE
} from "_react/playerpage/pro/shared/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TSimpleProjectionsAll } from "_react/shared/data_models/projections/_types";
import {
	TPlayerPageProState,
	FETCHING_PROJECTIONS,
	FETCHING_REPLACEMENT_LEVEL,
	FETCHING_PROJECTED_PITCHER_PROPORTIONS
} from "_react/playerpage/pro/_machine";
import { PlayerPageProContext } from "_react/playerpage/pro/PlayerPageProProvider";

type TProjectionsSectionProps = {
	player: TPlayerPageCombinedPlayer;
};

const ProjectionsSection = ({ player }: TProjectionsSectionProps) => {
	//
	// Player Page Context
	//
	const playerPageProContext = useContext(PlayerPageProContext);

	const projectionsSeasonData: [number, number, boolean] | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.projectionsSeasonData
	);
	const [projectionsSeason, ros, isFetchingProjectionsSeason] = projectionsSeasonData
		? projectionsSeasonData
		: [dayjs().year(), 0, false];

	const projections: TSimpleProjectionsAll | null | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.projections
	);
	const replacementLevel: TReplacementLevelActive | null | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.replacementLevel
	);
	const projectedPitcherProportions: IProjectedPitcherProportions | null | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.projectedPitcherProportions
	);

	const isFetchingProjections: boolean = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.matches(FETCHING_PROJECTIONS)
	);
	const isFetchingReplacementLevel: boolean = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.matches(FETCHING_REPLACEMENT_LEVEL)
	);
	const isFetchingProjectedPitcherProportions: boolean = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.matches(FETCHING_PROJECTED_PITCHER_PROPORTIONS)
	);
	const isLoadingProjections = isFetchingProjections || isFetchingReplacementLevel || isFetchingProjectionsSeason;
	const isLoadingProjectedPitcherProportions = isFetchingProjectedPitcherProportions || isFetchingProjectionsSeason;
	const hasSpProjections = useMemo(() => {
		const currentSimplePitcherProjections = projections?.simplePitcherProjections.find(
			(proj: TSimplePitcherProjections) => proj.season === projectionsSeason && proj.ros === ros
		);
		return currentSimplePitcherProjections?.projSpRaa != null;
	}, [projections, projectionsSeason, ros]);

	//
	// Position Group Logic
	//
	const playerPosition = getPosition(player);
	const isPitcherPosition = PITCHER_POSITIONS.includes(playerPosition ?? "");
	// This is the position group used for the first statcard and similar players table
	// For pitchers, the primary position group is always SP because...
	// For SP and P
	// the first row will be SP projections and similar players (SP + P),
	// the second row will be RP projections and similar players (RP + P)
	// For RP
	// the "primary" row won't show because they don't have SP projections
	// the first and only row will be the RP projections and similar players (RP + P)
	const primaryPositionGroup = isPitcherPosition ? POSITION_GROUP_SP : getPositionGroup(player);

	// For position players display:
	// 		2XL and larger - all projections leaderboard columns, except position and age
	// 		Base to XL - player, org, and all projection value columns (automatically filtered by table component to just total RAR for mobile)
	// For pitchers display:
	// 		2XL and larger - all projections leaderboard columns, except position and age
	// 		MD to XL - player, org, SP RAR, and RP RAR
	// 		Base to SM - player, org, and just SP or RP projection based if primary or secondary row
	const similarPlayersTablePrimaryColumns = useBreakpointValue({
		base: isPitcherPosition ? SIMILAR_PROJECTION_SP_COLUMNS_MOBILE : SIMILAR_PROJECTION_PLAYERS_COLUMNS_SUBSET,
		md: SIMILAR_PROJECTION_PLAYERS_COLUMNS_SUBSET,
		"2xl": SIMILAR_PROJECTION_PLAYERS_COLUMNS
	});
	// Only Pitchers have a secondary statcard and table, and it's always RP
	const similarPlayersTableSecondaryColumns = useBreakpointValue({
		base: SIMILAR_PROJECTION_RP_COLUMNS_MOBILE,
		md: SIMILAR_PROJECTION_PLAYERS_COLUMNS_SUBSET,
		"2xl": SIMILAR_PROJECTION_PLAYERS_COLUMNS
	});

	// Only need margin top when there is a table with a title next to the StatCard
	const statCardStyling = useBreakpointValue({
		base: undefined,
		"2xl": StatCardMarginTopStyle
	});

	// Need smaller colspan on smaller screen sizes so that all the tables wrap appropriately
	// Need larger colspan on larger screen sizes so that it takes up the entire grid width
	const fieldingProjectionsColSpan = useBreakpointValue({
		base: 2,
		"2xl": 3
	});

	return (
		<Section label="Projections" placement="start" style={SectionStyleObject}>
			<SimpleGrid
				columns={3}
				minChildWidth={{
					md: "sm"
				}}
				spacing={6}
			>
				{/*
					The primary stat card and similar players table shows:
					- Position group information for position players
					- SP information for pitchers

					So, hide the primary stat card and similar players table if:
					- Player is a pitcher
					- Player has no SP projections
				 */}
				{(!isPitcherPosition || hasSpProjections) && (
					<>
						<GridItem>
							<ProjectionsStatCard
								playerId={player.id}
								data={{
									projections: projections,
									replacementLevel: replacementLevel,
									isLoadingProjections: isLoadingProjections,
									projectionsSeasonData: projectionsSeasonData,
									projectedPitcherProportions: projectedPitcherProportions,
									isLoadingProjectedPitcherProportions: isLoadingProjectedPitcherProportions
								}}
								shouldFetchData={false}
								showProjections={{ hitter: true, startingPitcher: true }}
								style={statCardStyling}
							/>
						</GridItem>
						{!isPitcherPosition && (
							<>
								<GridItem colSpan={2}>
									<ProjectionsTable
										title={"Multi-Year Projections"}
										playerId={player.id}
										shouldFetchData={false}
										data={{
											simpleProjections: projections?.simpleHitterProjections,
											platoonProjections: projections?.futureSeasonBattingProjs,
											replacementLevel: replacementLevel,
											isLoading: isLoadingProjections
										}}
									/>
								</GridItem>
								<GridItem colSpan={fieldingProjectionsColSpan}>
									<FieldingProjectionsTable title="Fielding Projections" playerId={player.id} />
								</GridItem>
							</>
						)}
						<GridItem colSpan={isPitcherPosition ? 2 : 3}>
							<SimilarPlayersProjectionTable
								title={`Similar Players (${
									primaryPositionGroup === POSITION_GROUP_1B_DH ? "1B/DH" : primaryPositionGroup
								})`}
								filters={{
									playerId: player.id,
									positionGroup: primaryPositionGroup
								}}
								toggleOptions={{
									isFortyMan: true,
									isSameOrg: true,
									isPhillies: player?.team?.parentTeam?.id !== PHILLIES_TEAM_ID,
									isLeague: true
								}}
								columns={similarPlayersTablePrimaryColumns}
							/>
						</GridItem>
					</>
				)}
				{/* Secondary stat card and similar players table for showing RP information for pitchers */}
				{isPitcherPosition && (
					<>
						<GridItem>
							<ProjectionsStatCard
								playerId={player.id}
								data={{
									projections: projections,
									replacementLevel: replacementLevel,
									isLoadingProjections: isLoadingProjections,
									projectionsSeasonData: projectionsSeasonData,
									projectedPitcherProportions: projectedPitcherProportions,
									isLoadingProjectedPitcherProportions: isLoadingProjectedPitcherProportions
								}}
								shouldFetchData={false}
								showProjections={{ reliefPitcher: true }}
								style={statCardStyling}
							/>
						</GridItem>
						<GridItem colSpan={2}>
							<SimilarPlayersProjectionTable
								title={`Similar Players (RP)`}
								filters={{
									playerId: player.id,
									positionGroup: POSITION_GROUP_RP
								}}
								toggleOptions={{
									isFortyMan: true,
									isSameOrg: true,
									isPhillies: player?.team?.parentTeam?.id !== PHILLIES_TEAM_ID,
									isLeague: true
								}}
								columns={similarPlayersTableSecondaryColumns}
							/>
						</GridItem>
					</>
				)}
			</SimpleGrid>
		</Section>
	);
};

export default ProjectionsSection;
