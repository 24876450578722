import { PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { TPitchTypes } from "_react/shared/_types/pitch_types";

import { TParsedPitchData } from "_react/shared/ui/presentation/plots/PitchMetricTimeSeriesPlot/_types";

export const parsePitchData = <T, K extends keyof T>(
	getPitchTypeValue: (obj: T) => string | T[K],
	getXValue: (obj: T) => Date | number | T[K],
	getYValue: (obj: T) => number | T[K],
	isReverseYAxis: boolean,
	pitchData?: Array<T>
): TParsedPitchData<T>[] => {
	const pitchDataParsedArray: TParsedPitchData<T>[] = [];

	// Loop through pitch types we want to display
	PITCH_TYPES.forEach((pitchType: TPitchTypes) => {
		const pitches = pitchData?.filter(pitch => getPitchTypeValue(pitch) === pitchType);
		if (pitches && pitches.length > 0) {
			const xValuesByPitchType = pitches.map((pitch: T) => getXValue(pitch) as Date | number);

			// Get the coordinates of the max x value for the pitch type (the point furthest to the right)
			// Will use this for creating the line labels
			const xMaxByPitchType = xValuesByPitchType.reduce(function(a, b) {
				return a > b ? a : b;
			});
			const yEndByPitchType = pitches.find((pitch: T) => {
				const xValue = getXValue(pitch) as Date | number;
				if (typeof xValue === "number") return xValue === xMaxByPitchType;
				else return xValue.getTime() === (xMaxByPitchType as Date).getTime();
			});

			const pitchDataDict = {
				pitchType: pitchType,
				xMaxDatum: { x: xMaxByPitchType, y: getYValue(yEndByPitchType!) as number },
				data: pitches
			};
			pitchDataParsedArray.push(pitchDataDict as TParsedPitchData<T>);
		}
	});

	// Sort by the y values of the x max coordinate (the point furthest to the right)
	// Because we want to order the line labels
	// The top line label should be the top furthest right value, the bottom line label should be the bottom furthest right value, etc.
	const pitchDataParsedArraySorted = pitchDataParsedArray.sort(
		(pitchTypeA, pitchTypeB) => pitchTypeB.xMaxDatum.y - pitchTypeA.xMaxDatum.y
	);
	if (isReverseYAxis) {
		pitchDataParsedArraySorted.reverse();
	}
	return pitchDataParsedArraySorted;
};

export const convertToNumber = (value: number | Date): number => {
	if (value instanceof Date) return value.getTime();
	return value;
};
