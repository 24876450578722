import React, { MouseEventHandler } from "react";
import {
	Stack,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Box,
	Center
} from "@chakra-ui/react";

import { TDPLSurveyYear } from "_react/playerpage/ama/profile/DPLQuestionnaire/_types";
import DPLQuestionnaireYearGroup from "_react/playerpage/ama/profile/DPLQuestionnaire/DPLQuestionnaireYearGroup";

type TDPLQuestionnairePreviousYearSurveysProps = {
	philId: number | null;
	previousYearSurveys: Array<TDPLSurveyYear>;
	onOpenModal: MouseEventHandler<HTMLButtonElement>;
	onCloseModal: () => void;
	isOpenModal: boolean;
};

const DPLQuestionnairePreviousYearSurveys = ({
	philId,
	previousYearSurveys,
	onOpenModal,
	onCloseModal,
	isOpenModal
}: TDPLQuestionnairePreviousYearSurveysProps) => {
	return (
		<Accordion allowMultiple width="100%">
			<AccordionItem borderTop="none" borderBottom="none">
				<AccordionButton padding="0" textAlign="center">
					<Center width="100%">
						<Box color="gray.700" fontSize="xs" fontWeight="bold">
							Previous Years
						</Box>
						<AccordionIcon />
					</Center>
				</AccordionButton>
				<AccordionPanel padding="0">
					<Stack spacing={3} direction="column" paddingTop="2">
						{previousYearSurveys.map((year: TDPLSurveyYear) => (
							<DPLQuestionnaireYearGroup
								year={year}
								philId={philId}
								onOpenModal={onOpenModal}
								onCloseModal={onCloseModal}
								isOpenModal={isOpenModal}
								key={year.year}
							/>
						))}
					</Stack>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	);
};

export default DPLQuestionnairePreviousYearSurveys;
