import React from "react";
import Check from "_react/shared/ui/icons/Check";

import { IBlendedSurplusValue } from "_react/shared/data_models/surplus_value/_types";
import { TProModelLeaderboard, TProModelProfile } from "_react/promodel/leaderboard/_types";
import { R540ManBox } from "_react/promodel/leaderboard/_styles";

// combine pro2_predictions and sv_blended
export function parseSvPlusProModelPredictions(
	leaderboardData: Array<TProModelLeaderboard> | null,
	blendedSurplusValues: Array<IBlendedSurplusValue> | null
): Array<TProModelLeaderboard> | null {
	if (leaderboardData && blendedSurplusValues) {
		leaderboardData = leaderboardData.map((item: TProModelLeaderboard) => {
			return {
				...item,
				blendedSv: blendedSurplusValues.find((sv: IBlendedSurplusValue) => sv.bamId === item.bamId)
			};
		});
	}
	return leaderboardData;
}

type T40ManR5Props = {
	leaderboardPlayerProProfile: TProModelProfile;
};

export const get40ManR5Color = ({ leaderboardPlayerProProfile }: T40ManR5Props) => {
	if (leaderboardPlayerProProfile.isOn40ManRoster === true) {
		return (
			<R540ManBox>
				<Check></Check>
				40 Man
			</R540ManBox>
		);
	}
	return leaderboardPlayerProProfile.r5EligibleYear;
};
