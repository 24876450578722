import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { IStatsPlayerFielding, IStatsPlayerFieldingOpps } from "_react/shared/data_models/defensive_observed/_types";

export type TStatsPlayerFieldingGetQueryParams = {
	playerId?: number;
	"playerId[in]"?: string;
	bamId?: number;
	"bamId[in]"?: string;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	fields?: string;
};

export type TStatsPlayerFieldingOppsGetQueryParams = {
	season?: number;
	"season[in]"?: string;
	position?: string;
	"position[in]"?: string;
	opportunitiesPerFullSeason?: number;
	"opportunitiesPerFullSeason[gte]"?: number;
	"opportunitiesPerFullSeason[lte]"?: number;
	expectedOutsPerFullSeason?: number;
	"expectedOutsPerFullSeason[gte]"?: number;
	"expectedOutsPerFullSeason[lte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
};

export const fetchStatsPlayerFielding = (queryParams: TStatsPlayerFieldingGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TStatsPlayerFieldingGetQueryParams, Array<IStatsPlayerFielding>>(
		"/stats_player_fielding",
		queryParams,
		cancelToken
	);

export const fetchStatsPlayerFieldingOpps = (
	queryParams: TStatsPlayerFieldingOppsGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TStatsPlayerFieldingOppsGetQueryParams, IStatsPlayerFieldingOpps>(
		"/stats_player_fielding_opps",
		queryParams,
		cancelToken
	);
