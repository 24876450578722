import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import {
	IPlayerSeasonHitterGrades,
	IPlayerSeasonHitterGradesByTeam,
	IThreeGradesReferenceApiResponse,
	IThreeGradesThresholdApiResponse
} from "_react/shared/data_models/hitter_grades/_types";

// player_season hitter grades

export type TPlayerSeasonHitterGradesGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	bats?: string;
	throws?: string;
	gameType?: string;
	pitches?: number;
	"pitches[gte]"?: number;
	"pitches[lte]"?: number;
	swings?: number;
	"swings[gte]"?: number;
	"swings[lte]"?: number;
	bips?: number;
	"bips[gte]"?: number;
	"bips[lte]"?: number;
	pa?: number;
	"pa[gte]"?: number;
	"pa[lte]"?: number;
	batSpeedGrade?: number;
	"batSpeedGrade[gte]"?: number;
	"batSpeedGrade[lte]"?: number;
	batToBallGrade?: number;
	"batToBallGrade[gte]"?: number;
	"batToBallGrade[lte]"?: number;
	swingDecisionsGrade?: number;
	"swingDecisionsGrade[gte]"?: number;
	"swingDecisionsGrade[lte]"?: number;
	attackAngleVertical?: number;
	"attackAngleVertical[gte]"?: number;
	"attackAngleVertical[lte]"?: number;
	isMeasuredSwingMetrics?: boolean;
	// There are more, add them as necessary
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPlayerSeasonHitterGrades = (
	queryParams: TPlayerSeasonHitterGradesGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPlayerSeasonHitterGradesGetQueryParams, Array<IPlayerSeasonHitterGrades>>(
		"/hitter_grades/player_season",
		queryParams,
		cancelToken
	);

export type TPlayerSeasonHitterGradesByTeamGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	teamId?: number;
	"teamId[in]"?: string;
	bats?: string;
	throws?: string;
	gameType?: string;
	pitches?: number;
	"pitches[gte]"?: number;
	"pitches[lte]"?: number;
	swings?: number;
	"swings[gte]"?: number;
	"swings[lte]"?: number;
	bips?: number;
	"bips[gte]"?: number;
	"bips[lte]"?: number;
	pa?: number;
	"pa[gte]"?: number;
	"pa[lte]"?: number;
	batSpeedGrade?: number;
	"batSpeedGrade[gte]"?: number;
	"batSpeedGrade[lte]"?: number;
	batToBallGrade?: number;
	"batToBallGrade[gte]"?: number;
	"batToBallGrade[lte]"?: number;
	swingDecisionsGrade?: number;
	"swingDecisionsGrade[gte]"?: number;
	"swingDecisionsGrade[lte]"?: number;
	attackAngleVertical?: number;
	"attackAngleVertical[gte]"?: number;
	"attackAngleVertical[lte]"?: number;
	isMeasuredSwingMetrics?: boolean;
	// There are more, add them as necessary
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPlayerSeasonHitterGradesByTeam = (
	queryParams: TPlayerSeasonHitterGradesByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPlayerSeasonHitterGradesByTeamGetQueryParams, Array<IPlayerSeasonHitterGradesByTeam>>(
		"/hitter_grades/player_season/team",
		queryParams,
		cancelToken
	);

// three_grades_reference

export type TThreeGradesReferenceGetQueryParams = {
	season?: number;
	level?: string;
	qualifiedHitters?: number;
	meanSwingSpeedProxy?: number;
	stdSwingSpeedProxy?: number;
	meanBatToBallScore?: number;
	stdBatToBallScore?: number;
	meanSwingDecision?: number;
	stdSwingDecision?: number;
	meanAttackAngle?: number;
	stdAttackAngle?: number;
	meanSwingSpeedEvent?: number;
	stdSwingSpeedEvent?: number;
	meanBatToBallScoreEvent?: number;
	stdBatToBallScoreEvent?: number;
	meanSwingDecisionEvent?: number;
	stdSwingDecisionEvent?: number;
	meanAttackAngleEvent?: number;
	stdAttackAngleEvent?: number;
	isPriorSeason?: boolean;
	includeAttackAngleNormalDistribution?: boolean;
	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchThreeGradesReference = (
	queryParams: TThreeGradesReferenceGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TThreeGradesReferenceGetQueryParams, Array<IThreeGradesReferenceApiResponse>>(
		"/three_grades_reference",
		queryParams,
		cancelToken
	);

// three_grades_threshold

export const fetchThreeGradesThreshold = (cancelToken?: CancelToken) =>
	fetchResource<undefined, Array<IThreeGradesThresholdApiResponse>>(
		"/three_grades_threshold",
		undefined,
		cancelToken
	);
