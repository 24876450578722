import React, { FC, useState, useEffect, useMemo, useContext, CSSProperties } from "react";
import { useCookies } from "react-cookie";
import { useMachine } from "@xstate/react";

import { useFetch, useQueryString } from "utils/url_helpers";
import { useUserData } from "_react/app/AppContents";
import { useIsMobile } from "_react/_hooks";
import { PlayerPageContext } from "_react/playerpage/_context";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { Button } from "_react/shared/legacy/ui/Button";
import { ColorSchemeGroup, defaultColorScheme, BLACKWHITE } from "_react/shared/legacy/ui/Colors";
import Add from "_react/shared/legacy/ui/icons/Add";
import Delete from "_react/shared/legacy/ui/icons/Delete";
import Filter from "_react/shared/legacy/ui/icons/Filter";
import { Menu } from "_react/shared/legacy/ui/Menu";
import { MenuItem } from "_react/shared/legacy/ui/MenuItem";
import Check from "_react/shared/legacy/ui/icons/Check";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import createNotesMachine, {
	SET_FILTERS,
	UPDATE_NOTE,
	ADD_NOTE,
	DELETE_NOTE,
	SET_SORT_DATE_COLUMN,
	TOGGLE_DELETED_NOTES_SHOWING,
	SET_NOTES
} from "_react/playerpage/notes/_notesMachine";
import { RockyNoteLoading } from "_react/playerpage/notes/_loading";
import {
	CHECK_STYLE,
	CheckPlaceholder,
	ButtonContentsContainer,
	BUTTON_ICON_STYLE,
	HEADER_BUTTON_STYLE,
	MENU_ITEM_STYLE,
	BUTTON_STYLE,
	NoNotesDiv,
	NoNotesFiltersDiv
} from "_react/playerpage/notes/_styles";
import { TRockyNote, TNoteTypeLK } from "_react/shared/data_models/notes/_types";
import {
	getNoteTypesFiltered,
	getNewNoteTypesFilters,
	includesNoteType,
	isUsingFilters,
	generateNewNotePlaceholder,
	processPrepopulation,
	getPrepopulationValues
} from "_react/playerpage/notes/_helpers";
import { RockyNote } from "_react/playerpage/notes/RockyNote";
import { SORT_DATE_COLUMNS } from "_react/playerpage/notes/_constants";
import { isPlayerNote } from "_react/shared/notes/_helpers";

type TRockyNotesStyle = {
	cardHeader?: CSSProperties;
};

type RockyNotesProps = {
	colorScheme?: ColorSchemeGroup;
	player: TPlayerPageCombinedPlayer;
	style?: TRockyNotesStyle;
};

export const RockyNotes: FC<RockyNotesProps> = ({ player, colorScheme = defaultColorScheme, style }) => {
	// Constants
	const playerId = player.id;
	const user = useUserData();
	const { userId, firstName, lastName } = user;
	const isMobile = useIsMobile();

	// Note Types
	const { responseData: noteTypesRaw, isInProgress: isFetchingNoteTypes } = useFetch(
		`/lk?lkTableName=lk_note_type&dbName=phil_data`,
		[]
	);
	const { responseData: noteTypesForFiltering, isInProgress: isFetchingFilteringNoteTypes } = useFetch(
		`/notes/types/read`,
		[]
	);
	const { responseData: noteTypesForCreating, isInProgress: isFetchingCreatingNoteTypes } = useFetch(
		`/notes/types/create`,
		[]
	);
	const [noteTypes, setNoteTypes] = useState<TNoteTypeLK[]>([]);
	const [noteTypesAll, setNoteTypesAll] = useState<string[]>([]);
	useEffect(() => {
		if (noteTypesRaw.length) {
			const filteredNoteTypesRaw = noteTypesRaw.filter(
				(noteType: { label: string; value: string; creatable: number }) => isPlayerNote(noteType.value)
			);
			setNoteTypes(
				filteredNoteTypesRaw.map((noteType: { label: string; value: string; creatable: number }) => ({
					label: noteType.label,
					key: noteType.value,
					creatable: noteType.creatable === 1
				}))
			);
			setNoteTypesAll(filteredNoteTypesRaw.map((noteType: { label: string; value: string }) => noteType.value));
		}
	}, [noteTypesRaw]);

	// Create Resources
	const [anchorElCreate, setAnchorElCreate] = useState<HTMLButtonElement | null>();

	// Filter Resources
	const [anchorElFilter, setAnchorElFilter] = useState<HTMLButtonElement | null>();
	const [cookies, setCookie] = useCookies(["noteTypesFiltered"]);
	const [noteTypesFilteredParam, setNoteTypesFilteredParam] = useQueryString("noteTypes");

	const noteTypesFilteredRaw = noteTypesFilteredParam ?? cookies.noteTypesFiltered ?? noteTypesAll.join(",");
	const noteTypesFiltered = useMemo(() => getNoteTypesFiltered(noteTypesFilteredRaw), [noteTypesFilteredRaw]);

	// Data and Machine
	const { notes: notesDataSource, isNotesLoading, updateNotes } = useContext(PlayerPageContext);
	const [current, send] = useMachine(createNotesMachine(playerId, noteTypesFiltered));
	useEffect(() => {
		if (!isNotesLoading && notesDataSource) send({ type: SET_NOTES, data: notesDataSource ?? [] });
	}, [notesDataSource, isNotesLoading, send]);
	const showingDeletedNotes = current.matches("notes.showingDeleted");
	const toggleShowingDeleted = () => send({ type: TOGGLE_DELETED_NOTES_SHOWING });
	const noteStructureDict = current.context.structure;
	const notes = showingDeletedNotes ? current.context.deletedNotes : current.context.notesDisplay;
	const fetchingNotes = isNotesLoading;
	const machineNotesDataSource = current.context.notes;
	useEffect(() => {
		if (machineNotesDataSource.length > 0) {
			updateNotes(machineNotesDataSource);
		}
	}, [machineNotesDataSource, updateNotes]);

	// Actions
	const setNoteTypesFiltered = (noteType: string) => {
		let newNoteTypesFilters: string[] = [];
		if (noteType === "all" && noteTypesFiltered.length !== noteTypesAll.length) newNoteTypesFilters = noteTypesAll;
		else if (noteType === "none") newNoteTypesFilters = [noteType];
		else if (noteType !== "all" && noteType !== "none")
			newNoteTypesFilters = getNewNoteTypesFilters(noteTypesFiltered, noteType);
		setCookie("noteTypesFiltered", newNoteTypesFilters.join(","));

		setNoteTypesFilteredParam(
			!isUsingFilters(noteTypesFiltered, noteTypesAll) ? newNoteTypesFilters.join(",") : null
		);
		send({ type: SET_FILTERS, data: newNoteTypesFilters });
	};

	useEffect(() => {
		if (noteTypesRaw.length) {
			const filteredNoteTypesRaw = noteTypesRaw.filter(
				(noteType: { label: string; value: string; creatable: number }) => isPlayerNote(noteType.value)
			);
			setNoteTypes(
				filteredNoteTypesRaw.map((noteType: { label: string; value: string; creatable: number }) => ({
					label: noteType.label,
					key: noteType.value,
					creatable: noteType.creatable === 1
				}))
			);
			const noteTypesAllArray = filteredNoteTypesRaw.map(
				(noteType: { label: string; value: string }) => noteType.value
			);
			setNoteTypesAll(noteTypesAllArray);
			if (noteTypesFilteredRaw === "") {
				setCookie("noteTypesFiltered", noteTypesAllArray.join(","));
				setNoteTypesFilteredParam(
					!isUsingFilters(noteTypesFiltered, noteTypesAllArray) ? noteTypesAllArray.join(",") : null
				);
				send({ type: SET_FILTERS, data: noteTypesAllArray });
			}
		}
	}, [noteTypesRaw, noteTypesFiltered, noteTypesFilteredRaw, send, setCookie, setNoteTypesFilteredParam]);

	const updateNote = (note: TRockyNote) => send({ type: UPDATE_NOTE, data: note });

	const createNote = (noteType: string) => {
		if (playerId) {
			let newNote = generateNewNotePlaceholder(noteType, playerId, userId, `${firstName} ${lastName}`);
			const prePopulationFields = getPrepopulationValues(user);
			newNote = processPrepopulation(newNote, noteStructureDict[newNote.noteType], prePopulationFields);
			send({ type: ADD_NOTE, data: newNote });
			if (!noteTypesFiltered.includes(newNote.noteType)) setNoteTypesFiltered(newNote.noteType);
		}
	};

	const deleteNote = (noteId: string) => send({ type: DELETE_NOTE, data: noteId });

	const toggleSortDateColumn = () =>
		send({
			type: SET_SORT_DATE_COLUMN,
			data: (SORT_DATE_COLUMNS.find(sortDateColumn => sortDateColumn.key !== current.context.sortDateColumn)
				?.key ?? SORT_DATE_COLUMNS[0].key) as keyof TRockyNote
		});
	const currentSortDateColumnLabel = useMemo(
		() => SORT_DATE_COLUMNS.find(sortDateColumn => sortDateColumn.key === current.context.sortDateColumn)?.label,
		[current.context.sortDateColumn]
	);

	return (
		<Card>
			<ColoredCardHeader
				colorScheme={showingDeletedNotes ? BLACKWHITE.primary : colorScheme.secondary}
				text={
					showingDeletedNotes ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<Delete fill={BLACKWHITE.primary.text} /> Deleted Notes
						</div>
					) : (
						"Notes"
					)
				}
				style={style?.cardHeader}
			>
				{!isMobile && (
					<Button
						colorScheme={showingDeletedNotes ? BLACKWHITE.secondary : BLACKWHITE.primary}
						onClick={() => toggleShowingDeleted()}
						title={
							<ButtonContentsContainer>
								{showingDeletedNotes ? "Back" : "Show Deleted Notes"}
							</ButtonContentsContainer>
						}
						style={HEADER_BUTTON_STYLE}
					/>
				)}
				{!isMobile && !showingDeletedNotes && (
					<Button
						colorScheme={colorScheme.primary}
						onClick={() => toggleSortDateColumn()}
						title={`Sorting By: ${currentSortDateColumnLabel}`}
						style={HEADER_BUTTON_STYLE}
					/>
				)}
				{!showingDeletedNotes && (
					<>
						<Button
							colorScheme={colorScheme.primary}
							onClick={e => setAnchorElFilter(e.currentTarget)}
							title={
								<ButtonContentsContainer>
									<Filter fill={colorScheme.primary.text} style={BUTTON_ICON_STYLE} />
									Filter
									{!fetchingNotes && isUsingFilters(noteTypesFiltered, noteTypesAll)
										? ` (Filters Set)`
										: ""}
								</ButtonContentsContainer>
							}
							disabled={isFetchingNoteTypes || isFetchingFilteringNoteTypes}
							style={HEADER_BUTTON_STYLE}
						/>
						<Menu
							anchorEl={anchorElFilter}
							id="filterNotes"
							onClose={() => setAnchorElFilter(null)}
							open={Boolean(anchorElFilter)}
						>
							<MenuItem
								key={"all"}
								handleClick={() => {
									setNoteTypesFiltered(
										isUsingFilters(noteTypesFiltered, noteTypesAll) ? "all" : "none"
									);
									setAnchorElFilter(null);
								}}
								style={{ ...MENU_ITEM_STYLE, justifyContent: "center" }}
							>
								{isUsingFilters(noteTypesFiltered, noteTypesAll) ? "Select" : "Deselect"} All
							</MenuItem>
							{noteTypes
								.filter(noteType => noteTypesForFiltering.includes(noteType.key))
								.map(noteType => (
									<MenuItem
										key={noteType.key}
										handleClick={() => {
											setNoteTypesFiltered(noteType.key);
											setAnchorElFilter(null);
										}}
										style={MENU_ITEM_STYLE}
									>
										{includesNoteType(noteTypesFiltered, noteType.key) ? (
											<Check style={CHECK_STYLE} />
										) : (
											<CheckPlaceholder />
										)}
										{noteType.label}s
									</MenuItem>
								))}
						</Menu>
						<Button
							colorScheme={colorScheme.primary}
							onClick={e => setAnchorElCreate(e.currentTarget)}
							title={
								<ButtonContentsContainer>
									<Add fill={colorScheme.primary.text} style={BUTTON_ICON_STYLE} />
									New Note
								</ButtonContentsContainer>
							}
							disabled={
								Object.keys(noteStructureDict).length === 0 ||
								isFetchingNoteTypes ||
								isFetchingCreatingNoteTypes
							}
							style={BUTTON_STYLE}
						/>
						<Menu
							anchorEl={anchorElCreate}
							id="newNote"
							onClose={() => setAnchorElCreate(null)}
							open={Boolean(anchorElCreate)}
						>
							{noteTypes
								.filter(noteType => noteType.creatable && noteTypesForCreating.includes(noteType.key))
								.map(noteType => (
									<MenuItem
										key={noteType.key}
										handleClick={() => {
											createNote(noteType.key);
											setAnchorElCreate(null);
										}}
										style={MENU_ITEM_STYLE}
									>
										{noteType.label}
									</MenuItem>
								))}
						</Menu>
					</>
				)}
			</ColoredCardHeader>
			<div>
				{(Object.keys(noteStructureDict).length === 0 || fetchingNotes) && (
					<>
						{[0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => (
							<RockyNoteLoading key={i} />
						))}
					</>
				)}
				{Object.keys(noteStructureDict).length > 0 &&
					notes.map((note: TRockyNote) => (
						<RockyNote
							key={note.noteId}
							note={note}
							structure={noteStructureDict[note.noteType] ?? []}
							colorScheme={colorScheme}
							updateNote={updateNote}
							deleteNote={deleteNote}
							showingEditButtons={
								note.canEdit === true &&
								(noteTypes.find(noteType => noteType.key === note.noteType)?.creatable ?? false)
							}
							showingDeletedNotes={showingDeletedNotes}
						/>
					))}
				{Object.keys(noteStructureDict).length > 0 && !fetchingNotes && notes.length === 0 && (
					<NoNotesDiv>
						<div>No Notes</div>
						{isUsingFilters(noteTypesFiltered, noteTypesAll) && (
							<NoNotesFiltersDiv>(Check the filters)</NoNotesFiltersDiv>
						)}
					</NoNotesDiv>
				)}
			</div>
		</Card>
	);
};
