import React, { useContext, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "@xstate/react";
import { Box, HStack } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { getPositionGroupFromPosition } from "_react/shared/_helpers/position_groups";
import { BATS_OVERALL, THROWS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import { POSITION_GROUP_POSITION_PLAYER } from "_react/shared/_constants/position_groups";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import {
	ToggleTopBreakpointValue,
	TogglePositionBreakpointValue,
	ToggleJustifyContentBreakpointValue,
	ToggleGapBreakpointValue,
	ContentPaddingTopBreakpointValue,
	TOGGLE_STYLE
} from "_react/playerpage/_styles";
import { PlayerPageProContext } from "_react/playerpage/pro/PlayerPageProProvider";
import { TPlayerPageProState } from "_react/playerpage/pro/_machine";
import { BatterHandednessToggleOptions, PitcherHandednessToggleOptions } from "_react/playerpage/_constants";
import FieldingSection from "_react/playerpage/pro/content/observed/FieldingSection";
import ArsenalTrendsSection from "_react/playerpage/shared/content/observed/ArsenalTrendsSection";
import HittingSection from "_react/playerpage/shared/content/observed/HittingSection";
import StuffSection from "_react/playerpage/shared/content/observed/StuffSection";
import { PLAYING_LEVEL_PRO } from "_react/shared/data_models/seasonal_grades/_constants";
import LocationsSection from "_react/playerpage/shared/content/observed/LocationsSection";

type TObservedTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const ObservedTab = ({ player }: TObservedTabProps) => {
	// Toggles for handedness
	const [batsFilter, setBatsFilter] = useState<string>(BATS_OVERALL);
	const [throwsFilter, setThrowsFilter] = useState<string>(THROWS_OVERALL);
	// Season toggle -> default to previous year before 4/15 and then default to current year
	const today = dayjs();
	const currentYear = today.year();
	const defaultSeason = today.isBefore(`${currentYear}-04-15`, "date") ? currentYear - 1 : currentYear;
	const [seasonFilter, setSeasonFilter] = useState<number>(defaultSeason);

	const playerPageProContext = useContext(PlayerPageProContext);

	const isFetchingCurrentSeason: boolean | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.currentSeasonData?.isFetching
	);

	const currentSeason: number | undefined = useSelector(
		playerPageProContext.playerPageProService,
		(state: TPlayerPageProState) => state.context.currentSeasonData?.currentSeason
	);

	// Season options should be the current season, current season - 1 and current season - 2
	const seasonOptions = useMemo(() => {
		if (isFetchingCurrentSeason) {
			return [{ label: "Current Season", value: 0 }];
		}
		if (currentSeason) {
			const offsets = [0, 1, 2];
			return offsets.map(offset => {
				const season = currentSeason - offset;
				return { label: `${season}`, value: season };
			});
		}
		return [];
	}, [isFetchingCurrentSeason, currentSeason]);

	const positionGroup = getPositionGroupFromPosition(
		player?.proProfile?.projectedPosition ?? player?.position,
		false
	);

	const toggleTop = useBreakpointValue(ToggleTopBreakpointValue);
	const togglePosition = useBreakpointValue(TogglePositionBreakpointValue);
	const toggleJustifyContent = useBreakpointValue(ToggleJustifyContentBreakpointValue);
	const toggleGap = useBreakpointValue(ToggleGapBreakpointValue);
	const contentPaddingTop = useBreakpointValue(ContentPaddingTopBreakpointValue);

	return (
		<Box position="relative" top="-8">
			<HStack
				sx={{
					position: togglePosition,
					top: toggleTop,
					// Necessary otherwise the bars on the highcharts bar chart are on top of the toggle buttons
					zIndex: 1,
					gap: toggleGap,
					justifyContent: toggleJustifyContent
				}}
			>
				<ToggleButton<number>
					toggleOptions={seasonOptions}
					value={seasonFilter}
					onSelect={(value: number) => setSeasonFilter(value)}
					isLoading={isFetchingCurrentSeason}
					style={TOGGLE_STYLE}
				/>
				{positionGroup === POSITION_GROUP_POSITION_PLAYER && (
					<ToggleButton<string>
						toggleOptions={PitcherHandednessToggleOptions}
						value={throwsFilter}
						onSelect={(value: string) => setThrowsFilter(value)}
						style={TOGGLE_STYLE}
					/>
				)}
				{positionGroup !== POSITION_GROUP_POSITION_PLAYER && (
					<ToggleButton<string>
						toggleOptions={BatterHandednessToggleOptions}
						value={batsFilter}
						onSelect={(value: string) => setBatsFilter(value)}
						style={TOGGLE_STYLE}
					/>
				)}
			</HStack>

			<Box paddingTop={contentPaddingTop}>
				{isFetchingCurrentSeason && !seasonFilter && <Box className="loading-item" height="md" width="100%" />}

				{positionGroup === POSITION_GROUP_POSITION_PLAYER && seasonFilter && (
					<>
						<HittingSection
							player={player}
							playingLevel={PLAYING_LEVEL_PRO}
							seasonFilter={seasonFilter}
							throwsFilter={throwsFilter}
						/>
						<FieldingSection player={player} />
					</>
				)}
				{positionGroup !== POSITION_GROUP_POSITION_PLAYER && seasonFilter && (
					<>
						<StuffSection
							player={player}
							playingLevel={PLAYING_LEVEL_PRO}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
						/>
						<LocationsSection
							player={player}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							useCountSplits={true}
							playingLevel={PLAYING_LEVEL_PRO}
						/>
						<ArsenalTrendsSection
							player={player}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							playingLevel={PLAYING_LEVEL_PRO}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};

export default ObservedTab;
