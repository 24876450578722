import React, { useState, useEffect } from "react";
import {
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Flex,
	Box,
	Stack,
	Menu,
	MenuButton,
	MenuList,
	MenuItem
} from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { TTab, TTabsWithSidebarStyle } from "_react/shared/ui/presentation/layouts/TabsWithSidebar/_types";
import TabContainer from "_react/shared/ui/presentation/layouts/TabsWithSidebar/TabContainer";
import TabsWithSidebarLoading from "_react/shared/ui/presentation/layouts/TabsWithSidebar/TabsWithSidebarLoading";

export type TabsWithSidebarProps = {
	tabsHeaderChildren: React.ReactNode;
	tabs: Array<TTab>;
	sidebarChildren: React.ReactNode;
	tabIndex?: number;
	handleSetTabIndex?: (index: number) => void;
	numberVisibleTabs?: number;
	isLoading?: boolean;
	style?: TTabsWithSidebarStyle;
};

const TabsWithSidebar = ({
	tabsHeaderChildren,
	tabs,
	sidebarChildren,
	tabIndex: tabIndexProp,
	handleSetTabIndex,
	numberVisibleTabs,
	isLoading = false,
	style
}: TabsWithSidebarProps) => {
	const [tabIndex, setTabIndex] = useState(tabIndexProp ?? 0);

	useEffect(() => {
		if (tabIndexProp !== undefined) setTabIndex(tabIndexProp);
	}, [tabIndexProp]);

	const handleTabsChange = (index: number) => {
		setTabIndex(index);
		if (handleSetTabIndex) handleSetTabIndex(index);
	};

	const numberVisibleTabsBreakpointValue =
		numberVisibleTabs ??
		useBreakpointValue({
			base: 1,
			md: 2,
			lg: 4,
			xl: 6,
			"2xl": 9
		});

	// All of the tabs, sometimes show a `More..` option based on the number of tabs and breakpoint
	const tabContent =
		numberVisibleTabsBreakpointValue != null ? (
			<>
				{tabs.slice(0, numberVisibleTabsBreakpointValue).map((tab: TTab) => (
					<Tab key={tab.label} whiteSpace="nowrap" fontSize="lg">
						{tab.label}
					</Tab>
				))}
				{tabs.length > numberVisibleTabsBreakpointValue && (
					<Menu>
						<MenuButton
							fontWeight="semibold"
							paddingTop="2"
							paddingBottom="2"
							paddingLeft="1px"
							paddingInlineEnd="4"
							fontSize="lg"
							sx={
								tabIndex >= numberVisibleTabsBreakpointValue
									? {
											color: "maroon",
											marginBottom: "-2px",
											borderBottom: "2px solid",
											borderColor: "currentColor"
									  }
									: {}
							}
						>
							More...
						</MenuButton>
						<MenuList zIndex={2}>
							{tabs.slice(numberVisibleTabsBreakpointValue).map((tab: TTab, index: number) => (
								<MenuItem
									key={tab.label}
									onClick={() => handleTabsChange(index + numberVisibleTabsBreakpointValue)}
									color={index === tabIndex - numberVisibleTabsBreakpointValue ? "maroon" : undefined}
									fontWeight="semibold"
								>
									{tab.label}
								</MenuItem>
							))}
						</MenuList>
					</Menu>
				)}
			</>
		) : (
			undefined
		);

	return (
		<>
			{isLoading && <TabsWithSidebarLoading />}
			{!isLoading && (
				<Tabs
					display="flex"
					flexDirection="column"
					h={style?.tabs?.height ? undefined : { md: "100%" }}
					sx={style?.tabs}
					index={tabIndex}
					onChange={handleTabsChange}
				>
					<Box w="100%" bg="gray.50" display={{ base: "block", md: "none" }}>
						{sidebarChildren}
					</Box>
					{tabsHeaderChildren && (
						<Flex justify="center" paddingTop="4" display={{ base: "flex", md: "none" }}>
							{tabsHeaderChildren}
						</Flex>
					)}
					<Flex
						justify="center"
						align="center"
						bg="white"
						borderBottom="2px solid"
						borderColor="gray.200"
						paddingTop={{ base: "4", md: "0" }}
						gap={{ md: "2" }}
					>
						<TabList
							position="relative"
							bottom="-2px"
							alignItems="end"
							width={{ md: "100%" }}
							paddingLeft={{ md: "12" }}
							paddingRight={{ md: "6" }}
							paddingTop={{ md: "6" }}
						>
							{tabContent}
						</TabList>
						{tabsHeaderChildren && (
							<Flex
								justify="end"
								height="100%"
								width="lg"
								backgroundColor="gray.50"
								display={{ base: "none", md: "flex" }}
							>
								{tabsHeaderChildren}
							</Flex>
						)}
					</Flex>
					<Stack
						align="start"
						h="100%"
						direction={{ base: "column", md: "row" }}
						overflowY={{ md: "hidden" }}
					>
						<TabPanels
							paddingRight="12"
							paddingLeft="12"
							paddingTop={{ base: "12", md: "0" }}
							h={{ md: "100%" }}
							overflowY={{ md: "scroll" }}
						>
							{tabs.map((tab: TTab) => (
								<TabPanel key={tab.label}>
									<TabContainer>{tab.content}</TabContainer>
								</TabPanel>
							))}
						</TabPanels>
						<Box
							w="lg"
							h="100%"
							overflowY="scroll"
							bg="gray.50"
							marginInlineStart="0 !important"
							display={{ base: "none", md: "block" }}
						>
							{sidebarChildren}
						</Box>
					</Stack>
				</Tabs>
			)}
		</>
	);
};

export default TabsWithSidebar;
