import { Machine, assign, Interpreter, DoneInvokeEvent } from "xstate";

import { $TSFixMePlayer } from "utils/tsutils";

import { TEval, TPlayersResponse, TPlayerEvals } from "_react/evals/list/_types";
import { fetchEvalData, fetchPlayerData } from "_react/evals/list/_network";

export type TPlayersDict = {
	pro: Record<string, $TSFixMePlayer> | null;
	ama: Record<string, $TSFixMePlayer> | null;
	intl: Record<string, $TSFixMePlayer> | null;
	[key: string]: Record<string, $TSFixMePlayer> | null;
};

export type TEvalListContext = {
	evalIds: Array<string> | null;
	evals: Array<TEval> | null;
	players: TPlayersDict | null;
	playersEvals: Array<TPlayerEvals> | null;
	isShowSummaries: boolean;
};

interface IEvalListStateSchema {
	states: {
		initializing: {};
		initialized: {};
	};
}

export const FETCHING_STATE = { initialized: { evalData: "fetching" } };
export const FETCHING_PLAYERS_STATE = { initialized: { playerData: "fetching" } };

export const TOGGLE_SHOW_SUMMARIES = "TOGGLE_SHOW_SUMMARIES";
export const SET_EVAL_IDS = "SET_EVAL_IDS";

type TToggleShowSummariesEvent = { type: typeof TOGGLE_SHOW_SUMMARIES };
type TSetEvalIdsEvent = { type: typeof SET_EVAL_IDS; value: Array<string> | null };

type TEvalListEvent = TToggleShowSummariesEvent | TSetEvalIdsEvent;

export type TEvalListSend = Interpreter<TEvalListContext, IEvalListStateSchema, TEvalListEvent>["send"];

const doneFetchEvalDataAction = assign<TEvalListContext, DoneInvokeEvent<Array<TEval>>>({
	evals: (_context, event) => event.data
});

const doneFetchPlayerDataAction = assign<TEvalListContext, DoneInvokeEvent<TPlayersResponse>>({
	players: (_context, event) => {
		const playersDict = { pro: null, ama: null, intl: null } as TPlayersDict;
		Object.entries(event.data).forEach(([key, value]) => {
			const players: { [k: string]: $TSFixMePlayer } = {};
			value?.forEach((p: $TSFixMePlayer) => {
				const phil_id = p.phil_id?.toString() as string;
				if (phil_id) players[phil_id] = p;
			});
			playersDict[key] = players;
		});
		return playersDict;
	},
	playersEvals: (context: TEvalListContext, event: DoneInvokeEvent<TPlayersResponse>) => {
		const { evals } = context;
		if (!evals) return [];
		let playersEvals = [] as Array<TPlayerEvals>;
		Object.entries(event.data).forEach(([key, value]) => {
			value?.forEach((p: $TSFixMePlayer) => {
				const playerEvals = evals.filter(
					(e: TEval) => e.phil_id === p.phil_id && e.eval_type?.includes(key.toUpperCase())
				);
				playersEvals = [
					...playersEvals,
					{
						evals: playerEvals,
						player: p,
						playerClassification: key
					}
				];
			});
		});
		return playersEvals;
	}
});

export type TEvalListMachineProps = {
	evalIdsProp: Array<string>;
};

const EvalListMachine = ({ evalIdsProp }: TEvalListMachineProps) =>
	Machine<TEvalListContext, IEvalListStateSchema, TEvalListEvent>(
		{
			id: "eval_list",
			initial: "initializing",
			context: {
				evalIds: evalIdsProp,
				evals: null,
				players: null,
				playersEvals: null,
				isShowSummaries: true
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[TOGGLE_SHOW_SUMMARIES]: { actions: ["toggleShowSummaries"] },
						[SET_EVAL_IDS]: { actions: ["setEvalIds"] }
					},
					states: {
						evalData: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									always: { target: "fetching", cond: "shouldFetchEvalData" },
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									invoke: {
										src: "fetchEvalData",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneFetchEvalDataAction
										},
										onError: "#erroredNode"
									}
								}
							}
						},
						playerData: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									always: { target: "fetching", cond: "shouldFetchPlayerData" },
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									invoke: {
										src: "fetchPlayerData",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneFetchPlayerDataAction
										},
										onError: "#erroredNode"
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldFetchEvalData: (context, _event) => {
					const { evals, evalIds } = context;
					if (evals === null && evalIds?.length !== 0) return true;
					return false;
				},
				shouldFetchPlayerData: (context, _event) => {
					const { evals, players } = context;
					if (evals !== null && evals?.length > 0 && players === null) return true;
					return false;
				}
			},
			actions: {
				toggleShowSummaries: assign({
					isShowSummaries: (context, _event) => {
						return !context.isShowSummaries;
					}
				}),
				setEvalIds: assign({
					evalIds: (context, event) => {
						if (event.type === SET_EVAL_IDS) return event?.value;
						return context.evalIds;
					},
					evals: (context, event) => {
						if (event.type === SET_EVAL_IDS) return null;
						return context.evals;
					}
				})
			},
			services: {
				fetchEvalData: (context, _event) => {
					if (context.evalIds) return fetchEvalData(context.evalIds);
					return Promise.resolve([]);
				},
				fetchPlayerData: (context, _event) => {
					const { evals } = context;
					if (evals !== null) {
						const philIds = evals.map((e: TEval) => e.phil_id);
						if (philIds) return fetchPlayerData(philIds);
						return Promise.resolve(null);
					}
					return Promise.resolve(null);
				}
			}
		}
	);

export default EvalListMachine;
