import React, { useMemo } from "react";
import {
	useBoolean,
	Button,
	Box,
	Flex,
	Text,
	Image,
	IconButton,
	Tooltip,
	Menu,
	MenuButton,
	MenuList,
	MenuItem
} from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import {
	EVENT_TYPE_SELECT_CONTENT,
	CONTENT_TYPE_PLAYER_PAGES_TAB,
	CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION
} from "_react/shared/_constants/google_analytics";
import { sendReactGAEvent } from "_react/shared/_helpers/google_analytics";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { PlayerProSummary } from "_react/shared/legacy/globalcontext/player/_types";
import EntityPage from "_react/shared/ui/presentation/layouts/EntityPage/EntityPage";
import { TTab } from "_react/shared/ui/presentation/layouts/TabsWithSidebar/_types";
import { useIsGroupMember } from "_react/app/AppContents";
import { getAgeFromBirthDate } from "utils/helpers";
import { formatHeight } from "utils/functions";
import { logoURL } from "_react/shared/_helpers/team_logo";
import { getTeamName } from "_react/shared/_helpers/team";
import MergeType from "_react/shared/ui/icons/MergeType";
import ExpandMore from "_react/shared/ui/icons/ExpandMore";
import PlayerPosition from "_react/shared/ui/presentation/components/PlayerPosition/PlayerPosition";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import {
	VIEW_PROJECTIONS,
	VIEW_OBSERVED,
	VIEW_SCOUTING_REPORTS,
	VIEW_GAME_REPORTS,
	VIEW_STATISTICS,
	VIEW_CONTRACT,
	VIEW_INJURIES,
	VIEW_MANUEL,
	VIEW_NOTES,
	VIEW_TRANSACTIONS
} from "_react/shared/_constants/query_strings";

import {
	PRO,
	AMA,
	INTL,
	ZERO,
	ONE,
	NULL_FILLER_TEXT_HEADER,
	NULL_FILLER_TEXT_TEAM_NAME
} from "_react/playerpage/_constants";
import { getPlayerFullName, getPlayerNameAndUniformNumber } from "_react/shared/data_models/player/_helpers";
import { TPlayerPageCombinedPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import { MERGE_PLAYER_ADMIN_IAM_GROUP } from "_react/playerpage/_helpers";
import { getFreeAgentOrTeamName } from "_react/playerpage/pro/shared/_helpers";
import PlayerHeadshot from "_react/shared/ui/presentation/components/PlayerHeadshot/PlayerHeadshot";
import MergePlayersModal from "_react/playerpage/shared/header/MergePlayersModal";
import CreateReportMenu from "_react/playerpage/shared/header/CreateReportMenu";
import PlayerPageProProfile from "_react/playerpage/pro/profile/PlayerPageProProfile";
import GameReportsTab from "_react/playerpage/pro/content/game_reports/GameReportsTab";
import ProjectionsTab from "_react/playerpage/pro/content/projections/ProjectionsTab";
import ObservedTab from "_react/playerpage/pro/content/observed/ObservedTab";
import ContractTab from "_react/playerpage/pro/content/contract/ContractTab";
import TransactionsTab from "_react/playerpage/pro/content/transactions/TransactionsTab";
import StatisticsTab from "_react/playerpage/pro/content/statistics/StatisticsTab";
import ScoutingReportsTab from "_react/playerpage/shared/content/scouting_reports/ScoutingReportsTab";
import NotesTab from "_react/playerpage/shared/content/notes/NotesTab";
import InjuriesTab from "_react/playerpage/pro/content/injuries/InjuriesTab";
import PlayerPlanTab from "_react/playerpage/pro/content/playerplan/PlayerPlanTab";

type PlayerPageProContentProps = {
	player?: TPlayerPageCombinedPlayer | null;
	playerLegacy?: PlayerProSummary;
	evals: $TSFixMeEval[] | null | undefined;
	noteCount: number;
	isNotesLoading?: boolean;
	isNotesFailed?: boolean;
	setViewClassification?: (viewClassification?: TPlayerPagePlayerClassification) => void;
	viewClassification: string | null;
	setView?: (key: string, isReplaceState?: boolean) => void;
	view?: string;
	setIsBeta?: (isBeta: string | null, isReplaceState?: boolean) => void;
	hasProjectionsPermissions?: boolean;
	hasPlayerPlanPermissions?: boolean;
};

const PlayerPageProContent = ({
	player,
	playerLegacy,
	evals,
	noteCount,
	isNotesLoading,
	isNotesFailed,
	setViewClassification,
	viewClassification,
	setView,
	view,
	setIsBeta,
	hasProjectionsPermissions = false,
	hasPlayerPlanPermissions = false
}: PlayerPageProContentProps) => {
	const [isMergeModalOpen, setIsMergeModalOpen] = useBoolean(false);
	const canMergePlayers = useIsGroupMember(MERGE_PLAYER_ADMIN_IAM_GROUP);

	//
	// Header Content
	//

	// Player's Content
	const playerFullName = getPlayerFullName(player, false);
	const playerHeadshot = <PlayerHeadshot bamId={player?.bamId} alt={playerFullName} style={{ zIndex: "1" }} />;
	const playerNameText = getPlayerNameAndUniformNumber(player);
	const playerAttributesText = (
		<>
			<Text>{player?.birthDate ? `${getAgeFromBirthDate(player.birthDate)} yrs` : NULL_FILLER_TEXT_HEADER}</Text>
			<Text>|</Text>
			<Text>{player?.height ? formatHeight(player.height) : NULL_FILLER_TEXT_HEADER}</Text>
			<Text>|</Text>
			<Text>{player?.weight ? `${player.weight}lb` : NULL_FILLER_TEXT_HEADER}</Text>
		</>
	);

	// Player's Team Content

	const teamName = (
		<>
			<Box>
				{player?.team?.parentTeam?.bamId ? (
					<Image
						width="5"
						filter="drop-shadow(0px 0px 2px white)"
						src={logoURL(player.team.parentTeam.bamId)}
						alt={getTeamName(player.team)}
					/>
				) : (
					""
				)}
			</Box>
			<Text fontWeight="bold" fontSize="lg" textAlign="center">
				{getFreeAgentOrTeamName(player, NULL_FILLER_TEXT_TEAM_NAME)}
			</Text>
		</>
	);
	const teamDetails = (
		<>
			{getTeamName(player?.team) !== "" && player?.team?.level != null && (
				<TeamLevelBadge level={player?.team?.level} nullFillerText={NULL_FILLER_TEXT_HEADER} />
			)}
			<Text>|</Text>
			<PlayerPosition player={player} nullFillerText={NULL_FILLER_TEXT_HEADER} isTooltipDisabled={false} />
			<Text>|</Text>
			<Text>
				{player?.bats ?? NULL_FILLER_TEXT_HEADER}/{player?.throws ?? NULL_FILLER_TEXT_HEADER}
			</Text>
		</>
	);

	// Header actions

	const headerActions = (
		<>
			{canMergePlayers && (
				<Tooltip hasArrow placement="top" label="Merge Players">
					<IconButton
						colorScheme="gray"
						aria-label="Merge Players"
						borderRadius="full"
						marginRight="3"
						icon={<MergeType boxSize={5} />}
						onClick={() => setIsMergeModalOpen.on()}
						size="sm"
					/>
				</Tooltip>
			)}
			{player && <CreateReportMenu player={player} />}
		</>
	);

	//
	// Tab and Sidebar Content
	//

	const proTabs: Array<TTab> = useMemo(() => {
		// Tabs for which all users have permissions
		const newProTabs = [
			{
				label: "Observed",
				content: player ? <ObservedTab player={player} /> : <></>,
				queryStringValue: VIEW_OBSERVED
			},
			{
				label: "Statistics",
				content: playerLegacy ? <StatisticsTab player={playerLegacy} /> : <></>,
				queryStringValue: VIEW_STATISTICS
			},
			{
				label: "Scouting Reports",
				content: <ScoutingReportsTab evals={evals} />,
				queryStringValue: VIEW_SCOUTING_REPORTS
			},
			{
				label: "Game Reports",
				content: player?.id ? <GameReportsTab playerId={player.id} /> : <></>,
				queryStringValue: VIEW_GAME_REPORTS
			},
			{
				label: "Contract",
				content: player ? <ContractTab player={player} /> : <></>,
				queryStringValue: VIEW_CONTRACT
			},
			{
				label: "Transactions",
				content: player ? <TransactionsTab player={player} /> : <></>,
				queryStringValue: VIEW_TRANSACTIONS
			},
			{
				label: "Injuries",
				content: player ? <InjuriesTab player={player} viewClassification={viewClassification} /> : <></>,
				queryStringValue: VIEW_INJURIES
			},
			{
				label: "Notes" + (isNotesLoading ? " (...)" : isNotesFailed ? "" : ` (${noteCount})`),
				content: player ? <NotesTab player={player} /> : <></>,
				queryStringValue: VIEW_NOTES
			}
		];

		// Add Projections tab if user has permissions
		if (hasProjectionsPermissions) {
			newProTabs.splice(0, 0, {
				label: "Projections",
				content: player ? <ProjectionsTab player={player} /> : <></>,
				queryStringValue: VIEW_PROJECTIONS
			});
		}

		// Add Manuel tab if user has permissions
		if (hasPlayerPlanPermissions) {
			const spliceIndex = hasProjectionsPermissions ? 4 : 3;
			newProTabs.splice(spliceIndex, 0, {
				label: "Manuel",
				content: player ? <PlayerPlanTab playerId={player.id} /> : <></>,
				queryStringValue: VIEW_MANUEL
			});
		}

		return newProTabs;
	}, [
		player,
		playerLegacy,
		evals,
		noteCount,
		isNotesLoading,
		isNotesFailed,
		hasProjectionsPermissions,
		hasPlayerPlanPermissions,
		viewClassification
	]);

	const proTabsHeaderChildren = useMemo(
		() => (
			<Flex
				sx={{
					justifyContent: "center",
					flexDirection: "column",
					paddingRight: {
						base: undefined,
						md: "12"
					},
					paddingBottom: "1",
					paddingTop: "6"
				}}
			>
				<Menu>
					<MenuButton
						as={Button}
						rightIcon={<ExpandMore />}
						h="8"
						bg="transparent"
						color="gray.700"
						borderColor="gray.700"
						border="1px solid"
						fontSize="sm"
						fontWeight="semibold"
						paddingInlineStart="3"
						paddingInlineEnd="3"
					>
						PRO (NEW)
					</MenuButton>
					<MenuList>
						<MenuItem
							onClick={() => {
								setIsBeta ? setIsBeta(ZERO) : null;
								sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
									contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
									contentId: PRO
								});
							}}
						>
							PRO (OLD)
						</MenuItem>
						{player?.playerAmaId && (
							<MenuItem
								onClick={() => {
									setViewClassification ? setViewClassification(AMA) : null;
									setIsBeta ? setIsBeta(ONE) : null;
									sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
										contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
										contentId: `${AMA}-new`
									});
								}}
							>
								AMA (NEW)
							</MenuItem>
						)}
						{player?.playerAmaId && (
							<MenuItem
								onClick={() => {
									setViewClassification ? setViewClassification(AMA) : null;
									setIsBeta ? setIsBeta(ZERO) : null;
									sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
										contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
										contentId: AMA
									});
								}}
							>
								{`AMA (OLD)`}
							</MenuItem>
						)}
						{player?.playerIntlId && (
							<MenuItem
								onClick={() => {
									setViewClassification ? setViewClassification(INTL) : null;
									sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
										contentType: CONTENT_TYPE_PLAYER_PAGES_PLAYER_CLASSIFICATION,
										contentId: INTL
									});
								}}
							>
								INTL
							</MenuItem>
						)}
					</MenuList>
				</Menu>
			</Flex>
		),
		[player?.playerAmaId, player?.playerIntlId, setIsBeta, setViewClassification]
	);

	const activeTabIndex = useMemo(() => {
		const activeIndex = proTabs.findIndex((tab: TTab) => view && tab.queryStringValue === view);
		if (activeIndex === -1) return undefined;
		return activeIndex;
	}, [proTabs, view]);

	const handleSetTabIndex = (index: number) => {
		if (setView) setView(proTabs[index].queryStringValue ?? "");
		sendReactGAEvent(EVENT_TYPE_SELECT_CONTENT, {
			contentType: CONTENT_TYPE_PLAYER_PAGES_TAB,
			contentId: proTabs[index].queryStringValue
		});
	};

	//
	// Custom styles for layout
	//

	// If you change the height here, make sure to update `tabsWithSidebarStyle` below
	const entityHeaderStyle = useBreakpointValue({
		md: { container: { height: "24" } },
		lg: {}
	});

	// Manually account for header height for vertical scrolling and footer
	// Not an optimal way to do this...
	const tabsWithSidebarStyle = useBreakpointValue({
		base: { tabs: { height: "calc(100% - 91px)" } },
		lg: { tabs: { height: "calc(100% - 64px)" } }
	});

	return (
		<>
			<EntityPage
				entityHeaderProps={{
					image: playerHeadshot,
					startContent: {
						primaryContent: playerNameText,
						secondaryContent: playerAttributesText
					},
					middleContent: {
						primaryContent: teamName,
						secondaryContent: teamDetails
					},
					endContent: headerActions,
					style: entityHeaderStyle
				}}
				tabsWithSidebarProps={{
					tabsHeaderChildren: proTabsHeaderChildren,
					tabs: proTabs,
					tabIndex: activeTabIndex,
					handleSetTabIndex: handleSetTabIndex,
					sidebarChildren: player ? (
						<PlayerPageProProfile player={player} hasProjectionsPermissions={hasProjectionsPermissions} />
					) : (
						undefined
					),
					style: tabsWithSidebarStyle
				}}
				isLoading={player == null}
			/>
			{player && <MergePlayersModal player={player} isOpen={isMergeModalOpen} setIsOpen={setIsMergeModalOpen} />}
		</>
	);
};

export default PlayerPageProContent;
