import { POSITION_GROUPS_POSITION_PLAYER, POSITION_GROUPS_PITCHER } from "_react/shared/_constants/position_groups";

// phred.ama_prospect_value values
export const MODEL_NAME_V2 = "ama_v2";

export const PLAYER_TYPE_BATTER = "batter";
export const PLAYER_TYPE_PITCHER = "pitcher";

export const PLAYER_TYPE_TO_POSITION_GROUP = {
	[PLAYER_TYPE_BATTER]: POSITION_GROUPS_POSITION_PLAYER,
	[PLAYER_TYPE_PITCHER]: POSITION_GROUPS_PITCHER
};
