import React, { useEffect, useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { Box, HStack, VStack, CircularProgress, Tooltip } from "@chakra-ui/react";
import dayjs from "dayjs";

import { THRESHOLD_GRADE_LOCATION, PLAYING_LEVEL_AMA } from "_react/shared/data_models/arsenal_scores/_constants";
import {
	getThresholdLevel,
	getPlayerSeasonArsenalScoresByThrowsFilterStatement
} from "_react/shared/data_models/arsenal_scores/_helpers";
import { formatRa9, format2080Grade } from "_react/shared/data_models/seasonal_grades/_helpers";
import {
	BATS_OVERALL,
	COUNT_SPLIT_PRE_2K,
	COUNT_SPLIT_2K,
	COUNT_SPLIT_OVERALL
} from "_react/shared/data_models/seasonal_grades/_constants";
import { getLocationThresholdTooltip } from "_react/shared/_helpers/tooltips";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { PITCH_TYPE_LABEL_MAP } from "_react/shared/_constants/pitch_types";
import { TPlayerClassification } from "utils/tsutils";

import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import PlotStatLabel from "_react/shared/ui/presentation/components/PlotStatLabel/PlotStatLabel";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_DANGEROUS } from "_react/shared/ui/presentation/components/GradeThresholdIcon/_constants";

import createArsenalLocationTilesMachine, {
	TArsenalLocationTilesContext,
	SET_PLAYER_SEASON_ARSENAL_SCORES,
	SET_ARSENAL_SCORES_THRESHOLD,
	SET_PLAYER_ID,
	SET_PLAYING_LEVEL,
	SET_SEASON_FILTER,
	SET_ARSENAL_LOCATION_TILES_PLAYER,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES,
	FETCHING_ARSENAL_SCORES_THRESHOLD,
	FETCHING_ARSENAL_LOCATION_TILES_PLAYER
} from "_react/shared/ui/data/other/ArsenalLocationTiles/_machine";
import { PLOT_STAT_LABEL } from "_react/shared/ui/data/other/ArsenalLocationTiles/_constants";
import {
	PitchTypeLabelHStack,
	LocationTileBox,
	CountLabelBox
} from "_react/shared/ui/data/other/ArsenalLocationTiles/_styles";
import { TArsenalLocationTilesPlayer } from "_react/shared/ui/data/other/ArsenalLocationTiles/_types";

export type TArsenalLocationTilesPrimaryData = {
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema> | null;
	isLoading?: boolean;
};

export type TArsenalLocationTilesSecondaryData = {
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	arsenalLocationTilesPlayer?: TArsenalLocationTilesPlayer;
	isLoading?: boolean;
};

type TArsenalLocationTilesStyle = {
	container?: React.CSSProperties;
};

type TArsenalLocationTilesProps = {
	playerId?: number;
	playingLevel?: TPlayerClassification;
	useCountSplits?: boolean;
	batsFilter?: string;
	throwsFilter?: string;
	seasonFilter?: number;
	primaryData?: TArsenalLocationTilesPrimaryData;
	shouldFetchPrimaryData?: boolean;
	secondaryData?: TArsenalLocationTilesSecondaryData;
	shouldFetchSecondaryData?: boolean;
	style?: TArsenalLocationTilesStyle;
};

const ArsenalLocationTiles = ({
	playerId,
	playingLevel,
	useCountSplits = true,
	primaryData,
	shouldFetchPrimaryData = true,
	secondaryData,
	shouldFetchSecondaryData = true,
	batsFilter = BATS_OVERALL,
	throwsFilter,
	seasonFilter = dayjs().year(),
	style
}: TArsenalLocationTilesProps) => {
	const toast = useToast();
	const [current, send] = useMachine(
		createArsenalLocationTilesMachine(
			seasonFilter,
			playerId,
			playingLevel,
			shouldFetchPrimaryData,
			primaryData,
			shouldFetchSecondaryData,
			secondaryData,
			toast
		)
	);
	const {
		playerSeasonArsenalScores,
		arsenalScoresThreshold,
		arsenalLocationTilesPlayer
	} = current.context as TArsenalLocationTilesContext;

	const fetchingPlayerSeasonArsenalScores = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES);
	const fetchingArsenalScoresThreshold = current.matches(FETCHING_ARSENAL_SCORES_THRESHOLD);
	const fetchingArsenalLocationTilesPlayer = current.matches(FETCHING_ARSENAL_LOCATION_TILES_PLAYER);

	const isLoadingPrimary = shouldFetchPrimaryData ? fetchingPlayerSeasonArsenalScores : primaryData?.isLoading;

	const isLoadingSecondary = shouldFetchSecondaryData
		? (playingLevel === PLAYING_LEVEL_AMA && fetchingArsenalScoresThreshold) || fetchingArsenalLocationTilesPlayer
		: secondaryData?.isLoading;

	const isLoading = isLoadingPrimary || isLoadingSecondary;

	// Update machine context when data prop changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_PLAYING_LEVEL, data: playingLevel });
	}, [playingLevel, send]);

	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	useEffect(() => {
		if (primaryData?.playerSeasonArsenalScores !== playerSeasonArsenalScores && !shouldFetchPrimaryData) {
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES, data: primaryData?.playerSeasonArsenalScores });
		}
	}, [primaryData?.playerSeasonArsenalScores, playerSeasonArsenalScores, shouldFetchPrimaryData, send]);

	useEffect(() => {
		if (secondaryData?.arsenalScoresThreshold !== arsenalScoresThreshold && !shouldFetchSecondaryData) {
			send({ type: SET_ARSENAL_SCORES_THRESHOLD, data: secondaryData?.arsenalScoresThreshold });
		}
	}, [secondaryData?.arsenalScoresThreshold, arsenalScoresThreshold, shouldFetchSecondaryData, send]);

	useEffect(() => {
		if (secondaryData?.arsenalLocationTilesPlayer !== arsenalLocationTilesPlayer && !shouldFetchSecondaryData) {
			send({ type: SET_ARSENAL_LOCATION_TILES_PLAYER, data: secondaryData?.arsenalLocationTilesPlayer });
		}
	}, [secondaryData?.arsenalLocationTilesPlayer, arsenalLocationTilesPlayer, shouldFetchSecondaryData, send]);

	// Calculate pitch usage
	const arsenalScoresPitchTypesOverall = useMemo(() => {
		return playerSeasonArsenalScores
			?.filter(
				(scores: IPlayerSeasonArsenalScoresSchema) =>
					scores.countSplit === COUNT_SPLIT_OVERALL &&
					scores.bats === batsFilter &&
					getPlayerSeasonArsenalScoresByThrowsFilterStatement(
						scores,
						throwsFilter,
						arsenalLocationTilesPlayer
					)
			)
			?.sort(
				(a: IPlayerSeasonArsenalScoresSchema, b: IPlayerSeasonArsenalScoresSchema) =>
					(b.total ?? 0) - (a.total ?? 0)
			);
	}, [playerSeasonArsenalScores, batsFilter, throwsFilter, arsenalLocationTilesPlayer]);
	const totalPitches = useMemo(() => {
		return arsenalScoresPitchTypesOverall?.reduce(
			(total: number, scores: IPlayerSeasonArsenalScoresSchema) => total + (scores.total ?? 0),
			0
		);
	}, [arsenalScoresPitchTypesOverall]);
	const arsenalScoresScoredPitchTypesOverall = useMemo(() => {
		return arsenalScoresPitchTypesOverall?.filter(
			(scores: IPlayerSeasonArsenalScoresSchema) => scores.lkPitchType?.isScored === 1
		);
	}, [arsenalScoresPitchTypesOverall]);

	// Filter arsenal scores
	const arsenalScoresPitchTypesFiltered = useMemo(() => {
		return playerSeasonArsenalScores?.filter(
			(scores: IPlayerSeasonArsenalScoresSchema) =>
				(scores.countSplit === COUNT_SPLIT_PRE_2K ||
					scores.countSplit === COUNT_SPLIT_2K ||
					scores.countSplit === COUNT_SPLIT_OVERALL) &&
				scores.bats === batsFilter &&
				getPlayerSeasonArsenalScoresByThrowsFilterStatement(scores, throwsFilter, arsenalLocationTilesPlayer) &&
				scores.lkPitchType?.isScored === 1
		);
	}, [playerSeasonArsenalScores, batsFilter, throwsFilter, arsenalLocationTilesPlayer]);

	return (
		<>
			{isLoading && <Box className="loading-item" height="md" width="100%" sx={style?.container} />}
			{!isLoading && (
				<HStack flexWrap="wrap" alignItems="start" gap="6" width="100%" sx={style?.container}>
					{arsenalScoresScoredPitchTypesOverall?.map((scoresOverall: IPlayerSeasonArsenalScoresSchema) => {
						const scoresPre2k = arsenalScoresPitchTypesFiltered?.find(
							(scores: IPlayerSeasonArsenalScoresSchema) =>
								scores.pitchType === scoresOverall.pitchType && scores.countSplit === COUNT_SPLIT_PRE_2K
						);
						const scores2k = arsenalScoresPitchTypesFiltered?.find(
							(scores: IPlayerSeasonArsenalScoresSchema) =>
								scores.pitchType === scoresOverall.pitchType && scores.countSplit === COUNT_SPLIT_2K
						);
						const scoresOVR = arsenalScoresPitchTypesFiltered?.find(
							(scores: IPlayerSeasonArsenalScoresSchema) =>
								scores.pitchType === scoresOverall.pitchType &&
								scores.countSplit === COUNT_SPLIT_OVERALL
						);
						const pitchUsagePercentage = Math.round(
							((scoresOverall.total ?? 0) / (totalPitches ?? 0)) * 100
						);
						const scoresOVRThreshold = arsenalScoresThreshold?.find(
							(threshold: IArsenalScoresThresholdApiResponse) =>
								threshold.grade === THRESHOLD_GRADE_LOCATION &&
								scoresOVR?.lkPitchType?.pitchTypeGrouping === threshold.pitchTypeGrouping
						);
						const scoresOVRThresholdIconShape = getThresholdLevel(
							scoresOVR?.total,
							scoresOVRThreshold?.value
						);
						const scoresOVRThresholdIconTooltip = getLocationThresholdTooltip(
							scoresOVR?.total,
							scoresOVRThreshold?.value
						);
						return (
							<VStack alignItems="start" gap="0" key={scoresOverall.pitchType}>
								<PitchTypeLabelHStack>
									<PitchTypeLabel
										label={PITCH_TYPE_LABEL_MAP[scoresOverall.pitchType as TPitchTypes]}
										abbreviation={scoresOverall.pitchType}
										shape={ICON_CIRCLE}
										thresholdIcon={
											scoresOVRThresholdIconShape && !useCountSplits
												? {
														shape: scoresOVRThresholdIconShape,
														tooltipLabel: scoresOVRThresholdIconTooltip
												  }
												: undefined
										}
										isBold
									/>
									<Tooltip label={`${pitchUsagePercentage}%`} placement="top" hasArrow>
										<CircularProgress value={pitchUsagePercentage} size="6" color={"gray.500"} />
									</Tooltip>
								</PitchTypeLabelHStack>
								<HStack flexWrap="wrap" gap="6">
									{useCountSplits && (
										<>
											<VStack gap="1" alignItems="start">
												<CountLabelBox>Pre-2K</CountLabelBox>
												<LocationTileBox>
													<PlotStatLabel
														label={PLOT_STAT_LABEL}
														value={formatRa9(scoresPre2k?.locationRa9)}
														secondaryValue={format2080Grade(scoresPre2k?.locationGrade)}
													/>
												</LocationTileBox>
											</VStack>
											<VStack gap="1" alignItems="start">
												<CountLabelBox>2K</CountLabelBox>
												<LocationTileBox>
													<PlotStatLabel
														label={PLOT_STAT_LABEL}
														value={formatRa9(scores2k?.locationRa9)}
														secondaryValue={format2080Grade(scores2k?.locationGrade)}
													/>
												</LocationTileBox>
											</VStack>
										</>
									)}
									{!useCountSplits && (
										<VStack gap="1" alignItems="start">
											<LocationTileBox>
												<PlotStatLabel
													label={PLOT_STAT_LABEL}
													value={formatRa9(
														scoresOVRThresholdIconShape === ICON_DANGEROUS
															? null
															: scoresOVR?.locationRa9,
														"-"
													)}
													secondaryValue={format2080Grade(
														scoresOVRThresholdIconShape === ICON_DANGEROUS
															? null
															: scoresOVR?.locationGrade
													)}
													style={{ container: { minWidth: 20 } }}
												/>
											</LocationTileBox>
										</VStack>
									)}
								</HStack>
							</VStack>
						);
					})}
				</HStack>
			)}
		</>
	);
};

export default ArsenalLocationTiles;
