export const COUNT_SPLIT_OVERALL = "OVR";
export const COUNT_SPLIT_PRE_2K = "P2K";
export const COUNT_SPLIT_2K = "2K";

export const BATS_OVERALL = "OVR";
export const BATS_L = "L";
export const BATS_R = "R";

export const THROWS_OVERALL = "OVR";
export const THROWS_L = "L";
export const THROWS_R = "R";

export const PLAYING_LEVEL_PRO = "PRO";
export const PLAYING_LEVEL_AMA = "AMA";

export const GAME_TYPE_OVERALL = "OVR";

export const PITCH_TYPE_OVERALL = "OVR";

export const THRESHOLD_GRADE_LOCATION = "loc";
export const THRESHOLD_GRADE_STUFF = "stuff";
