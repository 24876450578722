import React from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { PlayerProSummary } from "_react/shared/legacy/globalcontext/player/_types";
import { Statistics } from "_react/playerpage/stat/Statistics";

type TStatisticsTabProps = {
	// TODO swap this to use combined player or get rid of this component completely
	player: PlayerProSummary;
};

const StatisticsTab = ({ player }: TStatisticsTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	return <Statistics player={player} colorScheme={chakraColorSchemeGroup} allowProjections={false} />;
};

export default StatisticsTab;
