import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import {
	GAME_TYPE_OVERALL,
	PITCH_TYPE_OVERALL,
	PLAYING_LEVEL_AMA
} from "_react/shared/data_models/arsenal_scores/_constants";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import {
	fetchArsenalScoresThreshold,
	fetchPlayerSeasonArsenalScores
} from "_react/shared/data_models/arsenal_scores/_network";
import { COUNT_SPLIT_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import { TObservedTabData } from "_react/playerpage/ama/content/observed/_types";

const ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE = "arsenalScoresThreshold";
const PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE = "playerSeasonArsenalScores";

export type TObservedTabCancelSource = {
	[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TObservedTabContext = {
	seasonFilter?: number;
	lastSeasonFilter?: number;
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	playerSeasonArsenalScores: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	cancelSources: TObservedTabCancelSource;
	toast?: CreateToastFnReturn;
};

interface IObservedTabStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId or season filter prop changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches arsenal scores thresholds data
				arsenalScoresThreshold: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches player's seasonal arsenal scores
				playerSeasonArsenalScores: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_ARSENAL_SCORES_THRESHOLD = "SET_ARSENAL_SCORES_THRESHOLD";
export const SET_PLAYER_SEASON_ARSENAL_SCORES = "SET_PLAYER_SEASON_ARSENAL_SCORES";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const FETCHING_ARSENAL_SCORES_THRESHOLD = { initialized: { arsenalScoresThreshold: "fetching" } };
export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES = {
	initialized: { playerSeasonArsenalScores: "fetching" }
};

const FETCH_ARSENAL_SCORES_THRESHOLD_DONE = "done.invoke.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE = "done.invoke.fetchingPlayerSeasonArsenalScores:invocation[0]";

type TSetArsenalScoresThresholdEvent = {
	type: typeof SET_ARSENAL_SCORES_THRESHOLD;
	data: Array<IArsenalScoresThresholdApiResponse> | null | undefined;
};
type TSetPlayerSeasonArsenalScoresEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES;
	data: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TFetchArsenalScoresThresholdEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_DONE;
	data: Array<IArsenalScoresThresholdApiResponse> | undefined;
};
type TFetchPlayerSeasonArsenalScoresEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE;
	data: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
};

type TObservedTabEvent =
	| TSetArsenalScoresThresholdEvent
	| TSetPlayerSeasonArsenalScoresEvent
	| TSetPlayerIdEvent
	| TSetSeasonFilterEvent
	| TFetchArsenalScoresThresholdEvent
	| TFetchPlayerSeasonArsenalScoresEvent;

export type TObservedTabSend = Interpreter<TObservedTabContext, IObservedTabStateSchema, TObservedTabEvent>["send"];

const ObservedTabMachine = (
	playerIdProp?: number,
	shouldFetchData = true,
	data?: TObservedTabData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TObservedTabContext, IObservedTabStateSchema, TObservedTabEvent>(
		{
			id: "ObservedTab",
			initial: "initializing",
			context: {
				seasonFilter: undefined,
				lastSeasonFilter: undefined,
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				playerSeasonArsenalScores: data?.playerSeasonArsenalScores,
				arsenalScoresThreshold: data?.arsenalScoresThreshold,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: {
						target: "initialized"
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_ARSENAL_SCORES_THRESHOLD]: { actions: "setArsenalScoresThreshold" },
						[SET_PLAYER_SEASON_ARSENAL_SCORES]: { actions: "setPlayerSeasonArsenalScores" },
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_SEASON_FILTER]: { actions: "setSeasonFilter" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						arsenalScoresThreshold: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalScoresThreshold",
												cond: "shouldFetchArsenalScoresThreshold"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalScoresThreshold",
									entry: ["refreshArsenalScoresThresholdCancelSource"],
									invoke: {
										src: "fetchArsenalScoresThreshold",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalScoresThresholdSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalScoresThresholdErrored"
										}
									}
								}
							}
						},
						playerSeasonArsenalScores: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerSeasonArsenalScores",
												cond: "shouldFetchPlayerSeasonArsenalScores"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerSeasonArsenalScores",
									entry: ["refreshPlayerSeasonArsenalScoresCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScores",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TObservedTabContext, _event: TObservedTabEvent) =>
					context.playerId !== context.lastPlayerId || context.seasonFilter !== context.lastSeasonFilter,
				shouldFetchArsenalScoresThreshold: (context: TObservedTabContext, _event: TObservedTabEvent) =>
					context.arsenalScoresThreshold === undefined && shouldFetchData,
				shouldFetchPlayerSeasonArsenalScores: (context: TObservedTabContext, _event: TObservedTabEvent) => {
					const { playerSeasonArsenalScores, playerId, seasonFilter } = context;
					return (
						playerSeasonArsenalScores === undefined &&
						shouldFetchData &&
						playerId !== undefined &&
						seasonFilter !== undefined
					);
				}
			},
			actions: {
				setArsenalScoresThreshold: assign<TObservedTabContext, TObservedTabEvent>({
					arsenalScoresThreshold: (context: TObservedTabContext, event: TObservedTabEvent) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.arsenalScoresThreshold;
						return event.data;
					},
					cancelSources: (context: TObservedTabContext, event: TObservedTabEvent) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.cancelSources;
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerSeasonArsenalScores: assign<TObservedTabContext, TObservedTabEvent>({
					playerSeasonArsenalScores: (context: TObservedTabContext, event: TObservedTabEvent) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.playerSeasonArsenalScores;
						return event.data;
					},
					cancelSources: (context: TObservedTabContext, event: TObservedTabEvent) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TObservedTabContext, TObservedTabEvent>({
					playerId: (context: TObservedTabContext, event: TObservedTabEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TObservedTabContext, TObservedTabEvent>({
					seasonFilter: (context: TObservedTabContext, event: TObservedTabEvent) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				clearContext: assign<TObservedTabContext, TObservedTabEvent>({
					lastPlayerId: (context: TObservedTabContext, _event: TObservedTabEvent) => context.playerId,
					lastSeasonFilter: (context: TObservedTabContext, _event: TObservedTabEvent) => context.seasonFilter,
					playerSeasonArsenalScores: (_context: TObservedTabContext, _event: TObservedTabEvent) => {
						return undefined;
					},
					cancelSources: (context: TObservedTabContext, _event: TObservedTabEvent) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshArsenalScoresThresholdCancelSource: assign<TObservedTabContext, TObservedTabEvent>({
					cancelSources: (context: TObservedTabContext, _event: TObservedTabEvent) => {
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonArsenalScoresCancelSource: assign<TObservedTabContext, TObservedTabEvent>({
					cancelSources: (context: TObservedTabContext, _event: TObservedTabEvent) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchArsenalScoresThresholdSuccess: assign<TObservedTabContext, TObservedTabEvent>({
					arsenalScoresThreshold: (context: TObservedTabContext, event: TObservedTabEvent) => {
						if (event.type !== FETCH_ARSENAL_SCORES_THRESHOLD_DONE) return context.arsenalScoresThreshold;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonArsenalScoresSuccess: assign<TObservedTabContext, TObservedTabEvent>({
					playerSeasonArsenalScores: (context: TObservedTabContext, event: TObservedTabEvent) => {
						const { playerSeasonArsenalScores } = context;
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE) return playerSeasonArsenalScores;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchArsenalScoresThresholdErrored: (context: TObservedTabContext, _event: TObservedTabEvent) => {
					if (context.toast)
						context.toast({
							title: "Arsenal Scores Thresholds",
							description: "Error fetching arsenal scores thresholds data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchPlayerSeasonArsenalScoresErrored: (
					context: TObservedTabContext,
					_event: TObservedTabEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Seasonal Arsenal Scores Pitch Type",
							description: "Error fetching seasonal arsenal scores pitch type.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchArsenalScoresThreshold: (context: TObservedTabContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchArsenalScoresThreshold(
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonArsenalScores: (context: TObservedTabContext, _event: AnyEventObject) => {
					const { playerId, seasonFilter } = context;
					if (!playerId || !seasonFilter) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScores(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_AMA,
								season: seasonFilter,
								gameType: GAME_TYPE_OVERALL,
								"pitchType[neq]": PITCH_TYPE_OVERALL,
								countSplit: COUNT_SPLIT_OVERALL,
								version: "v3",
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default ObservedTabMachine;
