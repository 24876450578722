import React, { useState, useEffect, useMemo, CSSProperties } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import { MultiValue, SingleValue } from "react-select";

import { TOption } from "_react/inputs/_types";
import LkSelect from "_react/inputs/lks/LkSelect";
import { Button } from "_react/shared/legacy/ui/Button";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { TextField, TChangeEvent } from "_react/shared/legacy/ui/TextField";
import { Pulser } from "_react/shared/legacy/ui/loaders/Pulser";
import { StyledSelect } from "_react/shared/selects";
import PlayerPlanNoteTypeSelect, { TNoteTypeOption } from "_react/shared/selects/PlayerPlanNoteTypeSelect";
import UserSelect, { TUserOption } from "_react/shared/selects/UserSelect";

import { DISABLED_OPACITY, PLAYER_PLAN_NOTE_USER_SELECT_GROUPS } from "_react/playerplan/shared/_constants";
import {
	getDrillLabelFromNote,
	getDrillFilterOptions,
	getFocusAreaLabelFromNote,
	getFocusAreaFilterOptions,
	getGoalStatementFromNote,
	getGoalFilterOptions,
	getFocusAreaOptions,
	getDrillOptions,
	getNoteCreatorFilterIds,
	getDepartmentFilterOptions,
	getGoalOptions
} from "_react/playerplan/shared/_helpers";
import {
	NotesContainer,
	AddNotesDiv,
	AddNoteLkSelectDiv,
	NoteFiltersDiv,
	NoteTypeSelectDiv,
	NoteMetricTypeSelectDiv,
	NoteUserSelectDiv,
	MultiLineNoteTextFieldStyle,
	AddNoteStyle,
	NoGoalNotesDiv,
	NoteContentSpan,
	AddNotesStartDiv,
	FlexSpaceBetweenDiv,
	NoteSortDiv,
	MaxWidthStyledSelectStyle,
	NoteSortToggle,
	NoteDatePickerDiv,
	NoteFiltersContainer,
	PaddedLeftRightDiv,
	NoteDatePickerContainer,
	DATEPICKER_STYLE,
	NoteGoalFilterSelectDiv,
	NoteFocusAreaFilterSelectDiv,
	NoteDrillFilterSelectDiv
} from "_react/playerplan/shared/_styles";
import {
	TNoteForm,
	TPlayerPlanNote,
	TGoalNoteFilters,
	TFocusAreaOption,
	TGoalOption,
	TPlayerPlanGoal
} from "_react/playerplan/shared/_types";
import { PlayerPlanNote } from "_react/playerplan/shared/PlayerPlanNote";
import { PlayerPlanNoteConfirmation } from "_react/playerplan/shared/PlayerPlanNoteConfirmation";

export type TPlayerPlanNotesStyle = {
	checkbox?: CSSProperties;
	textField?: CSSProperties;
};

export type TPlayerPlanNotesProps = {
	userId: number;
	isReadOnly?: boolean;
	isAddingNote?: boolean;
	goals?: Array<TPlayerPlanGoal> | null;
	// Only one of goalId, playerId should be used
	goalId?: number; // When passed in, filters all notes by goalId. Prevents note creation.
	playerId?: number;
	notes?: TPlayerPlanNote[] | null;
	addNote: (noteForm: TNoteForm) => void;
	isShowGoalStatement?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TPlayerPlanNotesStyle;
};

export const PlayerPlanNotes = ({
	userId,
	isReadOnly = false,
	isAddingNote = false,
	goals,
	goalId,
	playerId,
	notes,
	addNote,
	isShowGoalStatement = false,
	colorSchemeGroup = defaultColorScheme,
	style
}: TPlayerPlanNotesProps) => {
	const defaultFilteredNotes: TPlayerPlanNote[] | undefined = useMemo(() => {
		// If goalId is passed in, filter notes by goalId
		if (!goalId) return notes ?? undefined;
		return notes?.filter((note: TPlayerPlanNote) => {
			return note.playerPlanGoal?.id === goalId || note.playerPlanGoal?.parentGoal?.id === goalId;
		});
	}, [notes, goalId]);

	// Gets minimum note date
	const minNoteDate: string | undefined = useMemo(() => {
		return defaultFilteredNotes
			? dayjs
					.min(
						defaultFilteredNotes.map((note: TPlayerPlanNote) =>
							note.createDate ? dayjs.utc(note.createDate.substring(0, 23)).local() : dayjs()
						)
					)
					?.format("YYYY-MM-DD")
			: undefined;
	}, [defaultFilteredNotes]);

	// Gets maximum note date
	const maxNoteDate: string | undefined = useMemo(() => {
		return defaultFilteredNotes
			? dayjs
					.max(
						defaultFilteredNotes.map((note: TPlayerPlanNote) =>
							note.createDate ? dayjs.utc(note.createDate.substring(0, 23)).local() : dayjs()
						)
					)
					?.format("YYYY-MM-DD")
			: undefined;
	}, [defaultFilteredNotes]);

	// gets the default from date to be used to filter notes
	// 1 year before the max note date, or the min note date, whichever is more recent
	const defaultFromDate: string | undefined = useMemo(() => {
		return dayjs.max(dayjs(minNoteDate), dayjs(maxNoteDate).subtract(1, "year"))?.format("YYYY-MM-DD");
	}, [minNoteDate, maxNoteDate]);

	const defaultNoteForm = useMemo(() => {
		return {
			playerPlanGoalId: null,
			playerPlanGoalLabel: null,
			goalFocusAreas: null,
			focusAreaId: null,
			focusAreaLabel: null,
			goalDrills: null,
			playerPlanDrillId: null,
			drillLabel: null,
			playerId: playerId ?? null,
			metricType: null,
			type: null,
			content: null,
			isPositive: null,
			isPrivate: false,
			isNoContact: false,
			isLackingEquipment: false,
			isNewInjuries: false,
			newInjuriesNote: null,
			video: null
		};
	}, [playerId]);

	const defaultNoteFilters = useMemo(() => {
		return {
			noteType: null,
			noteMetricType: null,
			noteMetricTypeLabel: null,
			userId: null,
			fromDate: defaultFromDate ?? null,
			toDate: dayjs().format("YYYY-MM-DD"),
			goalStatement: null,
			focusAreaLabel: null,
			drillLabel: null
		};
	}, [defaultFromDate]);

	// Note Creation
	const [noteForm, setNoteForm] = useState<TNoteForm>(defaultNoteForm);
	const [noteTypePrompt, setNoteTypePrompt] = useState<string>();
	const [isConfirmingAddNote, setIsConfirmingAddNote] = useState(false);
	// Note Creation Options
	const [goalOptions, setGoalOptions] = useState<Array<TGoalOption> | undefined>();
	const [focusAreaOptions, setFocusAreaOptions] = useState<Array<TFocusAreaOption> | undefined>();
	const [drillOptions, setDrillOptions] = useState<Array<TOption<number>> | undefined>();
	// Filtering
	const [noteFilters, setNoteFilters] = useState<TGoalNoteFilters>(defaultNoteFilters);
	// Filter Options
	const [noteCreatorFilterIds, setNoteCreatorFilterIds] = useState<Array<number> | undefined>();
	const [departmentFilterOptions, setDepartmentFilterOptions] = useState<Array<TOption<string>> | undefined>();
	const [goalFilterOptions, setGoalFilterOptions] = useState<Array<TOption<string>> | undefined>();
	const [focusAreaFilterOptions, setFocusAreaFilterOptions] = useState<Array<TOption<string>> | undefined>();
	const [drillFilterOptions, setDrillFilterOptions] = useState<Array<TOption<string>> | undefined>();
	// Sorting
	const [isSortAsc, setIsSortAsc] = useState<boolean>(false);

	// Populate the goal, focus area, supporting action dropdowns w/ options
	useEffect(() => {
		setGoalOptions(getGoalOptions(goals ?? null));
	}, [goals]);

	useEffect(() => {
		setFocusAreaOptions(getFocusAreaOptions(noteForm.goalFocusAreas ?? null));
	}, [noteForm.goalFocusAreas]);

	useEffect(() => {
		setDrillOptions(getDrillOptions(noteForm.goalDrills ?? null));
	}, [noteForm.goalDrills]);

	// Populate the note creator, department, goal, focus area, and drill filters w/ options
	useEffect(() => {
		setNoteCreatorFilterIds(getNoteCreatorFilterIds(defaultFilteredNotes));
		setDepartmentFilterOptions(getDepartmentFilterOptions(defaultFilteredNotes));
		setGoalFilterOptions(getGoalFilterOptions(defaultFilteredNotes));
		setFocusAreaFilterOptions(getFocusAreaFilterOptions(defaultFilteredNotes));
		setDrillFilterOptions(getDrillFilterOptions(defaultFilteredNotes));
	}, [defaultFilteredNotes]);

	// When goal, player, changes,
	// reset note form, filters, sort to initial state
	useEffect(() => {
		setNoteForm(defaultNoteForm);
		setNoteTypePrompt(undefined);
		setIsConfirmingAddNote(false);
		setNoteFilters(defaultNoteFilters);
		setIsSortAsc(false);
	}, [goalId, playerId, defaultNoteFilters, defaultNoteForm]);

	const userFilteredNotes: TPlayerPlanNote[] = useMemo(() => {
		const newUserFilteredNotes = defaultFilteredNotes
			? defaultFilteredNotes
					.filter(
						(note: TPlayerPlanNote) =>
							(!noteFilters.userId || note.lastChangeUserId === noteFilters.userId) &&
							(!noteFilters.noteType || note.type?.value === noteFilters.noteType) &&
							(!noteFilters.noteMetricType || note.metricType?.value === noteFilters.noteMetricType) &&
							(!note.isPrivate || note.lastChangeUserId === userId) &&
							(!noteFilters.fromDate ||
								!dayjs
									.utc(note.createDate.substring(0, 23))
									.local()
									.isBefore(dayjs(noteFilters.fromDate))) &&
							(!noteFilters.toDate ||
								dayjs
									.utc(note.createDate.substring(0, 23))
									.local()
									.isBefore(dayjs(noteFilters.toDate).add(1, "day"))) &&
							(!noteFilters.goalStatement ||
								noteFilters.goalStatement === getGoalStatementFromNote(note)) &&
							(!noteFilters.focusAreaLabel ||
								noteFilters.focusAreaLabel === getFocusAreaLabelFromNote(note)) &&
							(!noteFilters.drillLabel || noteFilters.drillLabel === getDrillLabelFromNote(note))
					)
					.sort((a: TPlayerPlanNote, b: TPlayerPlanNote) => {
						const aValue = dayjs(a.createDate);
						const bValue = dayjs(b.createDate);
						if (aValue.isSame(bValue)) return 0;
						return aValue.isBefore(bValue) ? -1 : 1;
					})
			: [];
		return isSortAsc ? newUserFilteredNotes : newUserFilteredNotes.reverse();
	}, [defaultFilteredNotes, noteFilters, isSortAsc, userId]);

	return (
		<>
			<style>{DATEPICKER_STYLE}</style>
			{isConfirmingAddNote ? (
				<PlayerPlanNoteConfirmation
					noteForm={noteForm}
					noteTypePrompt={noteTypePrompt}
					setIsConfirmingAddNote={setIsConfirmingAddNote}
					setNoteForm={setNoteForm}
					defaultNoteForm={defaultNoteForm}
					setNoteTypePrompt={setNoteTypePrompt}
					setNoteFilters={setNoteFilters}
					defaultNoteFilters={defaultNoteFilters}
					setIsSortAsc={setIsSortAsc}
					addNote={addNote}
					colorSchemeGroup={colorSchemeGroup}
				/>
			) : (
				<>
					{defaultFilteredNotes?.length ? (
						<>
							<FlexSpaceBetweenDiv>
								<NoteFiltersContainer>
									<NoteFiltersDiv>
										<NoteUserSelectDiv>
											<UserSelect
												handleSelect={(e?: TUserOption) =>
													setNoteFilters({
														...noteFilters,
														userId: e?.value ?? null
													})
												}
												placeholder={`Filter note creator...`}
												value={noteFilters.userId}
												groupIds={PLAYER_PLAN_NOTE_USER_SELECT_GROUPS}
												userIds={noteCreatorFilterIds}
												activeOnly
												isClearable
											/>
										</NoteUserSelectDiv>
										<NoteTypeSelectDiv>
											<PlayerPlanNoteTypeSelect
												handleSelect={(opt?: TNoteTypeOption) =>
													setNoteFilters({
														...noteFilters,
														noteType: opt?.value ?? null
													})
												}
												value={noteFilters.noteType}
												placeholder={"Filter type..."}
												isClearable={true}
											/>
										</NoteTypeSelectDiv>
										<NoteMetricTypeSelectDiv>
											<StyledSelect
												styles={MaxWidthStyledSelectStyle}
												options={departmentFilterOptions}
												isClearable={true}
												value={
													noteFilters.noteMetricType && noteFilters.noteMetricTypeLabel
														? {
																value: noteFilters.noteMetricType,
																label: noteFilters.noteMetricTypeLabel
														  }
														: null
												}
												onChange={(opt: TOption<string> | null) =>
													setNoteFilters({
														...noteFilters,
														noteMetricType: (opt as TOption<string>)?.value ?? null,
														noteMetricTypeLabel: (opt as TOption<string>)?.label ?? null
													})
												}
												placeholder="Filter department..."
											></StyledSelect>
										</NoteMetricTypeSelectDiv>
									</NoteFiltersDiv>
									<NoteFiltersDiv>
										{!goalId && (
											<NoteGoalFilterSelectDiv>
												<StyledSelect
													styles={MaxWidthStyledSelectStyle}
													options={goalFilterOptions}
													isClearable={true}
													value={
														noteFilters.goalStatement
															? {
																	value: noteFilters.goalStatement,
																	label: noteFilters.goalStatement
															  }
															: null
													}
													onChange={(opt: TOption<string> | null) =>
														setNoteFilters({
															...noteFilters,
															goalStatement: (opt as TOption<string>)?.value ?? null
														})
													}
													placeholder="Filter goal..."
												></StyledSelect>
											</NoteGoalFilterSelectDiv>
										)}
										<NoteFocusAreaFilterSelectDiv>
											<StyledSelect
												styles={MaxWidthStyledSelectStyle}
												options={focusAreaFilterOptions}
												isClearable={true}
												value={
													noteFilters.focusAreaLabel
														? {
																value: noteFilters.focusAreaLabel,
																label: noteFilters.focusAreaLabel
														  }
														: null
												}
												onChange={(opt: TOption<string> | null) =>
													setNoteFilters({
														...noteFilters,
														focusAreaLabel: (opt as TOption<string>)?.value ?? null
													})
												}
												placeholder="Filter focus area..."
											></StyledSelect>
										</NoteFocusAreaFilterSelectDiv>
										<NoteDrillFilterSelectDiv>
											<StyledSelect
												styles={MaxWidthStyledSelectStyle}
												options={drillFilterOptions}
												isClearable={true}
												value={
													noteFilters.drillLabel
														? {
																value: noteFilters.drillLabel,
																label: noteFilters.drillLabel
														  }
														: null
												}
												onChange={(opt: TOption<string> | null) =>
													setNoteFilters({
														...noteFilters,
														drillLabel: (opt as TOption<string>)?.value ?? null
													})
												}
												placeholder="Filter supporting action..."
											></StyledSelect>
										</NoteDrillFilterSelectDiv>
									</NoteFiltersDiv>
								</NoteFiltersContainer>

								<NoteFiltersContainer>
									<NoteDatePickerContainer>
										<NoteDatePickerDiv>
											<DatePicker
												id="player_plan_notes_from_date_picker"
												filterDate={(date: Date) => {
													return dayjs(date).isAfter(dayjs(minNoteDate).subtract(1, "day"));
												}}
												onChange={(value: Date) => {
													if (value) {
														setNoteFilters({
															...noteFilters,
															fromDate: dayjs(value).format("YYYY-MM-DD")
														});
													}
												}}
												// If a user types a valid date that is before the min date,
												// set the datepicker to the min date
												onChangeRaw={(e: TChangeEvent) => {
													if (minNoteDate && dayjs(e.target.value).isBefore(minNoteDate)) {
														setNoteFilters({
															...noteFilters,
															fromDate: minNoteDate
														});
													}
												}}
												selected={
													noteFilters.fromDate
														? dayjs(noteFilters.fromDate).toDate()
														: undefined
												}
											/>
										</NoteDatePickerDiv>
										<PaddedLeftRightDiv>to</PaddedLeftRightDiv>
										<NoteDatePickerDiv>
											<DatePicker
												id="player_plan_notes_to_date_picker"
												filterDate={(date: Date) => {
													return dayjs(date).isAfter(dayjs(minNoteDate).subtract(1, "day"));
												}}
												onChange={(value: Date) => {
													if (value) {
														setNoteFilters({
															...noteFilters,
															toDate: dayjs(value).format("YYYY-MM-DD")
														});
													}
												}}
												// If a user types a valid date that is before the min date,
												// set the datepicker to the min date
												onChangeRaw={(e: TChangeEvent) => {
													if (minNoteDate && dayjs(e.target.value).isBefore(minNoteDate)) {
														setNoteFilters({
															...noteFilters,
															toDate: minNoteDate
														});
													}
												}}
												selected={
													noteFilters.toDate ? dayjs(noteFilters.toDate).toDate() : undefined
												}
											/>
										</NoteDatePickerDiv>
									</NoteDatePickerContainer>
									<NoteSortDiv>
										Sort by Date:
										<NoteSortToggle
											onClick={() => {
												setIsSortAsc(!isSortAsc);
											}}
										>
											{isSortAsc ? String.fromCharCode(9650) : String.fromCharCode(9660)}
										</NoteSortToggle>
									</NoteSortDiv>
								</NoteFiltersContainer>
							</FlexSpaceBetweenDiv>
						</>
					) : (
						<NoGoalNotesDiv>No Notes Yet</NoGoalNotesDiv>
					)}
					{userFilteredNotes && Boolean(userFilteredNotes.length) ? (
						<NotesContainer>
							{userFilteredNotes.map((note: TPlayerPlanNote, index: number) => (
								<PlayerPlanNote
									key={note.id}
									note={note}
									isShowBorder={index !== userFilteredNotes.length - 1}
									isShowGoalStatement={isShowGoalStatement}
								/>
							))}
						</NotesContainer>
					) : defaultFilteredNotes?.length ? (
						<NoGoalNotesDiv>No Notes</NoGoalNotesDiv>
					) : (
						<></>
					)}
					{!isReadOnly && !goalId && (
						<>
							<AddNotesDiv>
								<AddNoteLkSelectDiv>
									<LkSelect<string>
										lkName={"lk_player_plan_metric_type"}
										onChange={(
											opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
										) =>
											setNoteForm({
												...noteForm,
												metricType: (opt as TOption<string>)?.value
											})
										}
										valueOnlyValue={noteForm.metricType}
										placeholder={"Select department..."}
										menuPortalTarget={document.body}
										isClearable={true}
									/>
								</AddNoteLkSelectDiv>
								<AddNoteLkSelectDiv>
									<PlayerPlanNoteTypeSelect
										handleSelect={(opt?: TNoteTypeOption) => {
											setNoteForm({
												...noteForm,
												type: opt?.value ?? null
											});
											setNoteTypePrompt(opt?.prompt);
										}}
										value={noteForm.type}
										placeholder={"Select type..."}
										isClearable={true}
									/>
								</AddNoteLkSelectDiv>
								<AddNoteLkSelectDiv>
									<StyledSelect
										styles={MaxWidthStyledSelectStyle}
										options={goalOptions}
										isClearable={true}
										value={
											noteForm.playerPlanGoalId
												? {
														value: noteForm.playerPlanGoalId,
														label: noteForm.playerPlanGoalLabel
												  }
												: null
										}
										onChange={(opt: TGoalOption | null) =>
											setNoteForm({
												...noteForm,
												playerPlanGoalId: opt?.value ?? null,
												playerPlanGoalLabel: opt?.label ?? null,
												goalFocusAreas: opt?.goalFocusAreas ?? null,
												focusAreaId: null,
												focusAreaLabel: null,
												goalDrills: null,
												playerPlanDrillId: null,
												drillLabel: null
											})
										}
										placeholder={"Select goal..."}
									/>
								</AddNoteLkSelectDiv>
								<AddNoteLkSelectDiv>
									<StyledSelect
										styles={MaxWidthStyledSelectStyle}
										options={focusAreaOptions}
										isDisabled={!noteForm.playerPlanGoalId}
										isClearable={true}
										value={
											noteForm.focusAreaId
												? {
														value: noteForm.focusAreaId,
														label: noteForm.focusAreaLabel
												  }
												: null
										}
										onChange={(opt: TFocusAreaOption | null) =>
											setNoteForm({
												...noteForm,
												focusAreaId: opt?.value ?? null,
												focusAreaLabel: opt?.label ?? null,
												goalDrills: opt?.goalDrills ?? null,
												playerPlanDrillId: null,
												drillLabel: null
											})
										}
										placeholder={"Select focus area..."}
									/>
								</AddNoteLkSelectDiv>
								<AddNoteLkSelectDiv>
									<StyledSelect
										styles={MaxWidthStyledSelectStyle}
										isDisabled={!noteForm.focusAreaId}
										options={drillOptions}
										isClearable={true}
										value={
											noteForm.playerPlanDrillId
												? {
														value: noteForm.playerPlanDrillId,
														label: noteForm.drillLabel
												  }
												: null
										}
										onChange={(opt: TOption<number> | null) =>
											setNoteForm({
												...noteForm,
												playerPlanDrillId: opt?.value ?? null,
												drillLabel: opt?.label ?? null
											})
										}
										placeholder={"Select supporting action..."}
									/>
								</AddNoteLkSelectDiv>
								<div>
									<input
										id="video-input"
										type="file"
										onChange={e =>
											setNoteForm({
												...noteForm,
												video: e?.target?.files?.length ? e.target.files[0] : null
											})
										}
									/>
								</div>
							</AddNotesDiv>
							{noteTypePrompt && (
								<AddNotesDiv>
									<NoteContentSpan>{noteTypePrompt}</NoteContentSpan>
								</AddNotesDiv>
							)}
							<AddNotesStartDiv>
								<div>
									{!isAddingNote && (
										<Button
											onClick={() => {
												setIsConfirmingAddNote(true);
											}}
											colorScheme={colorSchemeGroup.secondary}
											title="Add Note"
											disabled={!noteForm.content}
											style={AddNoteStyle}
											disabledOpacity={DISABLED_OPACITY}
										/>
									)}
									{isAddingNote && <Pulser />}
								</div>
								<TextField
									name="player_plan_note_enter_note_textfield"
									onChange={(e: TChangeEvent) =>
										setNoteForm({ ...noteForm, content: e.target.value })
									}
									value={noteForm.content}
									multiline={true}
									fullWidth
									placeholder={"Enter a note..."}
									style={{ ...MultiLineNoteTextFieldStyle, ...style?.textField }}
								/>
							</AddNotesStartDiv>
						</>
					)}
				</>
			)}
		</>
	);
};
