import React from "react";

import { $TSFixMeEval } from "_react/evals/shared/_types";
import { EvaluationsTable } from "_react/playerpage/eval/EvaluationsTable";
import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";

type TScoutingReportsTabProps = {
	evals: $TSFixMeEval[] | null | undefined;
};

const ScoutingReportsTab = ({ evals }: TScoutingReportsTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	return (
		<EvaluationsTable
			evaluations={evals}
			colorScheme={chakraColorSchemeGroup}
			style={{
				evalList: {
					eval: {
						evalContentDiv: {
							width: "100%"
						},
						summarySection: {
							width: "100%"
						},
						evalSection: {
							width: "100%"
						}
					}
				}
			}}
		/>
	);
};

export default ScoutingReportsTab;
