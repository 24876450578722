import { chakra, Box, HStack } from "@chakra-ui/react";

// Chakra components

export const PitchTypeLabelHStack = chakra(HStack, {
	baseStyle: {
		width: "100%",
		justifyContent: "space-between",
		marginBottom: "5",
		paddingBottom: "1",
		borderBottom: "1px solid",
		borderColor: "gray.700"
	}
});

export const LocationTileBox = chakra(Box, {
	baseStyle: {
		gap: 0,
		border: "1px solid",
		borderColor: "gray.300",
		borderRadius: "lg",
		paddingTop: 4,
		paddingBottom: 4,
		paddingLeft: 10,
		paddingRight: 10
	}
});

export const CountLabelBox = chakra(Box, {
	baseStyle: {
		marginLeft: "2",
		fontSize: "md",
		fontWeight: "bold",
		color: "gray.500"
	}
});
