import React, { useEffect, useMemo, CSSProperties } from "react";
import dayjs from "dayjs";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import {
	Box,
	IconButton,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	Stack,
	HStack,
	Text,
	Button,
	useDisclosure
} from "@chakra-ui/react";

import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import ExternalLinkIcon from "_react/shared/ui/icons/ExternalLinkIcon";

import DPLQuestionnaireYearGroup from "_react/playerpage/ama/profile/DPLQuestionnaire/DPLQuestionnaireYearGroup";
import DPLQuestionnairePreviousYearSurveys from "_react/playerpage/ama/profile/DPLQuestionnaire/DPLQuestionnairePreviousYearSurveys";
import { TDPLQuestionnairePopoverData } from "_react/playerpage/ama/profile/DPLQuestionnaire/_types";
import {
	transformCurrentYearSurveys,
	transformPreviousYearSurveys
} from "_react/playerpage/ama/profile/DPLQuestionnaire/_helpers";
import createAmaProspectValuesStatCardMachine, {
	TDPLQuestionnairePopoverContext,
	SET_PLAYER_ID,
	SET_CURRENT_YEAR_DPL_SURVEYS,
	SET_PROSPECT_DPL_SURVEYS
} from "_react/playerpage/ama/profile/DPLQuestionnaire/_machine";

type TDPLQuestionnairePopoverStyle = {
	iconButton?: CSSProperties;
};
type TDPLQuestionnairePopoverProps = {
	playerId: number;
	philId: number | null;
	dplId?: number;
	shouldFetchData?: boolean;
	data?: TDPLQuestionnairePopoverData;
	style?: TDPLQuestionnairePopoverStyle;
};

const DPLQuestionnairePopover = ({
	playerId,
	philId,
	dplId,
	shouldFetchData,
	data,
	style
}: TDPLQuestionnairePopoverProps) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	// In order to prevent the DPL popover from closing when opening a questionnaire response modal (while also retaining the popover's close on blur functionality) the questionnaire modal's disclosure has to be accessible here, and passed to the child components.
	const { onOpen: onOpenModal, onClose: onCloseModal, isOpen: isOpenModal } = useDisclosure();

	const currentYear = dayjs().year();
	const toast = useToast();

	const [current, send] = useMachine(createAmaProspectValuesStatCardMachine(playerId, shouldFetchData, data, toast));
	const { currentYearDplSurveys, prospectDplSurveys } = current.context as TDPLQuestionnairePopoverContext;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.currentYearDplSurveys !== currentYearDplSurveys && !shouldFetchData) {
			send({ type: SET_CURRENT_YEAR_DPL_SURVEYS, data: data?.currentYearDplSurveys });
		}
	}, [data?.currentYearDplSurveys, shouldFetchData, send, currentYearDplSurveys]);

	useEffect(() => {
		if (data?.prospectDplSurveys !== prospectDplSurveys && !shouldFetchData) {
			send({ type: SET_PROSPECT_DPL_SURVEYS, data: data?.prospectDplSurveys });
		}
	}, [data?.prospectDplSurveys, shouldFetchData, send, prospectDplSurveys]);

	const transformedCurrentYearSurveys = useMemo(
		() => transformCurrentYearSurveys(currentYear, currentYearDplSurveys, prospectDplSurveys),
		[currentYearDplSurveys, prospectDplSurveys, currentYear]
	);

	const transformedPreviousYearSurveys = useMemo(
		() => transformPreviousYearSurveys(currentYear, prospectDplSurveys),
		[prospectDplSurveys, currentYear]
	);

	return (
		<Popover
			placement="left"
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={() => {
				if (!isOpenModal) {
					// Only close the popover if the modal isn't being displayed
					onClose();
				}
			}}
		>
			<PopoverTrigger>
				<IconButton
					aria-label={`Show DPL Questionnaires`}
					variant="ghost"
					h="fit-content"
					minWidth="fit-content"
					icon={<OutlineInfo />}
					sx={style?.iconButton}
				/>
			</PopoverTrigger>
			{/*
				Rocky header is zIndex 1000, "popover" is 1500
				Using the workaround from https://github.com/chakra-ui/chakra-ui/issues/3043
			 */}
			<Box zIndex="popover">
				<PopoverContent>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverHeader textAlign="left">DPL Questionnaires</PopoverHeader>
					<PopoverBody fontSize="xs" sx={{ maxHeight: "450px", overflow: "scroll" }}>
						<Stack spacing={3} direction="column">
							<DPLQuestionnaireYearGroup
								year={transformedCurrentYearSurveys}
								philId={philId}
								onOpenModal={onOpenModal}
								onCloseModal={onCloseModal}
								isOpenModal={isOpenModal}
							/>
							{transformedPreviousYearSurveys != null && (
								<DPLQuestionnairePreviousYearSurveys
									previousYearSurveys={transformedPreviousYearSurveys}
									philId={philId}
									onOpenModal={onOpenModal}
									onCloseModal={onCloseModal}
									isOpenModal={isOpenModal}
								/>
							)}
							<Button
								variant="outline"
								size="xs"
								onClick={() => window.open(`https://dpl.mlb.com/prospect/${dplId}`, "_blank")}
							>
								<HStack spacing={1}>
									<Text>DPL Profile</Text>
									<ExternalLinkIcon sx={{ strokeWidth: 4 }} />
								</HStack>
							</Button>
						</Stack>
					</PopoverBody>
				</PopoverContent>
			</Box>
		</Popover>
	);
};

export default DPLQuestionnairePopover;
