import { CancelToken } from "axios";
import axios from "_redux/_utils/_axios";

import { fetchResource, handleAxiosError } from "_react/shared/_helpers/axios";
import { IDPLSurvey } from "_react/shared/data_models/dpl/_types";

export type TProspectDPLSurveysQueryParams = {
	playerId?: number;
	philId?: number;
};

export type TCurrentYearDPLSurveysQueryParams = {
	year: number;
};

export type TDPLMedicalFileContentsQueryParams = {
	fileId: string;
	fileUrl: string;
};

export const fetchCurrentYearDPLSurveys = (queryParams: TCurrentYearDPLSurveysQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TCurrentYearDPLSurveysQueryParams, Array<IDPLSurvey>>("/dpl/surveys/all", queryParams, cancelToken);

export const fetchProspectDPLSurveys = (queryParams: TProspectDPLSurveysQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TProspectDPLSurveysQueryParams, Array<IDPLSurvey>>("/dpl/surveys", queryParams, cancelToken);

export const fetchDPLMedicalFileContents = (
	queryParams: TDPLMedicalFileContentsQueryParams,
	fileName: string,
	cancelToken?: CancelToken
) => {
	return axios
		.get(
			`/dpl/medical/file/contents?fileId=${queryParams.fileId}&fileUrl=${queryParams.fileUrl}`,
			{ responseType: "blob", cancelToken }
			// Copied from https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
		)
		.then(response => {
			// create file link in browser's memory
			const href = URL.createObjectURL(response.data);

			// create "a" HTML element with href to file & click
			const link = document.createElement("a");
			link.href = href;
			link.setAttribute("download", `${fileName}`); //or any other extension
			link.click();
			URL.revokeObjectURL(href);
		})
		.catch(handleAxiosError);
};
