export const ROSEN_USER_ID = 40074;
export const INTL_SCOUTING_IAM_GROUP = 3;
export const RND_IAM_GROUP = 27;
export const SWE_IAM_GROUP = 41;
export const AMA_ADMIN_IAM_GROUP = 62;

export const OTHER_SPORTS = [
	{ label: "Football", value: "Football" },
	{ label: "Soccer", value: "Soccer" },
	{ label: "Basketball", value: "Basketball" },
	{ label: "Track", value: "Track" },
	{ label: "Golf", value: "Golf" },
	{ label: "Hockey", value: "Hockey" },
	{ label: "Wrestling", value: "Wrestling" }
];

export const PLAYER_AMA_FIELDS: [
	"address1",
	"address2",
	"agentId",
	"alternatePosition",
	"bats",
	"birthCity",
	"birthCountry",
	"birthState",
	"currentTeamId",
	"decisionMaker",
	"eligibleYear",
	"email",
	"ethnicity",
	"fathersOccupation",
	"financialStatus",
	"firstName",
	"highSchoolGradYear",
	"homeCity",
	"homeCountry",
	"homeState",
	"instagram",
	"isBudgetSaver",
	"isMlLineage",
	"isParentsCollegeOriented",
	"isPriorTj",
	"isRedraft",
	"lastName",
	"maritalStatus",
	"middleName",
	"mlLineage",
	"mobilePhone",
	"mothersOccupation",
	"nextYearSchoolId",
	"otherSportNew",
	"otherSportsPlayed",
	"position",
	"rosterFirstName",
	"schoolClass",
	"semestersToGraduate",
	"signability",
	"studentType",
	"throws",
	"twitter",
	"weight",
	"zip"
] = [
	"address1",
	"address2",
	"agentId",
	"alternatePosition",
	"bats",
	"birthCity",
	"birthCountry",
	"birthState",
	"currentTeamId",
	"decisionMaker",
	"eligibleYear",
	"email",
	"ethnicity",
	"fathersOccupation",
	"financialStatus",
	"firstName",
	"highSchoolGradYear",
	"homeCity",
	"homeCountry",
	"homeState",
	"instagram",
	"isBudgetSaver",
	"isMlLineage",
	"isParentsCollegeOriented",
	"isPriorTj",
	"isRedraft",
	"lastName",
	"maritalStatus",
	"middleName",
	"mlLineage",
	"mobilePhone",
	"mothersOccupation",
	"nextYearSchoolId",
	"otherSportNew",
	"otherSportsPlayed",
	"position",
	"rosterFirstName",
	"schoolClass",
	"semestersToGraduate",
	"signability",
	"studentType",
	"throws",
	"twitter",
	"weight",
	"zip"
];

export const PLAYER_INTL_FIELDS: [
	"address1",
	"address2",
	"agentId",
	"alternatePosition",
	"bats",
	"birthCity",
	"birthCountry",
	"birthState",
	"city",
	"country",
	"currentTeamId",
	"draftedRound",
	"draftPickInRound",
	"eligibleYear",
	"email",
	"equalOpportunity",
	"extendedName",
	"eyewear",
	"firstName",
	"firstNameLegal",
	"homePhone",
	"instagram",
	"internationalPlayerType",
	"lastName",
	"middleName",
	"mobilePhone",
	"overallPick",
	"ownerUserId",
	"position",
	"rosterFirstName",
	"schoolClass",
	"state",
	"throws",
	"twinCount",
	"twitter",
	"uniformNumber",
	"weight",
	"zip"
] = [
	"address1",
	"address2",
	"agentId",
	"alternatePosition",
	"bats",
	"birthCity",
	"birthCountry",
	"birthState",
	"city",
	"country",
	"currentTeamId",
	"draftedRound",
	"draftPickInRound",
	"eligibleYear",
	"email",
	"equalOpportunity",
	"extendedName",
	"eyewear",
	"firstName",
	"firstNameLegal",
	"homePhone",
	"instagram",
	"internationalPlayerType",
	"lastName",
	"middleName",
	"mobilePhone",
	"overallPick",
	"ownerUserId",
	"position",
	"rosterFirstName",
	"schoolClass",
	"state",
	"throws",
	"twinCount",
	"twitter",
	"uniformNumber",
	"weight",
	"zip"
];

export const TEXT_FIELDS_TO_CONVERT = ["draftedRound", "eligibleYear", "highSchoolGradYear", "signability", "weight"];

export const PLAYER_FIELDS: [
	"bamId",
	"collegesplitsId",
	"dplId",
	"draftId",
	"ebisId",
	"id",
	"playerAmaId",
	"playerIntlId",
	"playerClassification"
] = [
	"bamId",
	"collegesplitsId",
	"dplId",
	"draftId",
	"ebisId",
	"id",
	"playerAmaId",
	"playerIntlId",
	"playerClassification"
];
