import React from "react";
import ReactDOM from "react-dom/client";
import "react-virtualized/styles.css"; // only needs to be imported once
import "tippy.js/dist/tippy.css";
import "react-datepicker/dist/react-datepicker.css";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as Sentry from "@sentry/browser";
import "array-flat-polyfill";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga4";
import App from "_react/app";

dayjs.extend(relativeTime);

if (["prod"].includes(process.env.REACT_APP_ENV)) {
	LogRocket.init("acdfk5/rocky");
	setupLogRocketReact(LogRocket);

	Sentry.init({ dsn: "https://9ec1f3accf4b416fb64924d5b7fdaf58@sentry.io/5169405" });
	LogRocket.getSessionURL(sessionURL => {
		Sentry.configureScope(scope => {
			scope.setExtra("sessionURL", sessionURL);
		});
	});

	ReactGA.initialize("G-S96WY3SERP");
}

const render = Component => {
	const root = ReactDOM.createRoot(document.getElementById("root"));
	root.render(
		<SnackbarProvider>
			<Component />
		</SnackbarProvider>
	);
};

render(App);

if (module.hot) {
	module.hot.accept("_react/app", () => {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const { App: NextApp } = require("_react/app");
		render(NextApp);
	});
}

//
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
