import React, { useEffect, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { useToast, VStack, HStack, Box } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { ASC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import {
	IStatsPlayerFielding,
	IStatsPlayerFieldingOpps,
	IStatsPlayerRangeOpps,
	IStatsPlayerReceivingOpps,
	IStatsPlayerRangeByLevel,
	IStatsPlayerReceivingByLevel
} from "_react/shared/data_models/defensive_observed/_types";

import {
	DEFAULT_SEASONS,
	MOBILE_COLUMNS,
	NON_MOBILE_COLUMNS,
	NON_MOBILE_PARENT_COLUMNS,
	NULL_FILLER_TEXT,
	POSITION_TO_COLUMN_MAPPING
} from "_react/shared/ui/data/tables/FieldingOaaTable/_constants";
import { getInningsFromStatsPlayerFielding } from "_react/shared/ui/data/tables/FieldingOaaTable/_helpers";
import FieldingOaaTableMachine, {
	TFieldingOaaTableContext,
	FETCHING_FIELDING_OAA,
	FETCHING_FIELDING_OAA_OPPS,
	SET_FIELDING_OAA,
	SET_FIELDING_OAA_OPPS,
	SET_SEASONS,
	SET_PLAYER_ID
} from "_react/shared/ui/data/tables/FieldingOaaTable/_machine";
import {
	TFieldingOaaRow,
	TFieldingOaaRowMobile,
	TStatsPlayerFieldingBasic
} from "_react/shared/ui/data/tables/FieldingOaaTable/_types";

export type TFieldingOaaTableData = {
	fieldingOaa?: IStatsPlayerFielding | null;
	fieldingOaaOpps?: IStatsPlayerFieldingOpps | null;
	isLoading?: boolean;
};

type TFieldingOaaTableProps = {
	title?: string;
	playerId?: number;
	seasons?: Array<number>;
	data?: TFieldingOaaTableData;
	shouldFetchData?: boolean;
	columns?: Array<string>;
	columnsMobile?: Array<string>;
	tableProps?: TTableProps<TFieldingOaaRow, keyof TFieldingOaaRow>;
	tablePropsMobile?: TTableProps<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>;
};

const FieldingOaaTable = ({
	title,
	playerId,
	seasons = DEFAULT_SEASONS,
	data,
	shouldFetchData = true,
	columns,
	columnsMobile,
	tableProps,
	tablePropsMobile
}: TFieldingOaaTableProps) => {
	const toast = useToast();

	const [current, send] = useMachine(FieldingOaaTableMachine(playerId, seasons, shouldFetchData, data, toast));
	const context = current.context;
	const { fieldingOaa, fieldingOaaOpps } = context as TFieldingOaaTableContext;

	const isFetchingFieldingOaa = current.matches(FETCHING_FIELDING_OAA);
	const isFetchingFieldingOaaOpps = current.matches(FETCHING_FIELDING_OAA_OPPS);
	const isLoading = shouldFetchData ? isFetchingFieldingOaa || isFetchingFieldingOaaOpps : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_SEASONS, data: seasons });
	}, [seasons, send]);

	useEffect(() => {
		if (data?.fieldingOaa !== fieldingOaa && !shouldFetchData) {
			send({ type: SET_FIELDING_OAA, data: data?.fieldingOaa });
		}
	}, [data?.fieldingOaa, shouldFetchData, send, fieldingOaa]);

	useEffect(() => {
		if (data?.fieldingOaaOpps !== fieldingOaaOpps && !shouldFetchData) {
			send({ type: SET_FIELDING_OAA_OPPS, data: data?.fieldingOaaOpps });
		}
	}, [data?.fieldingOaaOpps, shouldFetchData, send, fieldingOaaOpps]);

	// Group fielding OAA Opportunities by season and position
	const groupedFieldingOaaOpps: Record<number, Record<string, number | null>> = useMemo(() => {
		const newGroupedFieldingOaaOpps: Record<number, Record<string, number | null>> = {};
		const combinedOpps = (fieldingOaaOpps?.statsPlayerRangeOpps ?? []).concat(
			fieldingOaaOpps?.statsPlayerReceivingOpps ?? []
		);
		combinedOpps?.forEach((fieldingOaaOpp: IStatsPlayerRangeOpps | IStatsPlayerReceivingOpps) => {
			const season = fieldingOaaOpp.season;
			const position = fieldingOaaOpp.position;
			const opportunities = fieldingOaaOpp.opportunitiesPerFullSeason;

			const seasonRecord: Record<string, number | null> | undefined = newGroupedFieldingOaaOpps[season];
			// Handle case where we don't have any data for a season
			if (seasonRecord === undefined) {
				newGroupedFieldingOaaOpps[season] = { [position]: opportunities };
			}
			// Handle case where we do have data for a season
			else {
				const positionOpportunities: number | null = seasonRecord[position] ?? null;
				if (opportunities !== null) seasonRecord[position] = (positionOpportunities ?? 0) + opportunities;
				else seasonRecord[position] = positionOpportunities;
			}
		});

		return newGroupedFieldingOaaOpps;
	}, [fieldingOaaOpps]);

	// Get non-mobile rows
	const fieldingOaaRows: Array<TFieldingOaaRow> = useMemo(() => {
		const newfieldingOaaRows: Array<TFieldingOaaRow> = [];
		const combinedOaa = (fieldingOaa?.statsPlayerRangeBylevel ?? []).concat(
			fieldingOaa?.statsPlayerReceivingBylevel ?? []
		);
		combinedOaa?.forEach((fieldingOaaDatum: IStatsPlayerRangeByLevel | IStatsPlayerReceivingByLevel) => {
			const season = fieldingOaaDatum.season;
			const level = fieldingOaaDatum.level;
			const position = fieldingOaaDatum.position;

			const newStatsPlayerFieldingBasic: TStatsPlayerFieldingBasic = {
				opportunities: fieldingOaaDatum.opportunities,
				innings: fieldingOaaDatum.innings,
				fullSeasonScaledOaaCentered: fieldingOaaDatum.fullSeasonScaledOaaCentered,
				opportunitiesPerFullSeason: groupedFieldingOaaOpps[season]
					? groupedFieldingOaaOpps[season][position]
					: null
			};

			const recordToUpdate: TFieldingOaaRow | undefined = newfieldingOaaRows.find(
				(fieldingOaaRow: TFieldingOaaRow) => fieldingOaaRow.season === season && fieldingOaaRow.level === level
			);
			// Handle case where fieldingOaaRows does not have any data for a given season and level
			if (recordToUpdate === undefined)
				newfieldingOaaRows.push({
					season: season,
					level: level,
					fieldingOaaByPosition: { [position]: [newStatsPlayerFieldingBasic] }
				});
			// Handle case where we already have some fieldingOaa data for a season and level
			else {
				recordToUpdate.fieldingOaaByPosition[position] !== undefined
					? // Handle case where we have fieldingOaa data for a season and position
					  recordToUpdate.fieldingOaaByPosition[position].push(newStatsPlayerFieldingBasic)
					: // Handle case where we have fieldingOaa data for the season, but not the position
					  (recordToUpdate.fieldingOaaByPosition[position] = [newStatsPlayerFieldingBasic]);
			}
		});
		return newfieldingOaaRows;
	}, [fieldingOaa, groupedFieldingOaaOpps]);

	// Get non-mobile columns
	const filteredColumns = useMemo(() => {
		const newColumns: Array<TColumn<TFieldingOaaRow, keyof TFieldingOaaRow>> = [...NON_MOBILE_COLUMNS];

		// Whether to include each set of columns - is there at least one entry where innings is not null?
		for (const position in POSITION_TO_COLUMN_MAPPING) {
			const row = fieldingOaaRows.find(
				(fieldingOaaRow: TFieldingOaaRow) =>
					getInningsFromStatsPlayerFielding(fieldingOaaRow.fieldingOaaByPosition[position]) !==
					NULL_FILLER_TEXT
			);
			if (row) newColumns.push(...POSITION_TO_COLUMN_MAPPING[position]);
		}

		if (!columns) return newColumns;
		return newColumns.filter((col: TColumn<TFieldingOaaRow, keyof TFieldingOaaRow>) => columns.includes(col.value));
	}, [fieldingOaaRows, columns]);

	// Get mobile rows
	const fieldingOaaRowsMobile: Array<TFieldingOaaRowMobile> = useMemo(() => {
		const newfieldingOaaRowsMobile: Array<TFieldingOaaRowMobile> = [];
		const combinedOaa = (fieldingOaa?.statsPlayerRangeBylevel ?? []).concat(
			fieldingOaa?.statsPlayerReceivingBylevel ?? []
		);
		combinedOaa?.forEach((fieldingOaaDatum: IStatsPlayerRangeByLevel | IStatsPlayerReceivingByLevel) => {
			const season = fieldingOaaDatum.season;
			const level = fieldingOaaDatum.level;
			const position = fieldingOaaDatum.position;

			const newStatsPlayerFieldingBasic: TStatsPlayerFieldingBasic = {
				opportunities: fieldingOaaDatum.opportunities,
				innings: fieldingOaaDatum.innings,
				fullSeasonScaledOaaCentered: fieldingOaaDatum.fullSeasonScaledOaaCentered,
				opportunitiesPerFullSeason: groupedFieldingOaaOpps[season]
					? groupedFieldingOaaOpps[season][position]
					: null
			};

			const recordToUpdate: TFieldingOaaRowMobile | undefined = newfieldingOaaRowsMobile.find(
				(fieldingOaaRowMobile: TFieldingOaaRowMobile) =>
					fieldingOaaRowMobile.season === season &&
					fieldingOaaRowMobile.level === level &&
					fieldingOaaRowMobile.position === position
			);
			// Handle case where fieldingOaaRows does not have any data for a given season, level, and position
			if (recordToUpdate === undefined)
				newfieldingOaaRowsMobile.push({
					season: season,
					level: level,
					position: position,
					fieldingOaa: [newStatsPlayerFieldingBasic]
				});
			// Handle case where we already have some fieldingOaa data for a season, level, and position
			else recordToUpdate.fieldingOaa.push(newStatsPlayerFieldingBasic);
		});
		return newfieldingOaaRowsMobile;
	}, [fieldingOaa, groupedFieldingOaaOpps]);

	// Get mobile columns
	const filteredColumnsMobile = useMemo(() => {
		const newColumns: Array<TColumn<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>> = [...MOBILE_COLUMNS];

		if (!columnsMobile) return newColumns;
		return newColumns.filter((col: TColumn<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>) =>
			columnsMobile.includes(col.value)
		);
	}, [columnsMobile]);

	const isMobile = useBreakpointValue({
		base: true,
		"2xl": false
	});

	return (
		<VStack align="start">
			<HStack w="100%" justify="start">
				{title && (
					<Box fontFamily="heading" fontSize="md" fontWeight="bold">
						{title}
					</Box>
				)}
			</HStack>
			{isMobile ? (
				<Table<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>
					columns={
						filteredColumnsMobile as Array<TColumn<TFieldingOaaRowMobile, keyof TFieldingOaaRowMobile>>
					}
					data={fieldingOaaRowsMobile}
					isLoadingData={isLoading}
					emptyDataDisplayText={"No Fielding OAA Data"}
					defaultSortColumns={[
						{
							columnValue: "seasonMobile",
							sortDirection: ASC
						},
						{
							columnValue: "levelMobile",
							sortDirection: ASC
						},
						{
							columnValue: "positionMobile",
							sortDirection: ASC
						}
					]}
					{...tablePropsMobile}
				/>
			) : (
				<Table<TFieldingOaaRow, keyof TFieldingOaaRow>
					columns={filteredColumns as Array<TColumn<TFieldingOaaRow, keyof TFieldingOaaRow>>}
					parentColumns={NON_MOBILE_PARENT_COLUMNS}
					data={fieldingOaaRows}
					isLoadingData={isLoading}
					emptyDataDisplayText={"No Fielding OAA Data"}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: ASC
						}
					]}
					{...tableProps}
				/>
			)}
		</VStack>
	);
};

export default FieldingOaaTable;
