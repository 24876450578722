import React from "react";
import { useToast } from "@chakra-ui/react";

import { useSearchParam } from "utils/url_helpers";

import { TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import PlayerPageContent from "_react/playerpage/shared/content/PlayerPageContent";
import PlayerPageProvider from "_react/playerpage/PlayerPageProvider";

const PlayerPage = () => {
	const toast = useToast();
	const philId = useSearchParam("philId") as number | undefined;
	const playerId = useSearchParam("id") as number | undefined;
	const viewClassification = useSearchParam("viewClassification") as TPlayerPagePlayerClassification | undefined;

	return (
		<PlayerPageProvider philId={philId} playerId={playerId} viewClassification={viewClassification} toast={toast}>
			<PlayerPageContent />
		</PlayerPageProvider>
	);
};

export default PlayerPage;
