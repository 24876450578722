import React, { useEffect, useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { VStack, HStack, Box } from "@chakra-ui/react";
import dayjs from "dayjs";

import { PLAYING_LEVEL_AMA } from "_react/shared/data_models/arsenal_scores/_constants";
import { getStuffThresholdPitchTypesToDisplay } from "_react/shared/data_models/arsenal_scores/_helpers";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { BATS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import { STUFF_METRIC_ID } from "_react/shared/data_models/metric/_constants";
import { getMetricRollingAverageRowNumber, getMetricGroupPitchType } from "_react/shared/data_models/metric/_helpers";
import {
	IMetricRollingAverages,
	IMetricRollingAverage,
	IMetricRollingAverageWithPitchType,
	TMetricGroup
} from "_react/shared/data_models/metric/_types";
import { PitchMetricTimeSeriesPlot } from "_react/shared/ui/presentation/plots/PitchMetricTimeSeriesPlot/PitchMetricTimeSeriesPlot";
import { TPitchTypes } from "_react/shared/_types/pitch_types";

import createPitcherMetricOverTimePlotMachine, {
	TPitcherMetricOverTimePlotContext,
	TRollingAverages,
	SET_ROLLING_AVERAGES,
	SET_ARSENAL_SCORES_THRESHOLD,
	SET_PLAYER_SEASON_ARSENAL_SCORES,
	SET_PLAYER_ID,
	SET_METRIC_GROUP,
	SET_SEASON_FILTER,
	FETCHING_ROLLING_AVERAGES,
	FETCHING_ARSENAL_SCORES_THRESHOLD,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES,
	SET_BATS_FILTER,
	SET_THROWS_FILTER,
	SET_PLAYER_CLASSIFICATION
} from "_react/shared/ui/data/plots/PitcherMetricOverTimePlot/_machine";
import {
	METRIC_GROUP_LABEL_MAP,
	METRIC_GROUP_Y_TICK_FORMAT_MAP,
	MONTHS
} from "_react/shared/ui/data/plots/PitcherMetricOverTimePlot/_constants";

export type TPitcherMetricOverTimePrimaryPlotData = {
	rollingAverages: TRollingAverages;
	metricGroup: TMetricGroup;
	isLoading?: boolean;
};

export type TPitcherMetricOverTimeSecondaryPlotData = {
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema>;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	isLoading?: boolean;
};

type TPitcherMetricOverTimePlotStyle = {
	container?: React.CSSProperties;
};

type TPitcherMetricOverTimePlotProps = {
	title?: string;
	playerId?: number;
	playerClassification?: string;
	playingLevel?: string;
	metricGroup: TMetricGroup;
	primaryData?: TPitcherMetricOverTimePrimaryPlotData;
	shouldFetchPrimaryData?: boolean;
	secondaryData?: TPitcherMetricOverTimeSecondaryPlotData;
	shouldFetchSecondaryData?: boolean;
	batsFilter?: string;
	throwsFilter?: string;
	seasonFilter?: number;
	highlightPitchTypes?: Array<TPitchTypes>;
	width?: number;
	style?: TPitcherMetricOverTimePlotStyle;
};

const PitcherMetricOverTimePlot = ({
	title,
	playerId,
	playerClassification,
	playingLevel,
	metricGroup,
	primaryData,
	shouldFetchPrimaryData = true,
	secondaryData,
	shouldFetchSecondaryData = true,
	batsFilter = BATS_OVERALL,
	throwsFilter,
	seasonFilter = dayjs().year(),
	highlightPitchTypes,
	width,
	style
}: TPitcherMetricOverTimePlotProps) => {
	const toast = useToast();
	const [current, send] = useMachine(
		createPitcherMetricOverTimePlotMachine(
			batsFilter,
			throwsFilter,
			seasonFilter,
			playerId,
			playerClassification,
			playingLevel,
			metricGroup,
			shouldFetchPrimaryData,
			primaryData,
			shouldFetchSecondaryData,
			secondaryData,
			toast
		)
	);
	const {
		rollingAverages,
		arsenalScoresThreshold,
		playerSeasonArsenalScores
	} = current.context as TPitcherMetricOverTimePlotContext;

	const fetchingRollingAverages: boolean = current.matches(FETCHING_ROLLING_AVERAGES);
	const isLoadingPrimary = shouldFetchPrimaryData ? fetchingRollingAverages : primaryData?.isLoading;

	const fetchingPlayerSeasonArsenalScores: boolean = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES);
	const fetchingArsenalScoresThreshold: boolean = current.matches(FETCHING_ARSENAL_SCORES_THRESHOLD);
	const isLoadingSecondary = shouldFetchSecondaryData
		? playingLevel === PLAYING_LEVEL_AMA && (fetchingArsenalScoresThreshold || fetchingPlayerSeasonArsenalScores)
		: secondaryData?.isLoading;

	const isLoading = isLoadingPrimary || isLoadingSecondary;

	// Update machine context when data prop changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_PLAYER_CLASSIFICATION, data: playerClassification });
	}, [playerClassification, send]);

	useEffect(() => {
		send({ type: SET_METRIC_GROUP, data: metricGroup });
	}, [metricGroup, send]);

	useEffect(() => {
		send({ type: SET_BATS_FILTER, data: batsFilter });
	}, [batsFilter, send]);

	useEffect(() => {
		send({ type: SET_THROWS_FILTER, data: throwsFilter });
	}, [throwsFilter, send]);

	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	useEffect(() => {
		if (primaryData?.rollingAverages !== rollingAverages && !shouldFetchPrimaryData) {
			send({ type: SET_ROLLING_AVERAGES, data: primaryData?.rollingAverages ?? {} });
		}
	}, [primaryData?.rollingAverages, rollingAverages, shouldFetchPrimaryData, send]);

	useEffect(() => {
		if (secondaryData?.arsenalScoresThreshold !== arsenalScoresThreshold && !shouldFetchSecondaryData) {
			send({ type: SET_ARSENAL_SCORES_THRESHOLD, data: secondaryData?.arsenalScoresThreshold });
		}
	}, [secondaryData?.arsenalScoresThreshold, arsenalScoresThreshold, shouldFetchSecondaryData, send]);

	useEffect(() => {
		if (secondaryData?.playerSeasonArsenalScores !== playerSeasonArsenalScores && !shouldFetchSecondaryData) {
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES, data: secondaryData?.playerSeasonArsenalScores });
		}
	}, [secondaryData?.playerSeasonArsenalScores, playerSeasonArsenalScores, shouldFetchSecondaryData, send]);

	const pitchTypesToDisplay: Array<string> | undefined = useMemo(() => {
		return getStuffThresholdPitchTypesToDisplay(
			batsFilter,
			throwsFilter,
			arsenalScoresThreshold,
			playerSeasonArsenalScores
		);
	}, [playerSeasonArsenalScores, arsenalScoresThreshold, batsFilter, throwsFilter]);

	// Create a single pitch data array from multiple metric rolling averages objects
	const pitchData: undefined | Array<IMetricRollingAverageWithPitchType> = useMemo(() => {
		if (rollingAverages[`${batsFilter}-${throwsFilter}`] == null) return [];
		// This const is specifically for the stuff metric group
		// We need the overall stuff rolling averages "rowNumber" to reference below
		const overallStuffRollingAverages = rollingAverages[`${batsFilter}-${throwsFilter}`]?.find(
			(metricRollingAverages: IMetricRollingAverages) =>
				metricRollingAverages.id === STUFF_METRIC_ID && metricRollingAverages.requestArgs.pitchType === null
		);
		return rollingAverages[`${batsFilter}-${throwsFilter}`]
			?.filter(
				(metricRollingAverages: IMetricRollingAverages) =>
					metricRollingAverages.id !== STUFF_METRIC_ID ||
					(metricRollingAverages.requestArgs.pitchType !== null &&
						(!pitchTypesToDisplay ||
							(metricRollingAverages.requestArgs.pitchType &&
								pitchTypesToDisplay.includes(metricRollingAverages.requestArgs.pitchType))))
			)
			?.reduce(
				(
					combinedRollingAverages: Array<IMetricRollingAverageWithPitchType>,
					metricRollingAverages: IMetricRollingAverages
				) => {
					const rollingAveragesWithPitchType = metricRollingAverages.rollingAverages
						// Filter out the overall stuff grades, since we don't want to show them
						.filter(
							(metricRollingAverage: IMetricRollingAverage) =>
								(metricRollingAverages.id === STUFF_METRIC_ID ||
									metricRollingAverage.rowNumber >= metricRollingAverages.rollingAveragePeriod) &&
								metricRollingAverage.rollingAverage !== null
						)
						.map((metricRollingAverage: IMetricRollingAverage) => {
							return {
								...metricRollingAverage,
								// Pitch type stuff rolling averages have a "rowNumber" that is based on data filtered by pitch type
								// so we need to cross-reference the overall stuff "rowNumber"
								rowNumber: getMetricRollingAverageRowNumber(
									metricRollingAverage,
									overallStuffRollingAverages
								),
								// Get the pitch type of this data point based on the metric
								pitchType: getMetricGroupPitchType(metricRollingAverages)
							} as IMetricRollingAverageWithPitchType;
						});
					return combinedRollingAverages.concat(rollingAveragesWithPitchType);
				},
				[]
			);
	}, [rollingAverages, batsFilter, throwsFilter, pitchTypesToDisplay]);

	const xTickLabels: undefined | Array<{ label: string; value: number }> = useMemo(() => {
		const yearsInDataset = pitchData?.map((rollingAverage: IMetricRollingAverageWithPitchType) =>
			new Date(rollingAverage.date).getFullYear()
		);
		const uniqueYearsInDataset = yearsInDataset ? new Set<number>([...yearsInDataset]) : undefined;
		const labels: Array<{ label: string; value: number }> = [];
		uniqueYearsInDataset?.forEach((year: number) => {
			const averagesFilteredByYear = pitchData?.filter(
				(rollingAverage: IMetricRollingAverageWithPitchType) =>
					new Date(rollingAverage.date).getFullYear() === year
			);
			MONTHS.forEach((month: number) => {
				const averagesFilteredByMonth = averagesFilteredByYear?.filter(
					(rollingAverage: IMetricRollingAverageWithPitchType) =>
						new Date(rollingAverage.date).getUTCMonth() === month - 1
				);
				const minDate = averagesFilteredByMonth?.length
					? averagesFilteredByMonth.reduce(
							(prev: IMetricRollingAverageWithPitchType, curr: IMetricRollingAverageWithPitchType) =>
								prev.rowNumber < curr.rowNumber ? prev : curr
					  )
					: undefined;
				if (minDate) {
					labels.push({
						label:
							uniqueYearsInDataset?.size > 1
								? `${new Date(minDate.date).toLocaleString("default", {
										timeZone: "UTC",
										month: "long"
								  })} ${year}`
								: new Date(minDate.date).toLocaleString("default", { timeZone: "UTC", month: "long" }),
						value: minDate.rowNumber
					});
				}
			});
		});
		return labels.length ? labels : undefined;
	}, [pitchData]);

	return (
		<>
			{isLoading && <Box className="loading-item" height="xs" width="lg" sx={style?.container} />}
			{!isLoading && (
				<VStack align="start" sx={style?.container}>
					<HStack w="100%" justify="start">
						{title && (
							<Box fontFamily="heading" fontSize="md" fontWeight="bold">
								{title}
							</Box>
						)}
					</HStack>
					<PitchMetricTimeSeriesPlot<IMetricRollingAverage, keyof IMetricRollingAverage>
						highlightPitchTypes={highlightPitchTypes}
						pitchData={pitchData}
						xValue="rowNumber"
						yValue="rollingAverage"
						pitchTypeValue="pitchType"
						xLabel={`${
							batsFilter === BATS_OVERALL ? "Overall" : `v${batsFilter}HB`
						} Pitch Count (Current Season)`}
						yLabel={METRIC_GROUP_LABEL_MAP[metricGroup]}
						yTickFormat={METRIC_GROUP_Y_TICK_FORMAT_MAP[metricGroup]}
						isReverseYAxis
						xTickLabels={xTickLabels}
						tooltipValues={{ xValue: "date", pitchType: "pitchType", yValue: "rollingAverage" }}
						width={width}
					/>
				</VStack>
			)}
		</>
	);
};

export default PitcherMetricOverTimePlot;
