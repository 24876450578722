import React, { useMemo, useContext } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "@xstate/react";
import { Box, VStack, HStack } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { getAgeFromBirthDate } from "utils/helpers";
import { useDraftDate } from "_react/_hooks";
import { getAmaAvgGroup } from "_react/playerpage/shared_js/evals/_helpers";
import { MODEL_NAME_V2, PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import { IAmaProspectValue } from "_react/shared/data_models/phred/_types";
import { FORMAT_CURRENCY_MILLIONS, SM } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import { TMinimalGridListRow } from "_react/shared/ui/presentation/components/MinimalGridList/_types";
import MinimalGridList from "_react/shared/ui/presentation/components/MinimalGridList/MinimalGridList";
import SecondaryStat from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import Baseball from "_react/shared/ui/icons/Baseball";
import BaseballBat from "_react/shared/ui/icons/BaseballBat";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getAgentName } from "_react/playerpage/_helpers";
import { AgentValueFlex, MinimalGridListContainerStyle } from "_react/playerpage/_styles";
import { NULL_FILLER_TEXT_PROFILE, SV_COLOR_GRADIENT } from "_react/playerpage/_constants";
import { PlayerPageAmaContext } from "_react/playerpage/ama/PlayerPageAmaProvider";
import { getHasEligibleYearMakeupNote, formatAmaSurplusValue } from "_react/playerpage/ama/shared/_helpers";
import { FETCHING_AMA_PROSPECT_VALUES, TPlayerPageAmaState } from "_react/playerpage/ama/_machine";
import AgentAgencyPopover from "_react/playerpage/shared/profile/AgentAgencyPopover";
import { BaseballBatIconStyle, BaseballIconStyle } from "_react/playerpage/ama/shared/_styles";
import DPLQuestionnairePopover from "_react/playerpage/ama/profile/DPLQuestionnaire/DPLQuestionnairePopover";

type PlayerPageAmaProfileProps = {
	player: TPlayerPageCombinedPlayer;
	evals?: $TSFixMeEval[] | null;
	hasProjectionsPermissions?: boolean;
};

const PlayerPageAmaProfile = ({ player, evals, hasProjectionsPermissions = false }: PlayerPageAmaProfileProps) => {
	const playerPageAmaContext = useContext(PlayerPageAmaContext);
	const draftDate = useDraftDate(player.amaProfile?.eligibleYear ?? undefined);
	const birthDate = player.amaProfile?.birthDate ?? player.birthDate;

	const currentDraftDate: Dayjs | undefined = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.context.currentDraftDate
	);

	const amaProspectValues: Array<IAmaProspectValue> | null | undefined = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.context.amaProspectValues
	);

	const fetchingAmaProspectValues: boolean = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.matches(FETCHING_AMA_PROSPECT_VALUES)
	);

	const currentAmaProspectValueBatter: IAmaProspectValue | undefined = amaProspectValues?.find(
		(value: IAmaProspectValue) =>
			value.r4Year === player.amaProfile?.eligibleYear &&
			value.modelName === MODEL_NAME_V2 &&
			value.playerType === PLAYER_TYPE_BATTER
	);

	const currentAmaProspectValuePitcher: IAmaProspectValue | undefined = amaProspectValues?.find(
		(value: IAmaProspectValue) =>
			value.r4Year === player.amaProfile?.eligibleYear &&
			value.modelName === MODEL_NAME_V2 &&
			value.playerType === PLAYER_TYPE_PITCHER
	);

	const amaInfoData: Array<TMinimalGridListRow> = useMemo(() => {
		return [
			{
				items: [
					{
						label: "Group",
						value: getAmaAvgGroup(evals, currentDraftDate) ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Reports",
						value: evals?.length ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			},
			{
				items: [
					{
						label: "DPL Profile",
						value: (
							<>
								<Box width="min-content" paddingRight="1">
									{player.dplId != null ? "Yes" : "No"}
								</Box>
								{player.dplId != null && (
									<DPLQuestionnairePopover
										playerId={player.id}
										philId={player.playerAmaId}
										dplId={player.dplId}
										shouldFetchData={true}
									/>
								)}
							</>
						)
					},
					{
						label: "Makeup Note",
						value: getHasEligibleYearMakeupNote(player) ? "Yes" : "No"
					}
				]
			},
			{
				items: [
					{
						label: "Eligible Year",
						value: player.amaProfile?.eligibleYear ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Draft Age",
						value: birthDate ? getAgeFromBirthDate(birthDate, draftDate) : NULL_FILLER_TEXT_PROFILE
					}
				]
			}
		];
	}, [player, evals, birthDate, draftDate, currentDraftDate]);

	const bioData: Array<TMinimalGridListRow> = useMemo(() => {
		const org = player.proProfile?.orgCode?.toLowerCase();
		const age = getAgeFromBirthDate(birthDate);
		return [
			{
				items: [
					{
						label: "Agent",
						value: (
							<>
								<Box width="min-content" paddingRight="1">
									{getAgentName(player.amaProfile, NULL_FILLER_TEXT_PROFILE)}
								</Box>
								<AgentValueFlex>
									{player.amaProfile?.agentId && (
										<AgentAgencyPopover
											playerType="ama"
											infoKey="agent"
											infoId={player.amaProfile.agentId}
											org={org}
										/>
									)}
								</AgentValueFlex>
							</>
						)
					},
					{
						label: "Agency",
						value: (
							<>
								<Box width="min-content" paddingRight="1">
									{player.amaProfile?.agent?.agency?.agencyName ?? NULL_FILLER_TEXT_PROFILE}
								</Box>
								<AgentValueFlex>
									{player.amaProfile?.agent?.agencyId && (
										<AgentAgencyPopover
											playerType="ama"
											infoKey="agency"
											infoId={player.amaProfile.agent.agencyId}
											org={org}
										/>
									)}
								</AgentValueFlex>
							</>
						)
					}
				]
			},
			{
				items: [
					{
						label: "Scout Assigned",
						value:
							player.scoutAssigned?.firstName || player.scoutAssigned?.lastName
								? `${player.scoutAssigned?.firstName ?? ""} ${player.scoutAssigned?.lastName ?? ""}`
								: NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "DOB (Age)",
						value:
							birthDate && birthDate != null
								? `${dayjs(birthDate).format("M/D/YYYY")} (${age})`
								: NULL_FILLER_TEXT_PROFILE
					}
				]
			}
		];
	}, [player, birthDate]);

	const idData: Array<TMinimalGridListRow> = useMemo(() => {
		return [
			{
				items: [
					{
						label: "Combined ID",
						value: player.id ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Phil ID (AMA)",
						value: player.playerAmaId ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			},
			{
				items: [
					{
						label: "Collegesplits ID",
						value: player.collegesplitsId ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Trackman ID",
						value: player.trackmanId ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			},
			{
				items: [
					{
						label: "DPL ID",
						value: player.dplId ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "eBIS ID",
						value: player.ebisId ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			}
		];
	}, [player]);

	const topMinimalGridListContainerStyle = useBreakpointValue({
		base: { paddingTop: "0" },
		md: undefined
	});

	return (
		<VStack alignItems="start" paddingLeft="12" paddingRight="12" paddingTop="6" paddingBottom="6" gap="0">
			{hasProjectionsPermissions && (
				<HStack spacing="4" paddingBottom="3">
					{fetchingAmaProspectValues && (
						<SecondaryStat
							label={"SV"}
							nullFillerText={NULL_FILLER_TEXT_PROFILE}
							format={FORMAT_CURRENCY_MILLIONS}
							colorGradientInfo={SV_COLOR_GRADIENT}
							size={SM}
							isLoading
						/>
					)}
					{currentAmaProspectValueBatter !== undefined && (
						<SecondaryStat
							label={"SV"}
							icon={
								currentAmaProspectValuePitcher !== undefined ? (
									<BaseballBat sx={BaseballBatIconStyle} />
								) : (
									undefined
								)
							}
							value={formatAmaSurplusValue(currentAmaProspectValueBatter)}
							nullFillerText={NULL_FILLER_TEXT_PROFILE}
							format={FORMAT_CURRENCY_MILLIONS}
							colorGradientInfo={SV_COLOR_GRADIENT}
							size={SM}
							isLoading={fetchingAmaProspectValues}
						/>
					)}
					{currentAmaProspectValuePitcher !== undefined && (
						<SecondaryStat
							label={"SV"}
							icon={
								currentAmaProspectValueBatter !== undefined ? (
									<Baseball sx={BaseballIconStyle} />
								) : (
									undefined
								)
							}
							value={formatAmaSurplusValue(currentAmaProspectValuePitcher)}
							nullFillerText={NULL_FILLER_TEXT_PROFILE}
							format={FORMAT_CURRENCY_MILLIONS}
							colorGradientInfo={SV_COLOR_GRADIENT}
							size={SM}
							isLoading={fetchingAmaProspectValues}
						/>
					)}
				</HStack>
			)}
			<MinimalGridList
				label="Amateur Information"
				data={amaInfoData}
				style={{
					container: hasProjectionsPermissions
						? {
								...MinimalGridListContainerStyle,
								...topMinimalGridListContainerStyle
						  }
						: {
								paddingTop: "0"
						  }
				}}
			/>
			<MinimalGridList
				label="Bio"
				data={bioData}
				style={{
					container: MinimalGridListContainerStyle
				}}
			/>
			<MinimalGridList
				label="System Information"
				data={idData}
				style={{
					container: MinimalGridListContainerStyle
				}}
			/>
		</VStack>
	);
};

export default PlayerPageAmaProfile;
