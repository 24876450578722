import { PHILLIES_TEAM_ID } from "_react/shared/_constants/ids";
import { AMA, PRO } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import { IAmaMedicalPlayer } from "_react/shared/data_models/ama_medical/_types";
import {
	IAmaProspectValuePlayerPlayer,
	IAmaProspectValueLeaderboardPlayer
} from "_react/shared/data_models/phred/_types";

// Player Name utils

export const getPlayerFullNameFromPlayerProps = (
	firstName?: string | null,
	firstNameLegal?: string | null,
	lastName?: string | null,
	lastNameExtended?: string | null,
	amaFirstName?: string | null,
	amaLastName?: string | null,
	hasLastNameFirst = true,
	playerClassification?: TPlayerPagePlayerClassification
): string => {
	const combinedFirstName = firstName ?? firstNameLegal ?? "";
	const combinedLastName = lastName ?? lastNameExtended ?? "";

	if (playerClassification === AMA) {
		if (hasLastNameFirst) return `${amaLastName ?? combinedLastName}, ${amaFirstName ?? combinedFirstName}`;
		return `${amaFirstName ?? combinedFirstName} ${amaLastName ?? combinedLastName}`;
	}

	if (hasLastNameFirst) return `${combinedLastName}, ${combinedFirstName}`;
	return `${combinedFirstName} ${combinedLastName}`;
};

export const getPlayerFullName = (
	player?: TPlayerPageCombinedPlayer | IAmaMedicalPlayer | null,
	hasLastNameFirst = true,
	// If set to AMA, defaults to AMA name instead of combined name
	playerClassification?: TPlayerPagePlayerClassification
) => {
	if (!player) return "";

	return getPlayerFullNameFromPlayerProps(
		player.firstName,
		player.firstNameLegal,
		player.lastName,
		player.lastNameExtended,
		player.amaProfile?.firstName,
		player.amaProfile?.lastName,
		hasLastNameFirst,
		playerClassification
	);
};

export const getPlayerNameAndUniformNumber = (
	player?: TPlayerPageCombinedPlayer | null,
	playerClassification: TPlayerPagePlayerClassification = PRO
) => {
	if (player == null) return "";
	const playerFullName = getPlayerFullName(player, false, playerClassification);
	return `${playerFullName} ${player?.uniformNumber ? `#${player?.uniformNumber}` : ""}`;
};

// Position utils

export const getAmaPosition = (player?: TPlayerPageCombinedPlayer | IAmaMedicalPlayer | null) => {
	return player?.amaProfile?.position ?? player?.position;
};

// Image utils

export const getMajorLeagueHeadshot = (bamId?: number | null): string => {
	// This URL customizes the headshot picture
	// If you want an uncustomized version use https://img.mlbstatic.com/mlb/images/players/head_shot/[playerId].jpg
	return `https://img.mlbstatic.com/mlb-photos/image/upload/d_people:generic:headshot:silo:current.png,q_auto:best,f_auto/v1/people/${bamId}/headshot/silo/current`;
};

export const getMinorLeagueHeadshot = (bamId?: number | null): string => {
	return `https://www.milb.com/images/${bamId}/generic/180x270/${bamId}.jpg`;
};

export const getAmaHeadshot = (dplId: number): string => {
	return `https://storage.cloud.google.com/phillies-uploader/mlb/draft-prospect-headshots/${dplId}.png`;
};

// Roster utils

export const isOptionable = (
	optionsTotal: number,
	optionsUsed: number,
	optionsUsedCurrentSeason: number,
	mlsCurrent: number,
	parentOrgId?: number | null,
	hasBeenInPhiOrgCurrentSeason?: boolean | null
) => {
	// Logic from Corinne/MLB as of 4/17/24 for optionable
	// 1. Started the year with an option remaining (NOTE: these values do not update until the end of the season)
	// 2. Have less than 5 years of service
	// 3a. Have a current season option remaining OR
	// 3b.
	// Have used his 5th+ current season option
	// Be assigned to a different club
	// Not have been previously assigned to our organization in the current season
	const isNotOptionable =
		optionsTotal - optionsUsed === 0 ||
		mlsCurrent >= 5 ||
		(optionsUsedCurrentSeason >= 5 && (parentOrgId === PHILLIES_TEAM_ID || hasBeenInPhiOrgCurrentSeason));
	return !isNotOptionable;
};

// Draft utils

export const getMostRecentDraftPick = (player: IAmaProspectValuePlayerPlayer | IAmaProspectValueLeaderboardPlayer) => {
	if (player.draftPicks && player.draftPicks.length > 0) {
		const sortedPicks = player.draftPicks.sort((a, b) => (b.r4Year ?? 0) - (a.r4Year ?? 0));
		const mostRecentDraft = sortedPicks[0];
		return mostRecentDraft;
	}
	return null;
};
