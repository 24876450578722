import React, { useEffect, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { useToast, VStack, HStack, Box } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { POSITION_GROUP_POSITION_PLAYER } from "_react/shared/_constants/position_groups";
import { getNonDiscretePositionGroup } from "_react/shared/_helpers/position_groups";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC } from "_react/shared/ui/presentation/components/Table/_constants";
import { IFieldingProjectionsBlend } from "_react/shared/data_models/defensive_projections/_types";
import {
	IProjectedPositionalProportions,
	IProjectedPitcherProportions
} from "_react/shared/data_models/projections/_types";
import { useProjectionsSeason } from "_react/shared/_helpers/hooks";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";

import FieldingProjectionsTableMachine, {
	TFieldingProjectionsTableContext,
	FETCHING_FIELDING_PROJECTIONS,
	FETCHING_PROJECTED_POSITIONAL_PROPORTIONS,
	FETCHING_PROJECTED_PITCHER_PROPORTIONS,
	FETCHING_PLAYER,
	SET_FIELDING_PROJECTIONS,
	SET_PLAYER_ID,
	SET_PROJECTED_POSITIONAL_PROPORTIONS,
	SET_PROJECTED_PITCHER_PROPORTIONS,
	SET_PLAYER,
	SET_IS_EXPANDED
} from "_react/shared/ui/data/tables/FieldingProjectionsTable/_machine";

import {
	DEFAULT_NUM_SEASONS,
	SEASON_COLUMN,
	FIELDING_COLUMNS,
	PITCHING_COLUMNS,
	PARENT_COLUMNS
} from "_react/shared/ui/data/tables/FieldingProjectionsTable/_constants";
import {
	TFieldingProjectionsRow,
	TFieldingProjectionsTablePlayer
} from "_react/shared/ui/data/tables/FieldingProjectionsTable/_types";
import {
	checkIfFieldingProjectionsExist,
	checkIfPitchingProjectionsExist,
	getTotalRaaColumn
} from "_react/shared/ui/data/tables/FieldingProjectionsTable/_helpers";

export type TFieldingProjectionsTableData = {
	fieldingProjections?: Array<IFieldingProjectionsBlend> | null;
	projectedPositionalProportions?: Array<IProjectedPositionalProportions> | null;
	projectedPitcherProportions?: Array<IProjectedPitcherProportions> | null;
	player?: TFieldingProjectionsTablePlayer | null;
	isLoading?: boolean;
};

type TFieldingProjectionsTableProps = {
	title?: string;
	playerId?: number;
	data?: TFieldingProjectionsTableData;
	shouldFetchData?: boolean;
	columns?: Array<string>;
	minSeason?: number | null;
	maxSeason?: number | null;
	tableProps?: TTableProps<TFieldingProjectionsRow, keyof TFieldingProjectionsRow>;
};

const FieldingProjectionsTable = ({
	title,
	playerId,
	data,
	shouldFetchData = true,
	columns,
	minSeason: minSeasonProp,
	maxSeason: maxSeasonProp,
	tableProps
}: TFieldingProjectionsTableProps) => {
	const toast = useToast();
	const [projectionsSeason, _ros, isFetchingProjectionsSeason] = useProjectionsSeason();
	const minSeason = minSeasonProp ?? projectionsSeason;
	const maxSeason = maxSeasonProp ?? projectionsSeason + DEFAULT_NUM_SEASONS - 1;

	const [current, send] = useMachine(FieldingProjectionsTableMachine(playerId, data, shouldFetchData, toast));
	const context = current.context;
	const {
		fieldingProjections,
		projectedPositionalProportions,
		projectedPitcherProportions,
		player,
		isExpanded
	} = context as TFieldingProjectionsTableContext;

	const isFetchingFieldingProjections = current.matches(FETCHING_FIELDING_PROJECTIONS);
	const isFetchingProjectedPositionalProportions = current.matches(FETCHING_PROJECTED_POSITIONAL_PROPORTIONS);
	const isFetchingProjectedPitcherProportions = current.matches(FETCHING_PROJECTED_PITCHER_PROPORTIONS);
	const isFetchingPlayer = current.matches(FETCHING_PLAYER);
	const isLoading = shouldFetchData
		? isFetchingFieldingProjections ||
		  isFetchingProjectedPositionalProportions ||
		  isFetchingProjectedPitcherProportions ||
		  isFetchingPlayer ||
		  isFetchingProjectionsSeason
		: data?.isLoading;

	// Update machine context when props change
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.fieldingProjections !== fieldingProjections && !shouldFetchData) {
			send({ type: SET_FIELDING_PROJECTIONS, data: data?.fieldingProjections ?? undefined });
		}
	}, [data?.fieldingProjections, shouldFetchData, send, fieldingProjections]);

	useEffect(() => {
		if (data?.projectedPositionalProportions !== projectedPositionalProportions && !shouldFetchData) {
			send({
				type: SET_PROJECTED_POSITIONAL_PROPORTIONS,
				data: data?.projectedPositionalProportions ?? undefined
			});
		}
	}, [data?.projectedPositionalProportions, shouldFetchData, send, projectedPositionalProportions]);
	useEffect(() => {
		if (data?.projectedPitcherProportions !== projectedPitcherProportions && !shouldFetchData) {
			send({ type: SET_PROJECTED_PITCHER_PROPORTIONS, data: data?.projectedPitcherProportions ?? undefined });
		}
	}, [data?.projectedPitcherProportions, shouldFetchData, send, projectedPitcherProportions]);

	useEffect(() => {
		if (data?.player !== player && !shouldFetchData) {
			send({ type: SET_PLAYER, data: data?.player ?? undefined });
		}
	}, [data?.player, shouldFetchData, send, player]);

	// Table local state management
	const setIsExpanded = (value: boolean) => {
		send({ type: SET_IS_EXPANDED, data: value });
	};

	// Group projections by season
	const fieldingProjectionsBySeason = useMemo(() => {
		const newFieldingProjections: Array<TFieldingProjectionsRow> = [];
		fieldingProjections?.forEach((fProj: IFieldingProjectionsBlend) => {
			const season = fProj.season;
			if (!season || season < minSeason || season > maxSeason) return;
			const projPitcherProjections = projectedPitcherProportions?.find(
				(proj: IProjectedPitcherProportions) => proj.projectedSeason === season
			);
			const projectedPositionalProportion = projectedPositionalProportions?.find(
				(proj: IProjectedPositionalProportions) => proj.season === season
			);
			newFieldingProjections.push({
				season: season,
				fieldingProjectionsBlend: fProj,
				projectedPositionalProportion: projectedPositionalProportion,
				projectedPitcherProportion: projPitcherProjections
			});
		});
		return newFieldingProjections;
	}, [projectedPitcherProportions, fieldingProjections, projectedPositionalProportions, minSeason, maxSeason]);

	const isPitcherPositionGroup = useMemo(() => {
		return (
			getNonDiscretePositionGroup(
				player ? player["proProfile.ProjectedPositionGroup"] : undefined,
				player?.position
			) !== POSITION_GROUP_POSITION_PLAYER
		);
	}, [player]);

	// Display the correct columns based on existing projections and the columns prop
	const filteredColumns = useMemo(() => {
		const totalRaaColumn = getTotalRaaColumn(isPitcherPositionGroup);
		const newColumns: Array<TColumn<TFieldingProjectionsRow, keyof TFieldingProjectionsRow>> = [
			SEASON_COLUMN,
			totalRaaColumn
		];
		if (checkIfFieldingProjectionsExist(fieldingProjections) && !isPitcherPositionGroup)
			newColumns.push(...FIELDING_COLUMNS(isExpanded));
		// These are not currently displaying on Rocky pro player pages
		if (checkIfPitchingProjectionsExist(fieldingProjections) && isPitcherPositionGroup)
			newColumns.push(...PITCHING_COLUMNS);
		if (!columns) return newColumns;
		return newColumns.filter((col: TColumn<TFieldingProjectionsRow, keyof TFieldingProjectionsRow>) =>
			columns.includes(col.value)
		);
	}, [fieldingProjections, isPitcherPositionGroup, columns, isExpanded]);

	const isMobile = useBreakpointValue({
		base: true,
		"2xl": isExpanded
	});

	return (
		<VStack align="start">
			<HStack w="100%" justify="space-between">
				{title && (
					<Box fontFamily="heading" fontSize="md" fontWeight="bold">
						{title}
					</Box>
				)}
				<ToggleButton<boolean>
					toggleOptions={[
						{ value: false, label: "Total RAA" },
						{ value: true, label: "Component RAA" }
					]}
					value={isExpanded}
					onSelect={setIsExpanded}
					isDisabled={isLoading}
				/>
			</HStack>
			<Table<TFieldingProjectionsRow, keyof TFieldingProjectionsRow>
				columns={filteredColumns as Array<TColumn<TFieldingProjectionsRow, keyof TFieldingProjectionsRow>>}
				parentColumns={PARENT_COLUMNS}
				data={fieldingProjectionsBySeason}
				isLoadingData={isLoading}
				isMobile={isMobile}
				emptyDataDisplayText={"No Fielding Projections"}
				defaultSortColumns={[
					{
						columnValue: "season",
						sortDirection: ASC
					}
				]}
				{...tableProps}
			/>
		</VStack>
	);
};

export default FieldingProjectionsTable;
