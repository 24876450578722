import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchPlayerMetricRollingAverages } from "_react/shared/data_models/metric/_network";
import { getCancelSource } from "utils/url_helpers";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { IMetricRollingAverages } from "_react/shared/data_models/metric/_types";
import { PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { getMetricGroupPitchType } from "_react/shared/data_models/metric/_helpers";

import { TPlayerPitchTypeLabelsData } from "_react/shared/ui/data/other/PlayerPitchTypeLabels/PlayerPitchTypeLabels";

const PITCH_TYPES_CANCEL_SOURCE = "pitchTypes";

export type TPlayerPitchTypeLabelsCancelSource = {
	[PITCH_TYPES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPlayerPitchTypeLabelsContext = {
	seasonFilter: number;
	lastSeasonFilter: number;
	playerId?: number;
	lastPlayerId?: number;
	playerClassification?: string;
	lastPlayerClassification?: string;
	shouldFetchData?: boolean;
	pitchTypes?: Array<TPitchTypes> | null;
	cancelSources: TPlayerPitchTypeLabelsCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPlayerPitchTypeLabelsStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches player's pitch data data
				pitchTypes: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PITCH_TYPES = "SET_PITCH_TYPES";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const SET_PLAYER_CLASSIFICATION = "SET_PLAYER_CLASSIFICATION";
export const FETCHING_PITCH_TYPES = { initialized: { pitchTypes: "fetching" } };

const FETCH_PITCH_TYPES_DONE = "done.invoke.fetchingPitchTypes:invocation[0]";

type TSetPitchTypesEvent = {
	type: typeof SET_PITCH_TYPES;
	data: Array<TPitchTypes> | null | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetPlayerClassificationEvent = {
	type: typeof SET_PLAYER_CLASSIFICATION;
	data: string | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TFetchPitchTypesEvent = {
	type: typeof FETCH_PITCH_TYPES_DONE;
	data?: Array<IMetricRollingAverages>;
};

type TPlayerPitchTypeLabelsEvent =
	| TSetPitchTypesEvent
	| TSetPlayerIdEvent
	| TSetPlayerClassificationEvent
	| TSetSeasonFilterEvent
	| TFetchPitchTypesEvent;

export type TPlayerPitchTypeLabelsSend = Interpreter<
	TPlayerPitchTypeLabelsContext,
	IPlayerPitchTypeLabelsStateSchema,
	TPlayerPitchTypeLabelsEvent
>["send"];

const PlayerPitchTypeLabelsMachine = (
	seasonFilterProp: number,
	playerIdProp?: number,
	playerClassificationProp?: string,
	shouldFetchData = true,
	data?: TPlayerPitchTypeLabelsData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPlayerPitchTypeLabelsContext, IPlayerPitchTypeLabelsStateSchema, TPlayerPitchTypeLabelsEvent>(
		{
			id: "PlayerPitchTypeLabels",
			initial: "initializing",
			context: {
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				playerClassification: playerClassificationProp,
				lastPlayerClassification: playerClassificationProp,
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				pitchTypes: data?.pitchTypes,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PITCH_TYPES]: { actions: "setPitchTypes" },
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_PLAYER_CLASSIFICATION]: { actions: "setPlayerClassification" },
						[SET_SEASON_FILTER]: { actions: "setSeasonFilter" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						pitchTypes: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPitchTypes",
												cond: "shouldFetchPitchTypes"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPitchTypes",
									entry: ["refreshPitchTypesCancelSource"],
									invoke: {
										src: "fetchPitchTypes",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPitchTypesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPitchTypesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TPlayerPitchTypeLabelsContext, _event: TPlayerPitchTypeLabelsEvent) =>
					context.playerId !== context.lastPlayerId ||
					context.seasonFilter !== context.lastSeasonFilter ||
					context.playerClassification !== context.lastPlayerClassification,
				shouldFetchPitchTypes: (context: TPlayerPitchTypeLabelsContext, _event: TPlayerPitchTypeLabelsEvent) =>
					context.pitchTypes === undefined && shouldFetchData && context.playerId !== undefined
			},
			actions: {
				setPitchTypes: assign<TPlayerPitchTypeLabelsContext, TPlayerPitchTypeLabelsEvent>({
					pitchTypes: (context: TPlayerPitchTypeLabelsContext, event: TPlayerPitchTypeLabelsEvent) => {
						if (event.type !== SET_PITCH_TYPES) return context.pitchTypes;
						return event.data;
					},
					cancelSources: (context: TPlayerPitchTypeLabelsContext, event: TPlayerPitchTypeLabelsEvent) => {
						if (event.type !== SET_PITCH_TYPES) return context.cancelSources;
						if (context.cancelSources[PITCH_TYPES_CANCEL_SOURCE] != null)
							context.cancelSources[PITCH_TYPES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PITCH_TYPES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TPlayerPitchTypeLabelsContext, TPlayerPitchTypeLabelsEvent>({
					playerId: (context: TPlayerPitchTypeLabelsContext, event: TPlayerPitchTypeLabelsEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setPlayerClassification: assign<TPlayerPitchTypeLabelsContext, TPlayerPitchTypeLabelsEvent>({
					playerClassification: (
						context: TPlayerPitchTypeLabelsContext,
						event: TPlayerPitchTypeLabelsEvent
					) => {
						if (event.type !== SET_PLAYER_CLASSIFICATION) return context.playerClassification;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TPlayerPitchTypeLabelsContext, TPlayerPitchTypeLabelsEvent>({
					seasonFilter: (context: TPlayerPitchTypeLabelsContext, event: TPlayerPitchTypeLabelsEvent) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				clearContext: assign<TPlayerPitchTypeLabelsContext, TPlayerPitchTypeLabelsEvent>({
					lastSeasonFilter: (context: TPlayerPitchTypeLabelsContext, _event: TPlayerPitchTypeLabelsEvent) =>
						context.seasonFilter,
					lastPlayerClassification: (
						context: TPlayerPitchTypeLabelsContext,
						_event: TPlayerPitchTypeLabelsEvent
					) => context.playerClassification,
					lastPlayerId: (context: TPlayerPitchTypeLabelsContext, _event: TPlayerPitchTypeLabelsEvent) =>
						context.playerId,
					pitchTypes: (_context: TPlayerPitchTypeLabelsContext, _event: TPlayerPitchTypeLabelsEvent) =>
						undefined,
					cancelSources: (context: TPlayerPitchTypeLabelsContext, _event: TPlayerPitchTypeLabelsEvent) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshPitchTypesCancelSource: assign<TPlayerPitchTypeLabelsContext, TPlayerPitchTypeLabelsEvent>({
					cancelSources: (context: TPlayerPitchTypeLabelsContext, _event: TPlayerPitchTypeLabelsEvent) => {
						if (context.cancelSources[PITCH_TYPES_CANCEL_SOURCE] != null)
							context.cancelSources[PITCH_TYPES_CANCEL_SOURCE].cancel();
						context.cancelSources[PITCH_TYPES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPitchTypesSuccess: assign<TPlayerPitchTypeLabelsContext, TPlayerPitchTypeLabelsEvent>({
					pitchTypes: (context: TPlayerPitchTypeLabelsContext, event: TPlayerPitchTypeLabelsEvent) => {
						if (event.type !== FETCH_PITCH_TYPES_DONE) return context.pitchTypes;
						// Filter out pitch types that do not match our list of pitch types
						// Return just the pitch type values
						return event.data
							?.map((metricRollingAverages: IMetricRollingAverages) =>
								getMetricGroupPitchType(metricRollingAverages)
							)
							?.filter((pitchType: string | undefined) =>
								pitchType ? PITCH_TYPES.includes(pitchType as TPitchTypes) : false
							) as Array<TPitchTypes>;
					}
				}),
				// Fetch Errored Actions
				handleFetchPitchTypesErrored: (
					context: TPlayerPitchTypeLabelsContext,
					_event: TPlayerPitchTypeLabelsEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Seasonal Arsenal Scores Overall",
							description: "Error fetching seasonal arsenal scores overall data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchPitchTypes: (context: TPlayerPitchTypeLabelsContext, _event: AnyEventObject) => {
					const { playerId, playerClassification, seasonFilter } = context;
					if (playerId === undefined) return Promise.resolve(undefined);
					// We should be using shared/network/player#fetchPlayerPitchTypes
					// but there are inconsistencies in how we pull pitch types across different data
					// so use the pitch usage over time data to determine pitch types until QAs come up with single method
					const fetchFunc = () =>
						fetchPlayerMetricRollingAverages(
							{
								playerId: playerId,
								playerClassification: playerClassification,
								metricGroup: "pitch_usage",
								season: seasonFilter,
								versionArsenalScores: playerClassification === "AMA" ? "v3" : undefined,
								isUseCache: true
							},
							context.cancelSources[PITCH_TYPES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PlayerPitchTypeLabelsMachine;
