import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { colorToRGBA } from "utils/color";
import { $TSAnyRequired } from "utils/tsutils";
import { PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { KeysOfValue } from "_react/shared/_types/generics";
import { TPitchTypes } from "_react/shared/_types/pitch_types";

import {
	DATA_COLOR_SECONDARY_RGB,
	DATA_COLOR_PRIMARY_GRAY_RGB,
	HIGHCHARTS_THEME
} from "_react/shared/dataviz/_constants";
import { useDataVizColors } from "_react/shared/dataviz/_hooks";

(Highcharts as $TSAnyRequired).setOptions(HIGHCHARTS_THEME);

type TPitchUsageDistributionPlotProps<T extends object> = {
	highlightPitchTypes?: Array<TPitchTypes>;
	pitchData?: Array<T>;
	xValue: KeysOfValue<T, string>;
	getXValueFunction?: (obj: T) => string;
	xLabel?: string;
	yValue: KeysOfValue<T, number | null>;
	getYValueFunction?: (obj: T) => number;
	yLabel?: string;
	// TODO: Need to decide how we want to manage breakpoints
	width?: number;
	height?: number;
};

const PitchUsageDistributionPlot = <T extends object>({
	highlightPitchTypes,
	pitchData,
	xValue,
	getXValueFunction,
	xLabel,
	yValue,
	getYValueFunction,
	yLabel,
	width,
	height
}: TPitchUsageDistributionPlotProps<T>) => {
	const { pitchTypeColorDict } = useDataVizColors();

	const getXValue = (datum: T) =>
		getXValueFunction ? getXValueFunction(datum) : ((datum[xValue] as unknown) as string);
	const getYValue = (datum: T) => (getYValueFunction ? getYValueFunction(datum) : datum[yValue]) as number | null;

	const pitchTotal =
		pitchData?.reduce((accumulator: number, currentValue: T) => {
			const currentYValue = getYValue(currentValue) ?? 0;
			return accumulator + currentYValue;
		}, 0) ?? 0;
	const pitchDataSorted = pitchData
		? [...pitchData].sort((pitchTypeA, pitchTypeB) => (getYValue(pitchTypeB) ?? 0) - (getYValue(pitchTypeA) ?? 0))
		: undefined;
	const parsedPitchData = pitchDataSorted?.map((pitchType: T, index: number) => {
		const isHighlighted = (highlightPitchTypes as Array<string>)?.includes(getXValue(pitchType));
		const barDict = {
			x: index,
			y: Math.round(((getYValue(pitchType) ?? 0) / pitchTotal) * 100 * 10) / 10,
			name: getXValue(pitchType),
			color: isHighlighted
				? pitchTypeColorDict[getXValue(pitchType) as TPitchTypes]
				: colorToRGBA(DATA_COLOR_SECONDARY_RGB, 0.5),
			dataLabels: { style: { fontWeight: isHighlighted ? "bold" : "lighter" } },
			states: {
				hover: {
					color: (PITCH_TYPES as Array<string>).includes(getXValue(pitchType))
						? pitchTypeColorDict[getXValue(pitchType) as TPitchTypes]
						: DATA_COLOR_PRIMARY_GRAY_RGB
				}
			}
		};
		return barDict;
	});

	const options: Highcharts.Options = {
		// Required so that Highcharts logo doesn't show up
		credits: { enabled: false },
		chart: {
			height: height ?? undefined,
			width: width ?? undefined
		},
		// Required to ensure a default chart title doesn't display
		title: {
			text: undefined
		},
		legend: { enabled: false },
		tooltip: { enabled: false },
		xAxis: {
			title: { text: xLabel },
			// Labels underneath bars
			categories: parsedPitchData?.map(
				(pitchType: { x: number; y: number; name: string; color: string }) => pitchType.name
			)
		},
		yAxis: {
			visible: yLabel ? true : false,
			title: { text: yLabel }
		},
		plotOptions: {
			column: {
				// Labels on top of bars
				dataLabels: {
					enabled: true,
					format: "{y}%",
					crop: false,
					overflow: "allow"
				},
				pointPadding: 0,
				groupPadding: 0.1,
				pointWidth: 30
			}
		},
		series: [
			{
				type: "column",
				name: "Pitch Type Usage",
				data: parsedPitchData
			}
		]
	};
	return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PitchUsageDistributionPlot;
