import React, { useCallback, useMemo } from "react";
import Select, { Props } from "react-select";
import { sortBy } from "utils/helpers";
import { useLks } from "_react/inputs/lks/LkContext";
import { DEFAULT_STYLES, getOptsFromValues } from "_react/inputs/_helpers";
import { isOptionArr, TExtendedOption, TOptions, TOption } from "_react/inputs/_types";
import { TLk, TLkFilter, TLkValueConstraint, filterLkItems, isLkKey } from "_react/inputs";
import { T_DBs } from "utils/tsutils";

export type TLkOption<T extends TLkValueConstraint> = TExtendedOption<T>;

interface TLkSelectWrapperProps<T extends TLkValueConstraint> extends Props<TLkOption<T>> {
	abbreviate?: boolean;
	groupingCol?: string; // TODO: this should be a key of the Lk type of something
	lkName: string;
	lkFilters?: TLkFilter<string | number>[];
	valueOnlyValue?: T | T[] | null;
	manageInitialOptions?: (lkName: string, selectedFilters: TLkOption<T>[]) => void;
	dbName?: T_DBs;
	forDisplay?: boolean; // Indicates if this should show a select or just the value (so the data source is still managed here)
	displayStyle?: React.CSSProperties;
}

type TGroupOpt<T extends TLkValueConstraint> = { label: string; options: TLk<T>[] };

export function LkSelectWrapper<T extends TLkValueConstraint>({
	abbreviate,
	lkName,
	lkFilters = [],
	groupingCol,
	valueOnlyValue,
	manageInitialOptions,
	value: valueProp,
	dbName = "phil_data",
	forDisplay = false,
	displayStyle,
	...props
}: TLkSelectWrapperProps<T>) {
	const [optionsRaw, isFetching] = useLks<T>(lkName, dbName);

	const optionsFilteredSorted = useMemo(() => {
		const filteredOptions = filterLkItems<T>(optionsRaw, lkFilters);
		return sortBy(filteredOptions, [(opt: TLkOption<T>) => opt.sortOrder]) as TLk<T>[];
	}, [optionsRaw, lkFilters]);

	const options = useMemo(() => {
		if (groupingCol == null) return optionsFilteredSorted;
		const groups: { [groupKey: string]: TGroupOpt<T> } = {};
		optionsFilteredSorted.forEach(option => {
			if (isLkKey<T>(groupingCol, option)) {
				const groupingColVal = option[groupingCol];
				if (typeof groupingColVal === "string") {
					if (!groups.hasOwnProperty(groupingColVal)) {
						groups[groupingColVal] = { label: groupingColVal, options: [] };
					}
					groups[groupingColVal].options.push(option);
				}
			}
		});
		return Object.values(groups);
	}, [optionsFilteredSorted, groupingCol]);

	const value = useMemo(() => {
		if (valueOnlyValue === undefined) return valueProp;
		const selectedValues = getOptsFromValues<T>(valueOnlyValue, options as TOptions<TOption<T>>);
		if (manageInitialOptions && isOptionArr(selectedValues, "string")) {
			manageInitialOptions(lkName, selectedValues);
		}
		return selectedValues;
	}, [valueOnlyValue, valueProp, options, lkName, manageInitialOptions]);

	const styles = useMemo(() => {
		if (props.styles == null) return DEFAULT_STYLES;
		return {
			...DEFAULT_STYLES,
			...props.styles
		};
	}, [props.styles]);

	const getOptionLabel = useCallback(
		(option: TLk<T>) => (abbreviate ? option.abbreviation || option.label : option.label) || "",
		[abbreviate]
	);

	if (forDisplay)
		return <span style={displayStyle}>{value ? getOptionLabel(value as TLk<T>) : valueOnlyValue ?? ""}</span>;

	return (
		<Select<TLkOption<T>, boolean>
			{...props}
			isLoading={isFetching}
			getOptionLabel={getOptionLabel}
			value={value}
			options={options}
			styles={styles}
		/>
	);
}

export default LkSelectWrapper;
