import React, { useEffect, useState } from "react";
import { useQueryString } from "utils/url_helpers";
import Modal from "_react/shared/legacy/ui/Modal";
import axios from "_redux/_utils/_axios";
import { getCheckbox } from "_react/dpl/_helpers";
import { ButtonStyle, TextContainer } from "_react/dpl/_styles";
import { TSurvey, TSurveyType, TSurveyMetaData } from "_react/dpl/_types";
import Survey from "_react/dpl/Survey";
import { Button } from "_react/shared/legacy/ui/Button";
import { Menu } from "_react/shared/legacy/ui/Menu";
import { MenuItem } from "_react/shared/legacy/ui/MenuItem";

type TSurveyButtonProps = {
	philId: number | null;
	sType: TSurveyType;
	height: string;
	width: string;
};

export default function SurveyButton({ philId, sType }: TSurveyButtonProps) {
	const [viewingDPLSurvey, setDPLSurvey] = useQueryString("DPLSurvey");
	const [DPLSurveyID, setDPLSurveyID] = useQueryString("DPLSurveyId");
	const DPLSurveyIdentity = `${sType}`;
	const viewing = viewingDPLSurvey === DPLSurveyIdentity;
	const setViewing = (shouldView: boolean) => {
		if (shouldView) setDPLSurvey(DPLSurveyIdentity);
		else {
			setDPLSurvey(null);
			setDPLSurveyID(null);
		}
	};
	const [survey, setSurvey] = useState<TSurvey | undefined>();
	const [fetching, setFetching] = useState(false);
	let typeCapitalized = sType === "all" ? "View All" : sType.charAt(0).toUpperCase() + sType.slice(1);
	typeCapitalized = typeCapitalized.length <= 3 ? typeCapitalized.toUpperCase() : typeCapitalized;

	// All Management
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();
	const [surveys, setSurveys] = useState<TSurveyMetaData[]>([]);

	useEffect(() => {
		if (philId && sType !== "all") {
			setFetching(true);
			axios
				.get(`/dpl/survey?philId=${philId}&sType=${sType}`)
				.then(resp => {
					setSurvey(resp.data);
					setFetching(false);
				})
				.catch(() => setFetching(false));
		} else if (philId) {
			setFetching(true);
			axios
				.get(`/dpl/surveys?philId=${philId}`)
				.then(resp => {
					setSurveys(
						resp.data.sort((surveyA: TSurveyMetaData, surveyB: TSurveyMetaData) => {
							const aComponents = surveyA.name.split(" ");
							const bComponents = surveyB.name.split(" ");
							const aYear = parseInt(aComponents[0], 10);
							const bYear = parseInt(bComponents[0], 10);
							if (aYear > bYear) return -1;
							if (aYear > bYear) return 1;
							return surveyA.name > surveyB.name;
						})
					);
					setFetching(false);
				})
				.catch(() => setFetching(false));
		}
	}, [philId, sType]);

	useEffect(() => {
		if (philId && DPLSurveyID && sType === "all") {
			setFetching(true);
			axios
				.get(`/dpl/survey?philId=${philId}&id=${DPLSurveyID}`)
				.then(resp => {
					setSurvey(resp.data);
					setFetching(false);
				})
				.catch(() => setFetching(false));
		}
	}, [DPLSurveyID, philId, sType]);

	return (
		<>
			<Button
				disabled={fetching || (survey == null && sType !== "all")}
				onClick={e => {
					if (sType === "all") setAnchorEl(e.currentTarget);
					else setViewing(true);
				}}
				style={ButtonStyle}
				title={
					<TextContainer>
						{getCheckbox(fetching, survey != null, sType === "all")} {typeCapitalized}
					</TextContainer>
				}
			/>
			<Modal isOpen={viewing} onRequestClose={() => setViewing(false)} showPrint={true} showX={true}>
				<Survey survey={survey} />
			</Modal>
			<Menu anchorEl={anchorEl} id="dplSurveyMenu" onClose={() => setAnchorEl(null)} open={Boolean(anchorEl)}>
				{surveys.map(s => (
					<MenuItem
						key={s.id}
						handleClick={() => {
							setSurvey(undefined);
							setDPLSurveyID(s.id);
							setViewing(true);
						}}
					>
						{s.name}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
