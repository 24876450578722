import React, { useEffect, useState, MouseEventHandler, MouseEvent } from "react";
import axios from "_redux/_utils/_axios";
import {
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Text,
	Button
} from "@chakra-ui/react";
import { TSurvey } from "_react/dpl/_types";
import Survey from "_react/dpl/Survey";
import Assignment from "_react/shared/ui/icons/Assignment";

type TDPLQuestionnaireSurveyResponseProps = {
	philId: number;
	surveyId: string;
	surveyName: string;
	onOpenModal: MouseEventHandler<HTMLButtonElement>;
	onCloseModal: () => void;
	isOpenModal: boolean;
};

const DPLQuestionnaireSurveyResponse = ({
	philId,
	surveyId,
	surveyName,
	onOpenModal,
	onCloseModal,
	isOpenModal
}: TDPLQuestionnaireSurveyResponseProps) => {
	const [survey, setSurvey] = useState<TSurvey | undefined>();
	const [fetching, setFetching] = useState(false);
	// Since the modal disclosure is required to be defined in the DPL popover parent (and thus one disclosure is shared for all questionnaire modals) this flag is required to control which modal is being displayed
	const [showingModal, setShowingModal] = useState(false);

	// TODO: This is legacy code from the existing DPL Survey component, this should be updated to use a modern xState machine in this file
	useEffect(() => {
		setFetching(true);
		axios
			.get(`/dpl/survey?philId=${philId}&id=${surveyId}`)
			.then((resp: { data?: TSurvey }) => {
				setSurvey(resp.data);
				setFetching(false);
			})
			.catch(() => setFetching(false));
	}, [philId, surveyId]);

	return (
		<>
			<IconButton
				aria-label={`Show Response`}
				variant="ghost"
				h="fit-content"
				minWidth="fit-content"
				icon={<Assignment />}
				isLoading={fetching}
				onClick={(e: MouseEvent<HTMLButtonElement>) => {
					setShowingModal(true);
					onOpenModal(e);
				}}
			/>
			{showingModal && (
				<Modal
					isOpen={isOpenModal}
					onClose={() => {
						onCloseModal();
						setShowingModal(false);
					}}
				>
					<ModalOverlay />
					<ModalContent maxW={900}>
						<ModalHeader>{surveyName} Response</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							{survey == null && <Text>Error Fetching Response</Text>}
							{survey != null && <Survey survey={survey} showTitle={false} />}
						</ModalBody>
						<ModalFooter>
							<Button
								colorScheme="blue"
								mr={3}
								onClick={() => {
									onCloseModal();
									setShowingModal(false);
								}}
							>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</>
	);
};

export default DPLQuestionnaireSurveyResponse;
