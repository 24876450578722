import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchPlayer } from "_react/shared/data_models/player/_network";
import { PLAYING_LEVEL_AMA } from "_react/shared/data_models/arsenal_scores/_constants";
import {
	fetchPlayerSeasonArsenalScores,
	fetchArsenalScoresThreshold
} from "_react/shared/data_models/arsenal_scores/_network";
import { getCancelSource } from "utils/url_helpers";
import { TPlayerClassification } from "utils/tsutils";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { GAME_TYPE_OVERALL, PITCH_TYPE_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";

import { TArsenalLocationTilesPlayer } from "_react/shared/ui/data/other/ArsenalLocationTiles/_types";
import {
	TArsenalLocationTilesPrimaryData,
	TArsenalLocationTilesSecondaryData
} from "_react/shared/ui/data/other/ArsenalLocationTiles/ArsenalLocationTiles";

const PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE = "playerSeasonArsenalScores";
const ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE = "arsenalScoresThreshold";
const ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE = "arsenalLocationTilesPlayer";

export type TArsenalLocationTilesCancelSource = {
	[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TArsenalLocationTilesContext = {
	seasonFilter: number;
	lastSeasonFilter: number;
	playerId?: number;
	lastPlayerId?: number;
	playingLevel?: TPlayerClassification;
	lastplayingLevel?: TPlayerClassification;
	shouldFetchPrimaryData?: boolean;
	shouldFetchSecondaryData?: boolean;
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema> | null;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	arsenalLocationTilesPlayer?: TArsenalLocationTilesPlayer;
	cancelSources: TArsenalLocationTilesCancelSource;
	toast?: CreateToastFnReturn;
};

interface IArsenalLocationTilesStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches player season arsenal scores data
				playerSeasonArsenalScores: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches arsenal scores thresholds data
				arsenalScoresThreshold: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches additional player data
				arsenalLocationTilesPlayer: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_ARSENAL_LOCATION_TILES_PLAYER = "SET_ARSENAL_LOCATION_TILES_PLAYER";
export const SET_PLAYER_SEASON_ARSENAL_SCORES = "SET_PLAYER_SEASON_ARSENAL_SCORES";
export const SET_ARSENAL_SCORES_THRESHOLD = "SET_ARSENAL_SCORES_THRESHOLD";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYING_LEVEL = "SET_PLAYING_LEVEL";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES = {
	initialized: { playerSeasonArsenalScores: "fetching" }
};
export const FETCHING_ARSENAL_SCORES_THRESHOLD = { initialized: { arsenalScoresThreshold: "fetching" } };
export const FETCHING_ARSENAL_LOCATION_TILES_PLAYER = {
	initialized: { arsenalLocationTilesPlayer: "fetching" }
};

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE = "done.invoke.fetchingPlayerSeasonArsenalScores:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_DONE = "done.invoke.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_ARSENAL_LOCATION_TILES_PLAYER_DONE = "done.invoke.fetchingArsenalLocationTilesPlayer:invocation[0]";

type TPlayerSeasonArsenalScoresEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES;
	data: Array<IPlayerSeasonArsenalScoresSchema> | null | undefined;
};
type TArsenalScoresThresholdEvent = {
	type: typeof SET_ARSENAL_SCORES_THRESHOLD;
	data: Array<IArsenalScoresThresholdApiResponse> | null | undefined;
};
type TSetArsenalLocationTilesPlayerEvent = {
	type: typeof SET_ARSENAL_LOCATION_TILES_PLAYER;
	data: TArsenalLocationTilesPlayer | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetplayingLevelEvent = {
	type: typeof SET_PLAYING_LEVEL;
	data: TPlayerClassification | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TFetchPlayerSeasonArsenalScoresEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE;
	data?: Array<IPlayerSeasonArsenalScoresSchema>;
};
type TFetchArsenalScoresThresholdEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_DONE;
	data?: Array<IArsenalScoresThresholdApiResponse>;
};
type TFetchArsenalLocationTilesPlayerEvent = {
	type: typeof FETCH_ARSENAL_LOCATION_TILES_PLAYER_DONE;
	data?: TArsenalLocationTilesPlayer;
};

type TArsenalLocationTilesEvent =
	| TPlayerSeasonArsenalScoresEvent
	| TArsenalScoresThresholdEvent
	| TSetPlayerIdEvent
	| TSetplayingLevelEvent
	| TSetSeasonFilterEvent
	| TSetArsenalLocationTilesPlayerEvent
	| TFetchArsenalScoresThresholdEvent
	| TFetchArsenalLocationTilesPlayerEvent
	| TFetchPlayerSeasonArsenalScoresEvent;

export type TArsenalLocationTilesSend = Interpreter<
	TArsenalLocationTilesContext,
	IArsenalLocationTilesStateSchema,
	TArsenalLocationTilesEvent
>["send"];

const ArsenalLocationTilesMachine = (
	seasonFilterProp: number,
	playerIdProp?: number,
	playingLevelProp?: TPlayerClassification,
	shouldFetchPrimaryData = true,
	primaryData?: TArsenalLocationTilesPrimaryData,
	shouldFetchSecondaryData = true,
	secondaryData?: TArsenalLocationTilesSecondaryData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TArsenalLocationTilesContext, IArsenalLocationTilesStateSchema, TArsenalLocationTilesEvent>(
		{
			id: "arsenalLocationTiles",
			initial: "initializing",
			context: {
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playingLevel: playingLevelProp,
				lastplayingLevel: playingLevelProp,
				shouldFetchPrimaryData: shouldFetchPrimaryData,
				shouldFetchSecondaryData: shouldFetchSecondaryData,
				playerSeasonArsenalScores: primaryData?.playerSeasonArsenalScores,
				arsenalScoresThreshold: secondaryData?.arsenalScoresThreshold,
				arsenalLocationTilesPlayer: secondaryData?.arsenalLocationTilesPlayer,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: {
						target: "initialized"
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_SEASON_ARSENAL_SCORES]: { actions: "setPlayerSeasonArsenalScores" },
						[SET_ARSENAL_SCORES_THRESHOLD]: { actions: "setArsenalScoresThreshold" },
						[SET_ARSENAL_LOCATION_TILES_PLAYER]: { actions: "setArsenalLocationTilesPlayer" },
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_PLAYING_LEVEL]: { actions: "setplayingLevel" },
						[SET_SEASON_FILTER]: { actions: "setSeasonFilter" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						playerSeasonArsenalScores: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerSeasonArsenalScores",
												cond: "shouldFetchPlayerSeasonArsenalScores"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerSeasonArsenalScores",
									entry: ["refreshPlayerSeasonArsenalScoresCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScores",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresErrored"
										}
									}
								}
							}
						},
						arsenalScoresThreshold: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalScoresThreshold",
												cond: "shouldFetchArsenalScoresThreshold"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalScoresThreshold",
									entry: ["refreshArsenalScoresThresholdCancelSource"],
									invoke: {
										src: "fetchArsenalScoresThreshold",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalScoresThresholdSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalScoresThresholdErrored"
										}
									}
								}
							}
						},
						arsenalLocationTilesPlayer: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalLocationTilesPlayer",
												cond: "shouldFetchArsenalLocationTilesPlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalLocationTilesPlayer",
									entry: ["refreshArsenalLocationTilesPlayerCancelSource"],
									invoke: {
										src: "fetchArsenalLocationTilesPlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalLocationTilesPlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalLocationTilesPlayerErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) =>
					context.playerId !== context.lastPlayerId ||
					context.seasonFilter !== context.lastSeasonFilter ||
					context.playingLevel !== context.lastplayingLevel,
				shouldFetchPlayerSeasonArsenalScores: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) =>
					context.playerSeasonArsenalScores === undefined &&
					shouldFetchPrimaryData &&
					context.playerId !== undefined &&
					context.playingLevel !== undefined,
				shouldFetchArsenalScoresThreshold: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) =>
					context.arsenalScoresThreshold === undefined &&
					context.playingLevel === PLAYING_LEVEL_AMA &&
					shouldFetchSecondaryData,
				shouldFetchArsenalLocationTilesPlayer: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) =>
					context.arsenalLocationTilesPlayer === undefined &&
					shouldFetchSecondaryData &&
					context.playerId !== undefined &&
					context.playingLevel !== undefined
			},
			actions: {
				setPlayerSeasonArsenalScores: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					playerSeasonArsenalScores: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.playerSeasonArsenalScores;
						return event.data;
					},
					cancelSources: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalScoresThreshold: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					arsenalScoresThreshold: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.arsenalScoresThreshold;
						return event.data;
					},
					cancelSources: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.cancelSources;
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalLocationTilesPlayer: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					arsenalLocationTilesPlayer: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== SET_ARSENAL_LOCATION_TILES_PLAYER) return context.arsenalLocationTilesPlayer;
						return event.data;
					},
					cancelSources: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_ARSENAL_LOCATION_TILES_PLAYER) return context.cancelSources;
						if (context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					playerId: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setplayingLevel: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					playingLevel: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_PLAYING_LEVEL) return context.playingLevel;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					seasonFilter: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				clearContext: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					lastSeasonFilter: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) =>
						context.seasonFilter,
					lastPlayerId: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) =>
						context.playerId,
					lastplayingLevel: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) =>
						context.playingLevel,
					playerSeasonArsenalScores: (
						_context: TArsenalLocationTilesContext,
						_event: TArsenalLocationTilesEvent
					) => undefined,
					arsenalLocationTilesPlayer: (
						_context: TArsenalLocationTilesContext,
						_event: TArsenalLocationTilesEvent
					) => undefined,
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonArsenalScoresCancelSource: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalScoresThresholdCancelSource: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalLocationTilesPlayerCancelSource: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						if (context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonArsenalScoresSuccess: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					playerSeasonArsenalScores: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE)
							return context.playerSeasonArsenalScores;
						return event.data;
					}
				}),
				handleFetchArsenalScoresThresholdSuccess: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					arsenalScoresThreshold: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== FETCH_ARSENAL_SCORES_THRESHOLD_DONE) return context.arsenalScoresThreshold;
						return event.data;
					}
				}),
				handleFetchArsenalLocationTilesPlayerSuccess: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					arsenalLocationTilesPlayer: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== FETCH_ARSENAL_LOCATION_TILES_PLAYER_DONE)
							return context.arsenalLocationTilesPlayer;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchPlayerSeasonArsenalScoresErrored: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Seasonal Arsenal Scores by Pitch Type",
							description: "Error fetching seasonal arsenal scores by pitch type data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchArsenalScoresThresholdErrored: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Arsenal Scores Thresholds",
							description: "Error fetching arsenal scores thresholds data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchArsenalLocationTilesPlayerErrored: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Player Data - Arsenal Location Tiles",
							description: "Error fetching player data for arsenal location tiles.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchPlayerSeasonArsenalScores: (context: TArsenalLocationTilesContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScores(
							{
								playerId: context.playerId,
								season: context.seasonFilter,
								playingLevel: context.playingLevel,
								gameType: GAME_TYPE_OVERALL,
								"pitchType[neq]": PITCH_TYPE_OVERALL,
								version: context.playingLevel === PLAYING_LEVEL_AMA ? "v3" : undefined,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalScoresThreshold: (context: TArsenalLocationTilesContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchArsenalScoresThreshold(
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalLocationTilesPlayer: (context: TArsenalLocationTilesContext, _event: AnyEventObject) => {
					if (context.playerId === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayer<TArsenalLocationTilesPlayer>(
							{
								id: context.playerId as number, // We know this is a number based on the if statement above
								isUseCombinedId: true,
								fields: "throws"
							},
							context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default ArsenalLocationTilesMachine;
