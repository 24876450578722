import React from "react";
import { getItemName, getRowName, isBoolean, isCheckbox, isMatrix, isMultipleText, isTable } from "_react/dpl/_helpers";
import {
	AnswerContent,
	AnswerNum,
	AnswerQuestion,
	AnswerResponse,
	AnswerTable,
	AnswerWrapper,
	Boolean,
	SurveyTitle,
	SurveyWrapper
} from "_react/dpl/_styles";
import { TSurvey } from "_react/dpl/_types";

type TSurveyProps = {
	survey?: TSurvey;
	showTitle?: boolean;
};

export default function Survey({ survey, showTitle = true }: TSurveyProps) {
	if (!survey) return null;
	return (
		<SurveyWrapper data-cy={`${survey.type}-survey`}>
			{showTitle && (
				<SurveyTitle>
					<h3>
						{survey.name}: {survey.firstName} {survey.lastName}
					</h3>
				</SurveyTitle>
			)}
			{survey.answers.map(answer => {
				return (
					<AnswerWrapper key={answer.questionName}>
						<AnswerNum answer={answer}>{answer.questionNumber})</AnswerNum>
						<AnswerContent>
							<AnswerQuestion>{answer.questionTitle}</AnswerQuestion>
							<AnswerResponse>
								{isBoolean(answer) ? (
									<Boolean answer={answer}>{answer.questionAnswer === "True" ? "YES" : "NO"}</Boolean>
								) : isCheckbox(answer) ? (
									Object.values(answer.questionAnswer).join(", ")
								) : isTable(answer) ? (
									<AnswerTable>
										<thead>
											<tr>
												<th />
												{answer.columns.map(col => (
													<th key={col.columnId}>{col.columnName}</th>
												))}
											</tr>
										</thead>
										<tbody>
											{Object.entries(answer.questionAnswer).map(([rowId, values]) => (
												<tr key={rowId}>
													<td key={rowId}>{getRowName(answer, rowId)}</td>
													{Object.values(answer.columns).map((colVal, colIdx) => (
														<td key={colIdx}>
															{values[colVal.columnId]
																? String(values[colVal.columnId])
																: ""}
														</td>
													))}
												</tr>
											))}
										</tbody>
									</AnswerTable>
								) : isMatrix(answer) ? (
									<AnswerTable>
										<thead>
											<tr>
												<th />
												<th>Selection</th>
											</tr>
										</thead>
										<tbody>
											{Object.entries(answer.questionAnswer).map(([rowId, colId]) => (
												<tr key={rowId}>
													<td>{getRowName(answer, rowId)}</td>
													<td>
														{answer.columns
															.filter(col => col.columnId === colId)
															.map(col => col.columnName.replace("Column ", ""))}
													</td>
												</tr>
											))}
										</tbody>
									</AnswerTable>
								) : isMultipleText(answer) ? (
									<AnswerTable>
										<thead>
											<tr>
												<th />
												<th>Answer</th>
											</tr>
										</thead>
										<tbody>
											{Object.entries(answer.questionAnswer).map(([itemId, value]) => (
												<tr key={itemId}>
													<td>{getItemName(answer, itemId)}</td>
													<td>{value}</td>
												</tr>
											))}
										</tbody>
									</AnswerTable>
								) : (
									<>{answer.questionAnswer}</>
								)}
							</AnswerResponse>
						</AnswerContent>
					</AnswerWrapper>
				);
			})}
		</SurveyWrapper>
	);
}
